import { Formik } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { changePassword } from "../../api/authentications";
import { toast } from "react-toastify";
import Loader from "../../component/Loader";

function ChangePassword() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  return (
    <section className="sec-bg py-4 px-3 bottom-space">
      <div className="container">
        <div className="form-style mt-5">
          <Formik
            initialValues={{
              currentPassword: "",
              newPassword: "",
              confirmPassword: "",
              toggleCurrentPassword: false,
              toggleNewPassword: false,
              toggleConfirmPassword: false,
            }}
            validationSchema={Yup.object({
              currentPassword: Yup.string().required("Required"),
              newPassword: Yup.string().required("Required"),
              confirmPassword: Yup.string().oneOf(
                [Yup.ref("newPassword"), null],
                "Password doesn't match."
              ),
            })}
            onSubmit={(values, { isSubmitting, resetForm }) => {
              // navigate("/records", { replace: true });
              setLoading(true);
              changePassword({
                current_password: values.currentPassword,
                new_password: values.newPassword,
              }).then((res) => {
                setLoading(false);

                if (res?.code === 1) {
                  navigate("/records");
                  toast.success(res?.message);
                } else {
                  toast.success(res?.message);
                }
              });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <form className="row" onSubmit={handleSubmit}>
                <div className="form-group col-sm-12 col-md-12 text-center mb-5">
                  <div className="">
                    <img
                      src="assets/img/change-password.png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
                <div className="form-group col-sm-12 col-md-12 text-center mb-4">
                  <h2 className="mdl-ttl">change password</h2>
                  <p>Please change your password here</p>
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                  <div className="input-container">
                    <input
                      type={values.toggleCurrentPassword ? "text" : "password"}
                      className="form-control ps-4"
                      placeholder="Current Password"
                      name="currentPassword"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.currentPassword}
                    />
                    <img
                      src="assets/img/password.png"
                      className="input-img"
                      alt=""
                    />
                    <span
                      onClick={() => {
                        setFieldValue(
                          "toggleCurrentPassword",
                          !values.toggleCurrentPassword
                        );
                      }}
                      className="pass-view field-icon toggle-password cursor-pointer"
                    ></span>
                  </div>
                  {errors.currentPassword && touched.currentPassword && (
                    <div className="text-red">{errors.currentPassword}</div>
                  )}
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4 text-center">
                  <div className="input-container">
                    <p>
                      <Link
                        to="/forgot-password"
                        onClick={() => {
                          localStorage.removeItem('token_u');
                        }}
                        className="theme-color fw-bold"
                      >
                        Forgotten Password?
                      </Link>
                    </p>
                  </div>
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                  <div className="input-container">
                    <input
                      type={values.toggleNewPassword ? "text" : "password"}
                      className="form-control ps-4"
                      placeholder="Set New Password"
                      name="newPassword"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.newPassword}
                    />
                    <img
                      src="assets/img/password.png"
                      className="input-img"
                      alt=""
                    />
                    <span
                      onClick={() => {
                        setFieldValue(
                          "toggleNewPassword",
                          !values.toggleNewPassword
                        );
                      }}
                      className="pass-view field-icon toggle-password cursor-pointer"
                    ></span>
                  </div>
                  {errors.newPassword && touched.newPassword && (
                    <div className="text-red">{errors.newPassword}</div>
                  )}
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                  <div className="input-container">
                    <input
                      type={values.toggleConfirmPassword ? "text" : "password"}
                      className="form-control ps-4"
                      placeholder="Set Confirm New Password"
                      name="confirmPassword"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.confirmPassword}
                    />
                    <img
                      src="assets/img/password.png"
                      className="input-img"
                      alt=""
                    />
                    <span
                      onClick={() => {
                        setFieldValue(
                          "toggleConfirmPassword",
                          !values.toggleConfirmPassword
                        );
                      }}
                      className="pass-view field-icon toggle-password cursor-pointer"
                    ></span>
                  </div>
                  {errors.confirmPassword && touched.confirmPassword && (
                    <div className="text-red">{errors.confirmPassword}</div>
                  )}
                </div>
                <div className="form-group col-sm-12 col-md-12 text-center">
                  <button type="submit" className="btn btn-submit">
                    {loading ? <Loader /> : "Done"}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </section>
  );
}

export default ChangePassword;
