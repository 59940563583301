import {
  deleteData,
  getData,
  getDataforUrl,
  postFormData,
  putFormData,
} from "./index";

export const areaCodeList = async (payload) => {
  return getData("admin/areaCodeList", payload).then((data) => {
    return data;
  });
};
export const membershipList = async (payload) => {
  return getData("admin/membershipList", payload).then((data) => {
    return data;
  });
};

export const mySubscribedMembershipList = async (payload) => {
  return getData("user/mySubscribedMembershipList", payload).then((data) => {
    return data;
  });
};
