import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {},
};

export const RecordSlice = createSlice({
  name: "records",
  initialState,
  reducers: {
    reducerName: (state, action) => {},
  },
});

export default RecordSlice.reducer;
