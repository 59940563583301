import {
  deleteData,
  getData,
  getDataforUrl,
  postFormData,
  putFormData,
} from "./index";

export const membershipList = async (payload) => {
  return getData("admin/membershipList", payload).then((data) => {
    return data;
  });
};

export const disciplineList = async (payload) => {
  return getData("admin/disciplineList", payload).then((data) => {
    return data;
  });
};

export const sendMembershipSticker = async (payload) => {
  return postFormData("/user/sendMembershipSticker", payload).then((data) => {
    return data;
  });
};
