import React from "react";
import { Link } from "react-router-dom";

function EventCard({ handleShow, sectionName }) {
  return (
    <>
      <div className="bg-white p-2 rounded h-100 shadow eventCard">
        <div className="event-box-flex">
          <span>
            <img src="assets/img/kick.png" className="event-img" alt="" />
          </span>
          <span>
            <h5>
              {sectionName === "event" ? (
                <Link to={`/event/${1}`}>kick boxing</Link>
              ) : (
                "Kick Boxing"
              )}
            </h5>
            <p className="mb-0">adult | child | mixed</p>
            <p className="theme-color1 mb-0">individual class</p>
          </span>
        </div>
        <div className="joining-flex">
          <span>
            <img src="assets/img/photo.png" className="ppl-img mr-1" alt="" />
            <img src="assets/img/photo.png" className="ppl-img mx-1" alt="" />
            <small>20 people are joining</small>
          </span>
          <span
            className="left-seats cursor-pointer"
            onClick={() => {
              handleShow();
            }}
          >
            {sectionName === "class" ? "Book Seat" : "12 seats lefts"}
          </span>
        </div>
      </div>
    </>
  );
}

export default EventCard;
