import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  bookingMembershipData: localStorage.getItem("bookingMembershipData")
    ? JSON.parse(localStorage.getItem("bookingMembershipData"))
    : null,
};

export const StudentSlice = createSlice({
  name: "student",
  initialState,
  reducers: {
    addStudentDataReducer: (state, action) => {
      state.bookingMembershipData = action.payload;
    },
  },
});

export const { addStudentDataReducer } = StudentSlice.actions;

export default StudentSlice.reducer;
