import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import { useDispatch } from "react-redux";
import OtpModal from "../../component/Modal/OtpModal";
import { createAccountSchema } from "../../utils/schema";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ConsentModal from "./ConsentModal";
import {
  consentCall,
  createAccountCall,
  FetchTNC,
  FetchUserDetail,
  onConsentCall,
  tncSelector,
  userDetailSelector,
} from "../slice";
import Loader from "./../../component/Loader";
import { toast } from "react-toastify";
import { uploadFile } from "react-s3";
import { config } from "../../utils";
import { Progress } from "react-sweet-progress";
import { useSelector } from "react-redux";
import { consentList, subscribeNewsletter } from "../../api/authentications";
import DatePicker from "react-multi-date-picker";

function CreateAccount() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(userDetailSelector);
  const tnc = useSelector(tncSelector);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [passwordFor, setPasswordFor] = useState("");
  const [modalName, setModalName] = useState("");
  const [userId, setUserId] = useState("");
  const [time, setTime] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [consents, setConsents] = useState(null);
  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    setValue,
    watch,
    control,
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onSubmit",
    resolver: yupResolver(createAccountSchema),
    shouldFocusError: true,
  });
  const watchTnc = watch("tnc");

  useEffect(() => {
    dispatch(FetchUserDetail({})).then((res) => {
      if (res?.payload?.code === 1) {
        let data = res?.payload?.data?.user;
        if (data?.profile_stag === "0") {
          setShow(true);
          setModalName("otp");
        }
      }
    });
  }, [dispatch]);

  const onSubmit = (data) => {
    setLoading(true);
    dispatch(
      createAccountCall({
        first_name: data?.firstName,
        last_name: data?.lastName,
        username: data?.userName,
        email: data?.email,
        password: data?.password,
        // birth_date: data?.dob,
        user_type: "user",
      })
    )
      .then(async (res) => {
        setLoading(false);
        if (res?.payload?.code === 1) {
          await localStorage.setItem(
            "user",
            JSON.stringify(res?.payload?.data?.user)
          );
          await localStorage.setItem("email", data?.email);
          toast.success(res?.payload?.message);
          setUserId(res?.payload?.data?.userId);

          return res?.payload?.data?.userId;
        } else {
          toast.error(res?.payload?.message);
          return false;
        }
      })
      .then(async (res) => {
        if (res) {
          let data = await addUserConsent(res);
          if (data?.payload?.code === 1) {
            return res;
          } else {
            toast.error(data?.payload?.message);
            return null;
          }
        }
      })
      .then(async (ress) => {
        if (ress) {
          subscribeNewsletter({
            status: data?.newsletter === true ? true : false,
            user_id: ress,
          })
            .then((res) => {
              console.log(res, "After subscribe");
              if (res?.code === 1) {
                toast.success(res?.message);
                return ress;
              } else {
                toast.error(res?.message);
                return null;
              }
            })
            .then(() => {
              if (ress) {
                console.log(ress);
                setShow(true);
                setModalName("otp");
              }
            });
        }
      });
  };

  const sendConsentData = (data) => {
    setConsents(data?.user_consent);
    data?.user_consent?.map((item, i) => {
      console.log(item, "Consent in parent ");
    });
  };

  const addUserConsent = (id) => {
    const data = consents;
    console.log(data, "Before consent");
    const payload = {
      userId: id,
      user_consents: data?.map((it) => {
        return {
          consent: it?.id,
          value: it?.user_value?.value,
          note: it?.user_value?.note ? it?.user_value?.note : "",
        };
      }),
    };

    console.log(payload, "Payload consent");

    return dispatch(consentCall(payload)).then((res) => {
      if (res?.payload?.code === 1) {
        toast.success(res?.payload?.message);
      } else {
        toast.error(res?.payload?.message);
      }
      return res;
    });
  };

  useEffect(() => {
    let interval;
    if (isActive && time < 100) {
      interval = setInterval(() => {
        // if (time < 100)
        setTime((seconds) => (seconds < 99 ? seconds + 1 : seconds));
      }, 10);
    }
    return () => clearInterval(interval);
  }, [isActive, time]);

  const handleImage = (e) => {
    const { files } = e.target;
    setIsActive(true);

    uploadFile(files[0], config)
      .then((data) => {
        setValue("image", data?.location);
        setTime(100);
        setIsActive(true);
        setTimeout(() => {
          setTime(0);
          setIsActive(false);
        }, 2000);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    dispatch(FetchTNC({ type: "signup for new account" }));
  }, [dispatch]);

  return (
    <section className="bg-image">
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6">
            <div className="logo-bg position-relative">
              <p>
                <img
                  src="assets/img/headerlogo.png"
                  className="img-fluid cursor-pointer"
                  alt="logo"
                />
              </p>

              <p>
                <img
                  src="assets/img/banner_logo.png"
                  className="logo-img"
                  alt="logo"
                />
              </p>
            </div>
          </div>

          <div className="col-sm-12 col-md-6 col-lg-6">
            <div className="input-container text-end mb-4">
              <p>
                Already have an account?
                <Link to="/login" className="theme-color fw-bold px-1">
                  Log In
                </Link>
              </p>
            </div>
            <div className="form-style">
              <form className="row" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group col-sm-12 col-md-12 text-center mb-4">
                  <h2 className="mdl-ttl">create account</h2>
                </div>

                <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4 text-center">
                  <div className="upload-btn-wrapper ">
                    <button className="btn">
                      {time > 0 && isActive ? (
                        <Progress type="circle" width={50} percent={time} />
                      ) : (
                        <img
                          src={
                            getValues("image")
                              ? getValues("image")
                              : "assets/img/Stdt1.png"
                          }
                          alt=""
                        />
                      )}

                      {time > 0 && isActive ? null : (
                        <div className="camera-icon">
                          <i className="fa fa-camera"></i>
                        </div>
                      )}
                    </button>

                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => {
                        if (e.target.files.length > 0) {
                          handleImage(e);
                        }
                      }}
                    />
                  </div>
                </div>
                {errors.image && (
                  <span className="text-danger">{errors?.image?.message}</span>
                )}
                <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                  <label className="fw-bold">Parent's First Name</label>
                  <div className="input-container">
                    <input
                      type="text"
                      className="form-control ps-4"
                      placeholder="Parent's First Name"
                      name="firstName"
                      {...register("firstName")}
                    />
                    <img
                      src="assets/img/First-Name.png"
                      className="input-img"
                      alt=""
                    />
                  </div>
                  {errors && (
                    <div className="text-red">{errors?.firstName?.message}</div>
                  )}
                </div>

                <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                  <label className="fw-bold">Parent's Last Name</label>
                  <div className="input-container">
                    <input
                      type="text"
                      className="form-control ps-4"
                      placeholder="Parent's Last Name"
                      name="lastName"
                      {...register("lastName")}
                    />
                    <img
                      src="assets/img/First-Name.png"
                      className="input-img"
                      alt=""
                    />
                  </div>
                  {errors && (
                    <div className="text-red">{errors?.lastName?.message}</div>
                  )}
                </div>

                <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                  <label className="fw-bold">Username</label>
                  <div className="input-container">
                    <input
                      type="text"
                      className="form-control ps-4"
                      placeholder="User Name"
                      name="userName"
                      {...register("userName")}
                    />
                    <img
                      src="assets/img/First-Name.png"
                      className="input-img"
                      alt=""
                    />
                  </div>
                  {errors && (
                    <div className="text-red">{errors?.userName?.message}</div>
                  )}
                </div>

                <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                  <label className="fw-bold">Email ID</label>

                  <div className="input-container">
                    <input
                      type="email"
                      className="form-control ps-4"
                      placeholder="Email ID"
                      name="email"
                      {...register("email")}
                    />
                    <img
                      src="assets/img/email.png"
                      className="input-img"
                      alt=""
                    />
                  </div>
                  {errors && (
                    <div className="text-red">{errors?.email?.message}</div>
                  )}
                </div>

                <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                  <label className="fw-bold">Confirm Email ID</label>
                  <div className="input-container">
                    <input
                      type="email"
                      className="form-control ps-4"
                      placeholder="Confirm Email ID"
                      name="email"
                      {...register("emailConfirmation")}
                    />
                    <img
                      src="assets/img/email.png"
                      className="input-img"
                      alt=""
                    />
                  </div>
                  {errors && (
                    <div className="text-red">
                      {errors?.emailConfirmation?.message}
                    </div>
                  )}
                </div>

                <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                  <label className="fw-bold">Password</label>

                  <div className="input-container">
                    <input
                      id="password-field"
                      type={
                        toggle && passwordFor === "setPassword"
                          ? "text"
                          : "password"
                      }
                      className="form-control ps-4"
                      placeholder="Set Password"
                      name="password"
                      {...register("password")}
                    />
                    <img
                      src="assets/img/password.png"
                      className="input-img pass"
                      alt=""
                    />
                    <span
                      onClick={() => {
                        setPasswordFor("setPassword");
                        setToggle(!toggle);
                      }}
                      className="pass-view field-icon toggle-password cursor-pointer"
                    ></span>
                  </div>
                  {errors && (
                    <div className="text-red">{errors?.password?.message}</div>
                  )}
                </div>

                <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                  <label className="fw-bold">Confirm Password</label>

                  <div className="input-container">
                    <input
                      id="password-field"
                      type={
                        toggle && passwordFor === "confrimPassword"
                          ? "text"
                          : "password"
                      }
                      className="form-control ps-4"
                      placeholder="Confirm Password"
                      name="passwordConfirmation"
                      {...register("passwordConfirmation")}
                    />
                    <img
                      src="assets/img/password.png"
                      className="input-img pass"
                      alt=""
                    />
                    <span
                      onClick={() => {
                        setPasswordFor("confrimPassword");
                        setToggle(!toggle);
                      }}
                      className="pass-view field-icon toggle-password cursor-pointer"
                    ></span>
                  </div>
                  {errors && (
                    <div className="text-red">
                      {errors?.passwordConfirmation?.message}
                    </div>
                  )}
                </div>
                {/* <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                  <label className="fw-bold">Parent's Date of Birth</label>

                  <div className="input-container">
                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <DatePicker
                          containerClassName="form-control ps-4"
                          placeholder="Parent's Date of Birth*"
                          value={value}
                          onChange={(date) => {
                            onChange(date.toDate());
                          }}
                          maxDate={new Date()}
                        />
                      )}
                      name="dob"
                      control={control}
                    />
                    <img
                      src="assets/img/date-of-birth.png"
                      className="input-img"
                      alt=""
                    />
                    <img
                      src="assets/img/choose-date.png"
                      className="field-icon"
                      alt=""
                    />
                  </div>
                  {errors.dob && (
                    <span className="text-danger">{errors?.dob?.message}</span>
                  )}
                </div> */}
                <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4 text-center">
                  <div className="input-container term-checkbox">
                    <Controller
                      name="tnc"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <input
                          type="checkbox"
                          id="term-checkbox"
                          name="tnc"
                          checked={value}
                          // {...register("tnc")}
                          onChange={(e) => {
                            if (e.target.checked === true) {
                              setShow(!show);
                              setModalName("consent");
                              onChange(e.target.checked);
                              dispatch(onConsentCall(null));
                            } else {
                              setShow(false);
                              setModalName("");
                              onChange(false);
                            }
                          }}
                        />
                      )}
                    />
                    <label htmlFor="term-checkbox"></label>
                    <span>
                      {" "}
                      I consent to{" "}
                      <span
                        onClick={() => {
                          if (watchTnc) {
                            setValue("tnc", false);
                          }
                          setShow(true);
                          setModalName("consent");
                          // window.open(tnc?.file, "_blank");
                        }}
                        className="theme-color fw-bold cursor-pointer"
                      >
                        Data
                      </span>{" "}
                      &{" "}
                      <span
                        onClick={() => {
                          // if (watchTnc) {
                          //   setValue("tnc", false);
                          // }
                          // setShow(true);
                          // setModalName("consent");
                          // window.open(tnc?.file, "_blank");
                          navigate(
                            "/terms-and-condition/signup for new account"
                          );
                        }}
                        className="theme-color fw-bold cursor-pointer"
                      >
                        Terms of Site
                      </span>
                    </span>
                    {errors && (
                      <div className="text-red">{errors?.tnc?.message}</div>
                    )}
                  </div>
                  <div className="input-container term-checkbox mt-3">
                    <input
                      type="checkbox"
                      id="subscribeToNewsletter"
                      // checked={values.newsletter}
                      {...register("newsletter")}
                    />
                    <label htmlFor="subscribeToNewsletter">
                      Subscribe to Newsletter
                    </label>
                    {errors && (
                      <div className="text-red">
                        {errors?.newsletter?.message}
                      </div>
                    )}
                  </div>
                </div>

                <div className="form-group col-sm-12 col-md-12 text-center">
                  <button type="submit" className="btn btn-submit">
                    {loading ? <Loader /> : "Create Account"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {modalName === "otp" && (
        <OtpModal
          show={show}
          handleShow={() => {
            setShow(!show);
          }}
          id={userId}
        />
      )}

      {modalName === "consent" && (
        <ConsentModal
          show={show}
          handleShow={() => {
            setShow(!show);
            setModalName("");
          }}
          tncValue={watchTnc}
          sendConsentData={sendConsentData}
          setModalName={setModalName}
          onConsent={() => {
            setModalName("");
            setValue("tnc", true);
          }}
          onClose={() => {
            setValue("tnc", false);
          }}
        />
      )}
    </section>
  );
}

export default CreateAccount;
