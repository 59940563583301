import moment from "moment";
import React from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router";

function BookingDetailModal({
  show,
  handleShow,
  data,
  setSelectedBooking,
  page,
}) {
  const navigate = useNavigate();
  console.log(data, "Class to be mapped");
  return (
    <Modal
      show={show}
      onHide={() => {
        handleShow();
        setSelectedBooking(null);
      }}
      size="lg"
      centered
    >
      <Modal.Body>
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content">
            <div className="modal-header d-grid text-center">
              <h3 className="mdl-ttl">
                {page === "payment" ? "Payment" : "Booking"} Details
              </h3>
              <button
                type="button"
                className="btn-close"
                onClick={() => {
                  handleShow();
                  setSelectedBooking(null);
                }}
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-4 mt-3">
                  <div className="bg-white p-2 text-capitalize mt-3">
                    <p className="detail-account position-relative">
                      <span className="">Booking Id</span>
                      <span>
                        <b>{data?.id || "NA"}</b>
                      </span>
                    </p>
                  </div>
                </div>

                <div className="col-sm-12 col-md-6 col-lg-4 mt-3">
                  <div className="bg-white p-2 text-capitalize mt-3">
                    <p className="detail-account position-relative">
                      <i
                        className="fa fa-gbp icon-title"
                        aria-hidden="true"
                      ></i>

                      <span className="">Total Booking Amount</span>
                      <span>
                        <b>{data?.total_amount || "NA"}</b>
                      </span>
                    </p>
                  </div>
                </div>

                <div className="col-sm-12 col-md-12 col-lg-12 mt-3">
                  <div className="bg-white p-2 text-capitalize mt-3">
                    {data?.booking_classes?.length > 0 ? (
                      <div className="p-2">
                        <label className="fw-bold mb-2">
                          <h4>Classes Booked</h4>
                        </label>
                        {data?.booking_classes?.map((classes, i) => {
                          const sortedList = [...classes?.time_slot_list]?.sort(
                            function compare(a, b) {
                              console.log(a, b);
                              var dateA = new Date(a.date);
                              var dateB = new Date(b.date);
                              return dateA - dateB;
                            }
                          );
                          return sortedList?.map((time_slot, ind) => {
                            return time_slot?.student_list?.map(
                              (student, index) => {
                                return (
                                  <div className="shadow mb-2 p-3">
                                    <p className="mb-0">
                                      <label className="fw-bold mb-1">
                                        Student Name:
                                      </label>{" "}
                                      {student?.student_details?.first_name +
                                        " " +
                                        student?.student_details?.last_name}
                                    </p>
                                    <p className="mb-0">
                                      <label className="fw-bold mb-1">
                                        Membership Level:
                                      </label>{" "}
                                      {student?.student_details
                                        ?.trialAccountStatus === "3"
                                        ? "Active"
                                        : student?.student_details
                                            ?.trialAccountStatus === "1"
                                        ? "Trial"
                                        : "NA"}
                                    </p>
                                    <p className="mb-0">
                                      <label className="fw-bold mb-1">
                                        Class Name:
                                      </label>{" "}
                                      {classes?.class_details?.name}
                                    </p>
                                    <p className="mb-0">
                                      <label className="fw-bold mb-1">
                                        Venue:
                                      </label>{" "}
                                      {classes?.school_details?.name},{" "}
                                      {classes?.school_details?.address}
                                    </p>
                                    <p className="mb-0">
                                      <label className="fw-bold mb-1">
                                        Date:
                                      </label>{" "}
                                      {moment(time_slot?.date).format(
                                        "DD MMM YYYY"
                                      )}
                                    </p>
                                    <p className="mb-0">
                                      <label className="fw-bold mb-1">
                                        Time:
                                      </label>{" "}
                                      {moment(
                                        time_slot?.start_time,
                                        "hh:mm:ss"
                                      ).format("hh:mm a")}{" "}
                                      -{" "}
                                      {moment(
                                        time_slot?.end_time,
                                        "hh:mm:ss"
                                      ).format("hh:mm a")}
                                    </p>
                                    {page === "payment" && (
                                      <>
                                        <label className="fw-bold mb-1">
                                          Class Price:
                                        </label>{" "}
                                        <span className="theme-color">
                                          <i
                                            className="fa fa-gbp fa-sm"
                                            aria-hidden="true"
                                          ></i>{" "}
                                          {student?.student_type === "child"
                                            ? classes?.child_price
                                            : student?.student_type === "adult"
                                            ? classes?.adult_price
                                            : "NA"}
                                        </span>
                                      </>
                                    )}
                                  </div>
                                );
                              }
                            );
                          });
                        })}
                      </div>
                    ) : null}
                    {data?.booking_events?.length > 0 ? (
                      <div className="p-2">
                        <label className="fw-bold mb-2">
                          <h4>Events Booked</h4>
                        </label>
                        {data?.booking_events?.map((events, i) => {
                          return events?.student_list?.map((student, ind) => {
                            return (
                              <div className="shadow mb-2 p-3">
                                <p className="mb-0">
                                  <label className="fw-bold mb-1">
                                    Student Name:
                                  </label>{" "}
                                  {student?.student_details?.first_name +
                                    " " +
                                    student?.student_details?.last_name}
                                </p>
                                {/* <p className="mb-0">
                                  <label className="fw-bold mb-1">
                                    Membership Level:
                                  </label>{" "}
                                  {student?.student_details
                                    ?.trialAccountStatus === "3"
                                    ? "Active"
                                    : student?.student_details
                                        ?.trialAccountStatus === "1"
                                    ? "Trial"
                                    : "Trial"}
                                </p> */}
                                <p className="mb-0">
                                  <label className="fw-bold mb-1">
                                    Event Name:
                                  </label>{" "}
                                  {events?.event_details?.name}
                                </p>
                                <p className="mb-0">
                                  <label className="fw-bold mb-1">Venue:</label>{" "}
                                  {events?.school_details?.name},{" "}
                                  {events?.school_details?.address}
                                </p>
                                <p className="mb-0">
                                  <label className="fw-bold mb-1">Date:</label>{" "}
                                  {moment(events?.date).format("DD MMM YYYY")}
                                </p>

                                {events?.time && (
                                  <p className="mb-0">
                                    <label className="fw-bold mb-1">
                                      Time:
                                    </label>{" "}
                                    {events?.time
                                      ? moment(events?.time, "hh:mm:ss").format(
                                          "hh:mm a"
                                        )
                                      : "NA"}
                                  </p>
                                )}

                                <p className="mb-0" key={i}>
                                  <label className="fw-bold mb-1">
                                    Students:
                                  </label>{" "}
                                  {events?.student_list
                                    ?.map((stud, i) => {
                                      return `${stud?.student_details?.first_name} ${stud?.student_details?.last_name}`;
                                    })
                                    ?.join(", ")}
                                </p>

                                {events?.level_details && (
                                  <>
                                    <p className="mb-0">
                                      <label className="fw-bold mb-1">
                                        Level Name:
                                      </label>{" "}
                                      {events?.level_details?.name}
                                    </p>
                                    <p className="mb-0">
                                      <label className="fw-bold mb-1">
                                        Time:
                                      </label>{" "}
                                      {moment(
                                        events?.level_details?.time,
                                        "hh:mm:ss"
                                      ).format("hh:mm a")}
                                    </p>
                                  </>
                                )}

                                {page === "payment" && (
                                  <>
                                    <label className="fw-bold mb-1">
                                      Class Price:
                                    </label>{" "}
                                    <span className="theme-color">
                                      <i
                                        className="fa fa-gbp fa-sm"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      {events?.event_details?.event_price}
                                    </span>
                                  </>
                                )}
                              </div>
                            );
                          });
                        })}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="col-sm-12 col-md-12 col-lg-12 mt-3 text-center">
                  <button
                    type="button"
                    className="btn btn-submit"
                    onClick={() => {
                      handleShow();
                      setSelectedBooking(null);
                    }}
                  >
                    Done
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default BookingDetailModal;
