import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router";

function BuyMembershipPayment({ show, handleShow, showAnother, page, reload }) {
  const navigate = useNavigate();
  return (
    <Modal show={show} onHide={handleShow} size="lg" centered>
      <div className=" modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header d-grid text-center">
            <h5 className="mdl-ttl">Select Payment Method</h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                handleShow("");
              }}
            ></button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 mt-3 text-center">
                <img src="assets/img/select-payment.png" className="" alt="" />
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 mt-3">
                <div className="checkboxs payment-mode">
                  <label>
                    <input
                      type="radio"
                      value="dabit/credit card"
                      name="payment-mode"
                    />
                    <div className="div-bg">
                      <p>
                        <span>
                          <img
                            src="assets/img/dabit-card.png"
                            className="event-img"
                            alt=""
                          />{" "}
                          debit/credit card
                        </span>
                      </p>
                    </div>
                  </label>

                  <label>
                    <input
                      type="radio"
                      value="net banking"
                      name="payment-mode"
                    />
                    <div className="div-bg">
                      <p>
                        <span>
                          <img
                            src="assets/img/net-banking.png"
                            className="event-img"
                            alt=""
                          />{" "}
                          net banking
                        </span>
                      </p>
                    </div>
                  </label>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 mt-3">
                <button
                  className="btn btn-accept btn-view mt-2"
                  onClick={() => {
                    handleShow();
                    showAnother(true);
                    if (page === "changePlan") {
                    }
                  }}
                  id="paymenyDone"
                >
                  Pay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default BuyMembershipPayment;
