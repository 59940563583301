import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { FetchAreaCodeList, FetchDisciplineList } from "../slice";
import { FetchDefaultEventList, getEventLists } from "./EventSlice";
import moment from "moment";
import Paginations from "./../../component/Pagination";

function EventListing() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [areacode, setAreacode] = useState("");
  const [discipline, setDiscipline] = useState("");
  const [page, setPage] = useState(1);
  const total = useSelector((store) => store.event.total);
  const areacodeList = useSelector((store) => store.main.areaCodeList);
  const disciplineList = useSelector((state) => state.main.disciplinesList);
  const eventList = useSelector(getEventLists);

  const getListings = useCallback(() => {
    dispatch(FetchAreaCodeList({ page: 1, limit: 10000000000000 }));
    dispatch(FetchDisciplineList({ page: 1, limit: 10000000000000 }));
  }, [dispatch]);

  useEffect(() => {
    getListings();
  }, [getListings]);

  const getEventList = useCallback(() => {
    dispatch(
      FetchDefaultEventList({
        page: page,
        limit: 10,
        studentId: "",
        areacode,
        discipline,
        status: 1,
      })
    );
  }, [dispatch, areacode, discipline, page]);

  useEffect(() => {
    getEventList();
  }, [getEventList]);

  return (
    <section className="sec-bg py-4 px-3 bottom-space">
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-8">
            <h4>Events</h4>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-4">
            <div className="dropSchool">
              <select
                className="form-control form-select"
                onChange={(e) => {
                  setAreacode(e.target.value);
                }}
              >
                <option value="">Select Area</option>
                {areacodeList?.map((item, i) => (
                  <option value={item?.id} key={i}>
                    {item?.areacode}
                  </option>
                ))}
              </select>

              <select
                className="form-control form-select"
                onChange={(e) => {
                  setDiscipline(e.target.value);
                }}
              >
                <option value="">Select Discipline</option>
                {disciplineList?.map((item, i) => (
                  <option value={item?.id} key={i}>
                    {item?.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row">
            {eventList?.length > 0 ? (
              eventList?.map((item, i) => {
                return (
                  <div className="col-sm-12 col-md-6 col-lg-4 mt-2" key={i}>
                    <div className="class__details bg-white p-3 cursor-pointer rounded h-160 position-relative w-100 mb-3">
                      <div className="event-box-flex align-items-normal p-0 mb-2">
                        <span>
                          <img
                            src={
                              item?.image?.includes("http")
                                ? item?.image
                                : "assets/img/headerlogo.png"
                            }
                            className="event-img"
                            alt=""
                          />
                        </span>
                        <span className="ellipse">
                          <h6
                            onClick={() => {
                              navigate(`/event/${item?.id}`);
                            }}
                            className="cursor-pointer ellipse mb-0"
                          >
                            {item?.name || "NA"}
                          </h6>
                          <p className="mb-0 fs-7 ellipse">
                            {item?.age_group.toLowerCase() === "both"
                              ? "Adult |Child"
                              : item?.age_group}
                          </p>
                          <p className="mb-0 fs-7 ellipse">
                            <img
                              src="assets/img/address.png"
                              className=""
                              alt=""
                            />{" "}
                            {item?.school_details?.name || "NA"}
                          </p>
                          <p className="mb-0 fs-7">
                            <img
                              src="assets/img/date-of-birth.png"
                              className=""
                              alt=""
                            />{" "}
                            {item?.date
                              ? moment(item?.date).format("DD MMM YYYY")
                              : "NA"}
                          </p>
                          {item?.levels?.length > 0 && (
                            <p className="mb-0 fs-7 ellipse">
                              <span>Levels: </span> {item?.levels?.length}
                            </p>
                          )}
                        </span>
                      </div>
                      <div className="event-view-btns view-btns justify-content-sm-end position-sm-absolute eventButtons">
                        <button
                          className="left-seats py-1 px-2 fs-7 cursor-pointer"
                          onClick={() => {
                            navigate(`/event/${item?.id}`);
                          }}
                        >
                          View Event
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="mt-2 text-center">No events found.</div>
            )}
          </div>
          {eventList?.length > 0 && (
            <div className="col-sm-12 mt-5">
              <Paginations
                page={page}
                total={total}
                handlePage={(val) => {
                  setPage(val);
                }}
              />
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default EventListing;
