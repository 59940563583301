import React from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { BookingPayment } from "../../api/booking";
import {
  GetCartTotalSelector,
  getEventAddedToCartListSelector,
} from "../../features/slice";

function WantToAddAnotherStudent({ show, handleShow, addAnotherStudent }) {
  const navigate = useNavigate();
  return (
    <Modal show={show} onHide={handleShow} size="md" centered>
      <div className=" modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header d-grid text-center">
            <h5 className="mdl-ttl">Do you want to add another student?</h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                handleShow(!show);
              }}
            ></button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 mt-3 text-center">
                <img src="assets/img/Stdt1.png" className="" alt="" />
              </div>

              <div className="col-sm-12 col-md-12 col-lg-12 mt-3">
                <button
                  className="btn btn-accept btn-view mt-2"
                  onClick={() => {
                    handleShow(!show);
                    navigate("/book-class", { replace: true });
                  }}
                  id="paymenyDone"
                >
                  No
                </button>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 mt-3">
                <button
                  className="btn btn-accept btn-view mt-2"
                  onClick={() => {
                    addAnotherStudent();
                  }}
                  id="paymenyDone"
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default WantToAddAnotherStudent;
