import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { changeDetailOfPaymentDetail } from "./PaymentSlice";
import moment from "moment";
import ReadMore from "../../component/ReadMore/ReadMore";
import BackButton from "./../../component/BackButton";

function DetailOfPaymentDetail() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.payment.detailOfPaymentDetail);
  console.log(data);
  const [adminCharges, setAdminCharges] = useState(0);

  useEffect(() => {
    if (data) {
      let amt = 0;
      data?.booking_classes?.forEach((element) => {
        element?.time_slot_list.forEach((time_slot) => {
          time_slot?.student_list?.forEach((student) => {
            if (student?.bookedInTrialAccount) {
              amt += 1;
            }
          });
        });
      });
      setAdminCharges(amt);
    }
  }, [dispatch, data]);

  return (
    <section className="sec-bg py-4 px-3 bottom-space">
      <div className="container-fluid">
        <BackButton />
        <div className="row align-items-center">
          <div className="col-sm-12 col-md-12 col-lg-12 mt-3 text-center">
            <h3>
              {data?.booking_classes?.class_details
                ? "Class Payment Detail"
                : data?.booking_classes?.event_details
                ? "Event Payment Detail"
                : ""}
            </h3>
          </div>
          <div className="col-sm-12">
            <div className="col-sm-12 col-md-6 col-lg-6 mt-3">
              <div className="bg-white p-2 text-capitalize mt-3 d-flex gap-2">
                <p className="detail-account ">
                  <span className="">Total Amount</span>
                  <span className="">
                    <i className="fa fa-gbp " aria-hidden="true"></i>{" "}
                    <b>{data?.total_amount || "NA"}</b>
                  </span>
                </p>
                <p className="detail-account ">
                  <span className="">Admin Charges</span>
                  <span className="">
                    <i className="fa fa-gbp " aria-hidden="true"></i>{" "}
                    <b>{adminCharges || "NA"}</b>
                  </span>
                </p>
                <p className="detail-account ">
                  <span className="">Date</span>
                  <span className="">
                    <b>
                      {data?.createdAt
                        ? moment(data?.createdAt).format("hh:mm a, DD MMM YYYY")
                        : ""}
                    </b>
                  </span>
                </p>
              </div>
            </div>

            {data?.booking_classes?.length > 0 && (
              <div className="col-sm-12 col-md-12 col-lg-12 mt-3">
                <div className="bg-white p-2 text-capitalize mt-3">
                  <label className="fw-bold mb-2">
                    <h4>Classes Booked</h4>
                  </label>
                  {data?.booking_classes?.length > 0
                    ? data?.booking_classes?.map((classes, i) => {
                        return (
                          <div className="p-2">
                            <div className="shadow mb-2 p-3">
                              <p className="mb-1">
                                <label className="fw-bold mb-1">
                                  Class Name:
                                </label>{" "}
                                {classes.class_details?.name}
                              </p>
                              <p className="mb-1">
                                <label className="fw-bold mb-1">Venue:</label>{" "}
                                {classes.school_details?.name},{" "}
                                {classes.school_details?.address}
                              </p>
                              <div className="table-responsive">
                                <table className="table storetable">
                                  <thead className=" text-center ">
                                    <tr>
                                      <th>Date</th>
                                      <th>Time</th>
                                      <th>Students</th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {classes.time_slot_list
                                      //   ?.sort(function compare(a, b) {
                                      //     console.log(a, b);
                                      //     var dateA = new Date(a?.date);
                                      //     var dateB = new Date(b?.date);
                                      //     return dateA - dateB;
                                      //   })
                                      ?.map((time_slot, index) => {
                                        return (
                                          <tr
                                            key={index}
                                            className="text-center"
                                          >
                                            <td>
                                              {" "}
                                              {moment(time_slot?.date).format(
                                                "DD MMM YYYY"
                                              )}
                                            </td>
                                            <td>
                                              {" "}
                                              {moment(
                                                time_slot?.start_time,
                                                "hh:mm:ss"
                                              ).format("hh:mm a")}
                                              -{" "}
                                              {moment(
                                                time_slot?.end_time,
                                                "hh:mm:ss"
                                              ).format("hh:mm a")}
                                            </td>
                                            <td>
                                              {time_slot?.student_list
                                                ?.map(
                                                  (student) =>
                                                    `${student?.student_details?.first_name} ${student?.student_details?.last_name}`
                                                )
                                                ?.join(", ")}
                                            </td>
                                          </tr>
                                        );
                                      })}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            {/* {data?.booking_details?.booking_classes?.map(
                      (classes, i) => {
                        const sortedList = [...classes?.time_slot_list]?.sort(
                          function compare(a, b) {
                            console.log(a, b);
                            var dateA = new Date(a.date);
                            var dateB = new Date(b.date);
                            return dateA - dateB;
                          }
                        );

                        return (
                          
                        );
                      }
                    )} */}
                          </div>
                        );
                      })
                    : null}
                </div>
              </div>
            )}
            {data?.booking_events?.length > 0 && (
              <div className="col-sm-12 col-md-12 col-lg-12 mt-3">
                <div className="bg-white p-2 text-capitalize mt-3">
                  <label className="fw-bold mb-2">
                    <h4>Events Booked</h4>
                  </label>
                  {data?.booking_events?.length > 0
                    ? data?.booking_events?.map((events, i) => {
                        return (
                          <div className="p-2">
                            <div className="shadow mb-2 p-3">
                              <p className="mb-0">
                                <label className="fw-bold mb-1">
                                  Event Name:
                                </label>{" "}
                                {events.event_details?.name}
                              </p>

                              <p className="mb-0">
                                <label className="fw-bold mb-1">Venue:</label>{" "}
                                {events.school_details?.name},{" "}
                                {events.school_details?.address}
                              </p>
                              <p className="mb-0">
                                <label className="fw-bold mb-1">
                                  Event Date:
                                </label>{" "}
                                {moment(events.event_details?.date).format(
                                  "DD MMM YYYY"
                                )}
                              </p>
                              {events.event_details?.start_time && (
                                <p className="mb-0">
                                  <label className="fw-bold mb-1">
                                    Event Time:
                                  </label>{" "}
                                  {events.event_details?.start_time
                                    ? moment(
                                        events.event_details?.start_time,
                                        "hh:mm:ss"
                                      ).format("hh:mm a")
                                    : "NA"}
                                </p>
                              )}

                              {events.about && (
                                <p className="mb-0">
                                  <label className="fw-bold mb-1">About:</label>{" "}
                                  {events.about ? (
                                    <ReadMore>{events.about}</ReadMore>
                                  ) : (
                                    "NA"
                                  )}
                                </p>
                              )}
                              {events.levels?.length > 0 && (
                                <div className="table-responsive">
                                  <table className="table storetable">
                                    <thead className=" text-center ">
                                      <tr>
                                        <th>Level Name</th>
                                        <th>Level Time</th>
                                        <th>Students</th>
                                        <th>Level Note</th>
                                      </tr>
                                    </thead>

                                    <tbody>
                                      {events.levels?.map((level, index) => {
                                        return (
                                          <tr
                                            key={index}
                                            className="text-center"
                                          >
                                            <td>
                                              {level?.level_details?.name}
                                            </td>

                                            <td>
                                              {" "}
                                              {moment(
                                                level?.level_details?.time,
                                                "hh:mm:ss"
                                              ).format("hh:mm a")}
                                            </td>
                                            <td>
                                              {level?.student_list
                                                ?.map(
                                                  (student) =>
                                                    `${student?.student_details?.first_name} ${student?.student_details?.last_name}`
                                                )
                                                ?.join(", ")}
                                            </td>
                                            <td>
                                              {level?.level_details?.note}
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })
                    : null}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default DetailOfPaymentDetail;
