import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { getData, getDataforUrl, postFormData } from "../../api";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { addWalletAmount } from "../../api/payment";
import { toast } from "react-toastify";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { addMemberShip, addStudentCall, userDetailSelector } from "../slice";
import { BookingPayment } from "../../api/booking";
import OrderSummary from "../../component/Modal/OrderSummary";
import { sendMembershipSticker } from "../../api/membership";
import { config, htmlStringToPdf } from "../../utils";

import { v4 as uuidv4 } from "uuid";
import S3 from "aws-s3";
const S3Client = new S3(config);
function CompletedPayment() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let [searchParams, setSearchParams] = useSearchParams();
  const payload = useSelector((state) => state.student.bookingMembershipData);
  let transactionId = searchParams?.get("transactionId");
  const [data, setData] = useState(null);
  const [bookingId, setBookingId] = useState("");
  const [show, setShow] = useState(false);
  const [page, setPage] = useState("");
  const user = useSelector(userDetailSelector);

  const handleShow = () => {
    setShow(!show);
  };
  console.log(payload);

  const handleAddStudent = (data) => {
    const countryCode = data?.mobile
      ? formatPhoneNumberIntl(data?.mobile).split(" ")[0]
      : "";
    return dispatch(
      addStudentCall({
        first_name: data?.studentFirstName,
        last_name: data?.studentLastName,
        dob: `${data?.year}-${data?.month}-${data?.day}`,
        student_type: data?.student_type,
        discipline: data?.discipline,
        emergency_contactNo: data?.mobile
          ? data?.mobile.replace(countryCode, "")
          : "",
        emergency_countryCode: data?.mobile ? countryCode : "",
        medical_note: data?.note,
        image: data?.image || "",
        studentId: data?.studentId,
        areacode: data?.areacode,
        schoolId: data?.schoolId,
        trial_membership: data?.membership_level
          ? ""
          : data?.trial_membership
          ? 1
          : "",
        membership_level: data?.membership_level || "",
        gender: data?.gender || "",
        // no_of_days: data?.membership_level ? data?.no_of_days : "",
        // price: data?.membership_level ? data?.price : "",
        wallet_used_amount: data?.wallet_used_amount || "",
        transaction_amount: data?.transaction_amount || "",
      })
    ).then((response) => {
      if (response?.payload?.code === 1) {
        toast.success(response?.payload?.message);
      } else {
        toast.error(response?.payload?.message);
      }
      return response;
    });
  };

  const handleMembership = (payload) => {
    const studentData = payload?.studentData;
    const userData = payload?.userData;
    if (!payload?.sub_payment_for) {
      const sendHtmlAsString = async (nameD = "") => {
        let htmlPart = `<!DOCTYPE html>
    <html lang="en">

    <head>
        <meta charset="utf-8">
        <meta http-equiv="Content-Type" content="text/html;charset=UTF-8">
        <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">

        <title>Membership Details</title>

    </head>

    <body style="width: 100 mm;height: 56 mm;">
        <div class="mw-body"
            style="width: 100%;height: 100%;
        font-family: Roboto Slab,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji">
            <div>
                <table style="width: 100%;height: 100%;border-collapse: separate;
                border-spacing: 0 0em;text-transform: uppercase; font-size: 12px; font-weight: 700;margin: 1mm;padding: 5px;">
                    <tr>
                        <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
        border-right: 0;"><span>name</span></td>
                        <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;"><span style="color: #ff0000;"> ${
                          studentData?.first_name
                        } ${studentData?.last_name}</span></td>

                    </tr>
                    <tr>
                        <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                            border-right: 0;"><span>address</span></td>
                        <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;"><span
                                style="color: #ff0000;"> ${
                                  userData?.property_name
                                }, ${
          userData?.property_number ? `${userData?.property_number}, ` : ""
        } ${userData?.street_number}</span></td>

                    </tr>
                    <tr>
                        <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                            border-right: 0;"><span>town</span></td>
                        <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                            "><span style="color: #ff0000;"> ${
                              userData?.town
                            }</span></td>
                    </tr>
                    <tr>
                        <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                            border-right: 0;"><span>postcode</span></td>
                        <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                            "><span style="color: #ff0000;"> ${
                              userData?.postal_code
                            }</span></td>
                    </tr>
                    <tr>
                        <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                            border-right: 0;"><span>dob</span></td>
                        <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                            "><span style="color: #ff0000;"> ${moment(
                              studentData?.dob
                            ).format("DD MMM YYYY")}</span></td>
                    </tr>
                    <tr>
                        <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                            border-right: 0;"><span>school</span></td>
                        <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                            "><span style="color: #ff0000;"> ${
                              studentData?.school_details?.name
                            }</span></td>
                    </tr>
                    <tr>
                        <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                            border-right: 0;"><span>first licence</span></td>
                        <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                            "><span style="color: #ff0000;"> ${moment(
                              studentData?.membership_details?.start_date
                            ).format("DD MMM YYYY")}</span></td>
                    </tr>
                    <tr>
                        <td style="border-collapse: collapse;padding: 1mm;
                            border-right: 0;"><span>date</span></td>
                        <td style="border-collapse: collapse;padding: 1mm;
                            "><span style="color: #ff0000;"> ${moment(
                              studentData?.membership_details?.valid_till
                            ).format("DD MMM YYYY")}</span></td>
                    </tr>
                </table>
            </div>
    </body>

    </html>`;

        const pdfOutput = await htmlStringToPdf(htmlPart);

        return pdfOutput;
      };

      sendHtmlAsString().then((ob) => {
        if (ob) {
          const newFileName = uuidv4();
          S3Client.uploadFile(ob, newFileName)
            .then((data) => {
              if (data?.location) {
                const stickerPayload = {
                  user_membership_id: payload?.userMembershipId,
                  file_url: data?.location,
                  userId: studentData?.userId,
                };
                sendMembershipSticker(stickerPayload).then((res) => {
                  if (res?.code === 1) {
                    // setShowBuyMembership(false);
                    navigate("/student", { replace: true });
                    toast.success(res?.message);
                  } else {
                    toast.error(res?.message);
                  }
                });
              }
            })

            .catch((err) => console.error(err));
        }
      });
    }
  };

  useEffect(() => {
    if (transactionId) {
      getDataforUrl("booking/getPaymentDetails", transactionId)
        .then((ret) => {
          console.log(ret, "Payment detail");
          if (ret?.code === 1) {
            setData(ret?.data?.payment);
          }
          return ret;
        })
        .then((transaction) => {
          console.log(transaction, "Transaction");
          if (
            transaction?.code === 1 &&
            transaction?.data?.payment?.status?.toLowerCase() === "completed"
          ) {
            if (payload?.payment_for === "wallet") {
              return;
              // addWalletAmount({
              //   ...payload?.data,
              //   currency:"GBP",
              //   merchant_support_email:user?.email ,
              // })
              //   .then((res) => {
              //     console.log(res, "Wallet ");
              //     if (res?.code === 1) {
              //       // navigate("/dashboard", { replace: true });
              //       toast.success(res?.message);
              //     } else {
              //       toast.error(res?.message);
              //     }
              //     return res;
              //   })
                // .then((re) => {
                //   console.log(re);
                //   if (re?.code === 1) {
                //     postFormData("booking/savePaymentDetails", {
                //       userWalletPaymentId: re?.data?.userWalletPaymentId,
                //       amount:
                //         transaction?.data?.payment?.amountMoney?.amount / 100,
                //       order_id: transaction?.data?.payment?.orderId,
                //       transaction_id: transaction?.data?.payment?.id,
                //       payment_for: "wallet",
                //       payment_status: transaction?.data?.payment?.status,
                //     }).then((resp) => {
                //       if (resp?.code === 1) {
                //         toast.success(resp?.message);
                //       } else {
                //         toast.error(resp?.message);
                //       }
                //     });
                //   }
                // });
            } else if (payload?.payment_for === "booking") {
              console.log("Booking is successfull.");
              setBookingId(payload?.bookingId);
              handleShow();

              // CHANGES THE STATUS OF THE BOOKING

              // postFormData("booking/savePaymentDetails", {
              //   bookingId: payload?.bookingId,
              //   amount: transaction?.data?.payment?.amountMoney?.amount / 100,
              //   order_id: transaction?.data?.payment?.orderId,
              //   transaction_id: transaction?.data?.payment?.id,
              //   payment_for: "booking",
              //   payment_status: transaction?.data?.payment?.status,
              // }).then((resp) => {
              //   if (resp?.code === 1) {

              //     toast.success(resp?.message);
              //   } else {
              //     toast.error(resp?.message);
              //   }
              // });
            } else if (
              payload?.payment_for === "membership" &&
              payload?.sub_payment_for === "changePlan"
            ) {
              handleMembership(payload);

              // postFormData("booking/savePaymentDetails", {
              //   userMembershipId: payload?.userMembershipId,
              //   amount: transaction?.data?.payment?.amountMoney?.amount / 100,
              //   order_id: transaction?.data?.payment?.orderId,
              //   transaction_id: transaction?.data?.payment?.id,
              //   payment_for: "membership",
              //   payment_status: transaction?.data?.payment?.status,
              // }).then((resp) => {
              //   if (resp?.code === 1) {
              //     toast.success(resp?.message);
              //   } else {
              //     toast.error(resp?.message);
              //   }
              // });
            } else if (payload?.payment_for === "membership") {
              handleMembership(payload);

              // postFormData("booking/savePaymentDetails", {
              //   userMembershipId: payload?.userMembershipId,
              //   amount: transaction?.data?.payment?.amountMoney?.amount / 100,
              //   order_id: transaction?.data?.payment?.orderId,
              //   transaction_id: transaction?.data?.payment?.id,
              //   payment_for: "membership",
              //   payment_status: transaction?.data?.payment?.status,
              // }).then((resp) => {
              //   if (resp?.code === 1) {
              //     toast.success(resp?.message);
              //   } else {
              //     toast.error(resp?.message);
              //   }
              // });
            }
            // localStorage.removeItem("bookingMembershipData");
          }
        });
    }
  }, [transactionId, payload]);

  return (
    <section className="sec-bg py-4 px-3 bottom-space">
      <div className="container-fluid">
        {transactionId && data?.status === "COMPLETED" && (
          <div className="row ">
            <div className="col-sm-12 col-md-12 col-lg-12 mt-3 text-center">
              <h3>Transaction Detail</h3>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 mt-3 text-center">
              Your payment is{" "}
              {data?.status === "COMPLETED" ? "successfull." : data?.status}
            </div>
            <div
              className="col-sm-12 "
              style={{ maxWidth: 600, margin: "auto" }}
            >
              <div className="bg-white p-3 text-capitalize mt-3 ">
                <p>
                  <label className="fw-bold">Order Date: </label>{" "}
                  <span>
                    {moment(data?.createdAt).format("HH:mm a, DD MMM YYYY")}
                  </span>
                </p>
                <p>
                  <label className="fw-bold">Order Id: </label>{" "}
                  <span>{data?.orderId}</span>
                </p>
                <p>
                  <label className="fw-bold">Payment Id: </label>{" "}
                  <span>{data?.id}</span>
                </p>
                <p>
                  <label className="fw-bold">Amount: </label>{" "}
                  <span>{data?.amountMoney?.amount / 100}</span>
                </p>
                <p>
                  <label className="fw-bold">Currency: </label>{" "}
                  <span>{data?.amountMoney?.currency}</span>
                </p>{" "}
                <p>
                  <label className="fw-bold">Payment mode: </label>{" "}
                  <span>{data?.sourceType}</span>
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 mt-3 text-center">
              <button
                className="btn-accept btn-view w-auto text-center m-auto"
                onClick={() => {
                  navigate("/dashboard", { replace: true });
                }}
              >
                Back To Dashboard
              </button>
            </div>
          </div>
        )}
        {!transactionId && data?.status !== "COMPLETED" && (
          <div className="row ">
            <div className="col-sm-12 col-md-12 col-lg-12 mt-3 text-center">
              <h3>Transaction Detail</h3>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 mt-3 text-center">
              Your payment is{" "}
              {data?.status === "COMPLETED" ? "succesfull." : data?.status}{" "}
              Kindly try again.
            </div>

            <div className="col-sm-12 col-md-12 col-lg-12 mt-3 text-center">
              <button
                className="btn-accept btn-view w-auto text-center m-auto"
                onClick={() => {
                  navigate("/dashboard", { replace: true });
                }}
              >
                Back To Dashboard
              </button>
            </div>
          </div>
        )}
      </div>
      {bookingId && show && (
        <OrderSummary
          show={show}
          handleShow={(val) => {
            handleShow();
          }}
          id={bookingId}
          page={page}
        />
      )}
    </section>
  );
}

export default CompletedPayment;
