import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: [1, 1],
};

export const NotificationSlice = createSlice({
  name: "name",
  initialState,
  reducers: {
    reducerName: (state, action) => {},
  },
});

export const { reducerName } = NotificationSlice.actions;

export default NotificationSlice.reducer;
