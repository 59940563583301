import moment from "moment";
import React from "react";

function WalletCard({ amount, data, date }) {
  return (
    <div className="bg-white p-3 rounded h-100 shadow cursor-pointer">
      <div className="row">
        <div className="col-sm-6 col-md-12 col-lg-12 col-xl-8 mb-2 text-capitalize">
          {/* <p className="mb-2">
            <label>Amount Added By Admin</label>
          </p> */}
          <p className="mb-2 ">
            <label className="theme-color fw-bold">Date: </label>{" "}
            <span>{date}</span>
          </p>
          <p className="mb-2 ">
            <label className="theme-color fw-bold">
              Current Wallet Amount:{" "}
            </label>{" "}
            <span>
              <i className="fa fa-gbp" aria-hidden="true"></i> {amount}
            </span>
          </p>
          <p className="mb-2 ">
            <label className="theme-color fw-bold">Note: </label>{" "}
            <span> {data?.note}</span>
          </p>
        </div>
        <div className="col-sm-6 col-md-12 col-lg-12 col-xl-4 mb-2 position-relative text-sm-end">
          <p className="text-capitalize text-muted"></p>
          <div className="payment-type">
            {
              <h3 className="theme-color">
                {data?.amount ? data?.amount?.charAt(0) : ""}{" "}
                <i className="fa fa-gbp" aria-hidden="true"></i>{" "}
                {data?.amount ? data?.amount?.slice(1) : 0}
              </h3>
            }
            <p className="text-capitalize fw-bold mb-0">
              {/* net banking */}
              {/* {total > 1 ? `+ ${total - 1} more` : ""} */}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WalletCard;
