import JsPDF from "jspdf";
import html2canvas from "html2canvas";

export const currency = "GBP";

export const padTime = (time) => {
  return String(time).length === 1 ? `0${time}` : `${time}`;
};

export const format = (time) => {
  // Convert seconds into minutes and take the whole part
  const minutes = Math.floor(time / 60);

  // Get the seconds left after converting minutes
  const seconds = time % 60;

  //Return combined values as string in format mm:ss
  return `${minutes}:${padTime(seconds)}`;
};

export const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1242, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 646, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

export const config = {
  bucketName: process.env.REACT_APP_BUCKET_NAME,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
  dirName: "dev",
};

export const phoneRegExp =
  /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;

export const yearOptions = () => {
  let initialTime = new Date("1970-03-09Z08:00:00"),
    endTime = new Date(),
    arrTime = [],
    dayMillisec = 12 * 30 * 24 * 60 * 60 * 1000;
  for (
    let q = initialTime;
    q <= endTime;
    q = new Date(q.getTime() + dayMillisec)
  ) {
    arrTime.unshift(q.getFullYear());
  }
  return arrTime;
};

export const monthOption = [
  {
    id: 1,
    value: "January",
  },
  {
    id: 2,
    value: "February",
  },
  {
    id: 3,
    value: "March",
  },
  {
    id: 4,
    value: "April",
  },
  {
    id: 5,
    value: "May",
  },
  {
    id: 6,
    value: "June",
  },
  {
    id: 7,
    value: "July",
  },
  {
    id: 8,
    value: "August",
  },
  {
    id: 9,
    value: "September",
  },
  {
    id: 10,
    value: "October",
  },
  {
    id: 11,
    value: "November",
  },
  {
    id: 12,
    value: "December",
  },
];

export const htmlStringToPdf = async (htmlString) => {
  let iframe = document.createElement("iframe");
  iframe.style.visibility = "hidden";
  document.body.appendChild(iframe);
  let iframedoc = iframe.contentDocument || iframe.contentWindow.document;
  iframedoc.body.innerHTML = htmlString;

  let canvas = await html2canvas(iframedoc.body, { opacity: 1, scale: 4 });

  // Convert the iframe into a PNG image using canvas.
  let imgData = canvas.toDataURL("image/png");

  // Create a PDF document and add the image as a page.
  const doc = new JsPDF({
    orientation: "l",
    format: [58, 104],
    unit: "mm",
  });
  doc.addImage(imgData, "PNG", 1, 1, 100, 56);

  // Get the file as blob output.
  let blob = doc.output("blob");

  // Remove the iframe from the document when the file is generated.
  document.body.removeChild(iframe);
  return blob;
};
