import moment from "moment";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import Loader from "../../component/Loader";
import Paginations from "../../component/Pagination";
import { bookingCountSelector } from "../slice";
import {
  changeBookingFor,
  FetchPaymentDetail,
  FetchPaymentList,
} from "./PaymentSlice";
import { Link } from "react-router-dom";
import EventDetailCard from "../../component/EventDetailCard";
import PaymentClassCard from "../../component/PaymentClassCard";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MembershipCard from "../../component/MembershipCard";
import WalletCard from "../../component/walletCard/WalletCard";
function PaymentDetail() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  // const detail = useSelector((state) => state.payment?.detail);
  const [detail, setDetail] = useState(null);
  console.log(detail, "apyment detail");
  const total = useSelector(bookingCountSelector);
  const [loading, setLoading] = useState(false);
  const [booking_for, setBooking_for] = useState("");
  // const [start_date, setStart_date] = useState(new Date(id));
  // const [end_date, setEnd_date] = useState(new Date(id));
  const [walletAmount, setWalletAmount] = useState(0);
  const booking_for_is_wallet = useSelector(
    (state) => state.payment.booking_for_is_wallet
  );

  useEffect(() => {
    if (booking_for_is_wallet === "wallet") {
      setBooking_for(booking_for_is_wallet);
    } else if (booking_for_is_wallet === "membership") {
      setBooking_for(booking_for_is_wallet);
    } else {
      setBooking_for("class");
    }
  }, [booking_for_is_wallet]);

  useEffect(() => {
    setLoading(true);
    if (booking_for) {
      dispatch(
        FetchPaymentDetail({
          page: 1,
          limit: 10,
          booking_for: booking_for,
          // start_date: start_date ? moment(start_date).format("YYYY-MM-DD") : "",
          // end_date: end_date ? moment(end_date).format("YYYY-MM-DD") : "",
        })
      ).then((res) => {
        setLoading(false);

        if (res?.payload?.code === 1) {
          let data = res?.payload?.data?.booking_list;
          console.log(data, "Booking list");
          setDetail(data);
        }
      });
    }
  }, [dispatch, id, booking_for]);

  return (
    <section className="sec-bg py-4 px-3 bottom-space">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-sm-12 col-md-12 col-lg-12 mt-3 text-center">
            <h3>Payment Detail</h3>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-12 mt-3 text-center">
            <ul
              className="nav nav-pills mb-3 d-flex justify-content-center gap-3"
              id="pills-tabContent"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  className={
                    booking_for === "class"
                      ? "btn bookingPills shadow nav-link active"
                      : "btn bookingPills shadow nav-link"
                  }
                  id="classTab-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#classTab"
                  type="button"
                  role="tab"
                  aria-controls="classTab"
                  aria-selected="true"
                  onClick={() => {
                    setPage(1);
                    setBooking_for("class");
                    dispatch(changeBookingFor("class"));
                  }}
                >
                  Class
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={
                    booking_for === "event"
                      ? "btn bookingPills shadow nav-link active"
                      : "btn bookingPills shadow nav-link"
                  }
                  id="eventTab-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#eventTab"
                  type="button"
                  role="tab"
                  aria-controls="eventTab"
                  aria-selected="false"
                  onClick={() => {
                    setPage(1);
                    setBooking_for("event");
                    dispatch(changeBookingFor("event"));
                  }}
                >
                  Event
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={
                    booking_for === "membership"
                      ? "btn bookingPills shadow nav-link active"
                      : "btn bookingPills shadow nav-link"
                  }
                  id="eventTab-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#eventTab"
                  type="button"
                  role="tab"
                  aria-controls="eventTab"
                  aria-selected="false"
                  onClick={() => {
                    setPage(1);
                    setBooking_for("membership");
                    dispatch(changeBookingFor("membership"));
                  }}
                >
                  Membership
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={
                    booking_for === "wallet"
                      ? "btn bookingPills shadow nav-link active"
                      : "btn bookingPills shadow nav-link"
                  }
                  id="eventTab-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#eventTab"
                  type="button"
                  role="tab"
                  aria-controls="eventTab"
                  aria-selected="false"
                  onClick={() => {
                    setPage(1);
                    setBooking_for("wallet");
                    dispatch(changeBookingFor("wallet"));
                  }}
                >
                  Wallet
                </button>
              </li>
            </ul>
          </div>

          {/* <div className="col-sm-12 col-md-12 col-lg-12 mt-3">
            <div className="filterIconForPayment teacher-fil float-end">
              <h6 className="ps-4">Filter</h6>
              <div className="bg-white py-3 px-3 position-relative ">
                <span>
                  <label className="px-1 theme-color">Start Date</label>
                  <DatePicker
                    selected={start_date}
                    onChange={(val) => {
                      setStart_date(val);
                    }}
                    isClearable
                    placeholder="Start Date"
                    className="form-select p-0"
                    clearButtonClassName={"clearButton"}
                  />
                </span>
                <span>
                  <label className="px-1 theme-color">End Date</label>

                  <DatePicker
                    selected={end_date}
                    onChange={(val) => {
                      setEnd_date(val);
                    }}
                    minDate={start_date || new Date()}
                    isClearable
                    placeholder="End Date"
                    className="form-select p-0"
                    clearButtonClassName={"clearButton"}
                  />
                </span>
                 <img
                  src="assets/img/filter.png"
                  className="filter-icon rounded"
                  alt=""
                /> 
              </div>
            </div>
          </div> */}
          {loading ? (
            <Loader />
          ) : (
            <>
              {detail?.length > 0 ? (
                detail?.map((item, i) => {
                  if (booking_for === "class" || booking_for === "event") {
                    return (
                      <div className="col-sm-12 col-md-6 col-lg-4 my-3" key={i}>
                        <PaymentClassCard
                          data={
                            booking_for === "class"
                              ? item?.booking_classes?.[0]
                              : booking_for === "event"
                              ? item?.booking_events?.[0]
                              : null
                          }
                          amount={item?.total_amount}
                          date={item?.createdAt}
                          allData={item}
                        />
                      </div>
                    );
                  } else if (booking_for === "wallet") {
                    return (
                      <div className="col-sm-12 col-md-6 col-lg-4 my-3" key={i}>
                        <WalletCard
                          data={item}
                          amount={walletAmount}
                          date={moment(id).format("DD MMM YYYY")}
                        />{" "}
                      </div>
                    );
                  } else {
                    return (
                      <div className="col-sm-12 col-md-6 col-lg-4 my-3" key={i}>
                        <MembershipCard data={item} />{" "}
                      </div>
                    );
                  }
                })
              ) : (
                <span className="mt-3 text-center">No data found.</span>
              )}
            </>
          )}
        </div>
      </div>
    </section>
  );
}

export default PaymentDetail;
