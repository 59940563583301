import moment from "moment";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import ReadMore from "../ReadMore/ReadMore";

function EventLevels({
  show,
  handleShow,
  levels,
  setSelectedLevels,
  onDone,
  child_price,
  adult_price,
  setSelectedEvent,
}) {
  console.log(levels, "All levels for event");
  const [selectedLevel, setSelectedLevel] = useState([]);

  const handleDone = () => {
    setSelectedLevels(selectedLevel);
    onDone(selectedLevel, child_price, adult_price);
  };
  return (
    <Modal
      show={show}
      onHide={() => {
        handleShow();
      }}
      size="lg"
      centered
    >
      <Modal.Body>
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content other-city b-r-20">
            <div className="modal-header d-grid text-center">
              <h2 className="mdl-ttl">Select Levels</h2>

              <button
                type="button"
                className="btn-close"
                onClick={() => {
                  handleShow();
                }}
              ></button>
            </div>
            <div className="modal-body">
              <div className="row student-container">
                {levels?.map((item, i) => {
                  return (
                    <div key={i}>
                      <input
                        type="checkbox"
                        name="level"
                        id={`level-${item?.id}`}
                        checked={
                          selectedLevel?.includes(item) ||
                          item?.isBooked ||
                          item?.isAddedInCart
                        }
                        onChange={(e) => {
                          if (item?.isBooked === false) {
                            console.log(item, "Item Selected");
                            //   handleStudent(e, item);
                            if (item.isAddedInCart === false) {
                              let checked = e.target.checked;
                              let arr = [...selectedLevel];
                              const isAdded = arr.includes(item);
                              const index = arr.indexOf(item);
                              console.log(isAdded);
                              if (checked) {
                                if (!isAdded) {
                                  arr.push(item);
                                }
                                setSelectedLevel(arr);
                              } else if (!checked) {
                                if (isAdded) {
                                  arr.splice(index, 1);
                                }
                                setSelectedLevel(arr);
                              }
                            } else {
                              toast.error("Item already added in cart");
                            }
                          } else {
                            toast.error("You have already booked this level.");
                          }
                        }}
                      />
                      <label
                        htmlFor={`level-${item?.id}`}
                        className="student__details levels bg-transparent shadow-none"
                      >
                        <div className="shadow bg-white p-3">
                          <p className="mb-0">
                            <label className="fw-bold">Level Name:</label>{" "}
                            <span>{item?.name}</span>
                          </p>
                          <p className="mb-0">
                            <label className="fw-bold">Time:</label>{" "}
                            <span>
                              {item?.time
                                ? moment(item?.time, "hh:mm:ss").format(
                                    "hh:mm a"
                                  )
                                : "NA"}
                            </span>
                          </p>
                          {item?.child_price && item?.child_price !== "0" && (
                            <p className="mb-0">
                              <label className="fw-bold">Child Price:</label>{" "}
                              <span>&#163; {item?.child_price}</span>
                            </p>
                          )}

                          {item?.adult_price && item?.adult_price !== "0" && (
                            <p className="mb-0">
                              <label className="fw-bold">Adult Price:</label>{" "}
                              <span>&#163; {item?.adult_price}</span>
                            </p>
                          )}

                          <p className="mb-0">
                            <label className="fw-bold">Note:</label>{" "}
                            <ReadMore>{item?.note}</ReadMore>
                          </p>

                          <p className="mb-0 text-danger">
                            <span>
                              {item?.isBooked
                                ? "Item already booked."
                                : item?.isAddedInCart
                                ? "Item already added in cart"
                                : ""}
                            </span>
                          </p>
                        </div>
                      </label>
                    </div>
                  );
                })}
                <div
                  className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-5 d-flex justify-content-center align-items-center text-center"
                  style={{ gap: 20 }}
                >
                  <button
                    type="button"
                    onClick={() => {
                      setSelectedLevels([]);
                      handleShow();
                      setSelectedEvent(null);
                    }}
                    className="btn btn-submit fs-6 mb-1 w-100"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      if (selectedLevel.length === 0) {
                        toast.error("Kindly select a level you want to book.");
                      } else {
                        handleDone();
                      }
                    }}
                    className="btn btn-submit fs-6 mb-1 w-100"
                  >
                    Done
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default EventLevels;
