import moment from "moment";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Loader from "../../component/Loader";
import Paginations from "../../component/Pagination";
import { bookingCountSelector } from "../slice";
import { changeBookingFor, FetchPaymentList } from "./PaymentSlice";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import DatePicker from "react-multi-date-picker";
// import "react-multi-date-picker/styles/layouts/mobile.css";

function PaymentSummary() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const list = useSelector((state) => state.payment?.list);
  const total = useSelector((state) => state.payment?.total);
  const [loading, setLoading] = useState(false);
  const [start_date, setStart_date] = useState("");
  const [end_date, setEnd_date] = useState("");

  useEffect(() => {
    setLoading(true);

    dispatch(
      FetchPaymentList({
        page: page,
        limit: 10,
        start_date: start_date ? moment(start_date).format("YYYY-MM-DD") : "",
        end_date: end_date ? moment(end_date).format("YYYY-MM-DD") : "",
      })
    ).then((res) => {
      setLoading(false);
    });
  }, [dispatch, page, start_date, end_date]);

  return (
    <section className="sec-bg py-4 px-3 bottom-space">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-sm-12 col-md-12 col-lg-12 mt-3 text-center">
            <h3>Payment Summary</h3>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-12 mt-3 text-center">
            {/* <ul
              className="nav nav-pills mb-3 d-flex justify-content-center gap-3"
              id="pills-tabContent"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  className={
                    booking_for === "class"
                      ? "btn bookingPills shadow nav-link active"
                      : "btn bookingPills shadow nav-link"
                  }
                  id="classTab-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#classTab"
                  type="button"
                  role="tab"
                  aria-controls="classTab"
                  aria-selected="true"
                  onClick={() => {
                    setPage(1);
                    setBooking_for("class");
                  }}
                >
                  Class
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={
                    booking_for === "event"
                      ? "btn bookingPills shadow nav-link active"
                      : "btn bookingPills shadow nav-link"
                  }
                  id="eventTab-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#eventTab"
                  type="button"
                  role="tab"
                  aria-controls="eventTab"
                  aria-selected="false"
                  onClick={() => {
                    setPage(1);
                    setBooking_for("event");
                  }}
                >
                  Event
                </button>
              </li>
            </ul> */}
          </div>

          <div className="col-sm-12 col-md-12 col-lg-12 mt-3">
            <div className="filterIconForPayment teacher-fil float-end">
              <h6 className="ps-4">Filter</h6>
              <div className="bg-white py-3 px-3 position-relative ">
                <span>
                  <label className="px-1 theme-color">Start Date</label>
                  <DatePicker
                    selected={start_date}
                    onChange={(val) => {
                      setStart_date(val);
                    }}
                    isClearable
                    placeholder="Start Date"
                    className="form-select p-0"
                    clearButtonClassName={"clearButton"}
                  />
                </span>
                <span>
                  <label className="px-1 theme-color">End Date</label>

                  <DatePicker
                    selected={end_date}
                    onChange={(val) => {
                      setEnd_date(val);
                    }}
                    isClearable
                    placeholder="End Date"
                    className="form-select p-0"
                    clearButtonClassName={"clearButton"}
                  />
                </span>
                {/* <img
                  src="assets/img/filter.png"
                  className="filter-icon rounded"
                  alt=""
                /> */}
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-12 mt-3">
            <div className="table-responsive">
              <table className="table storetable">
                <thead className=" text-center bg-white">
                  <tr>
                    <th scope="col">Date</th>
                    <th scope="col">Payment For</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Action</th>
                  </tr>
                  <tr className="border-0 bg-none invisible">
                    <td className="border-0 bg-none"></td>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan={4}>
                        <Loader />
                      </td>
                    </tr>
                  ) : list?.length > 0 ? (
                    list?.map((item, i) => {
                      return (
                        <tr className="text-center bg-white">
                          <td>
                            <div className="line-lmp">
                              {item?.createdAt
                                ? moment(item?.createdAt).format("DD MMM YYYY")
                                : "NA"}
                            </div>
                          </td>
                          <td>
                            {item?.membership_level
                              ? "Membership"
                              : item?.booked_by
                              ? "Booking"
                              : item?.note ||
                                item?.note === "" ||
                                item?.note === null
                              ? "Wallet"
                              : ""}
                          </td>
                          <td>
                            <div className="line-lmp theme-color">
                              <i className="fa fa-gbp" aria-hidden="true"></i>{" "}
                              {item?.total_amount}
                            </div>
                          </td>
                          <td>
                            <button className="btn" title="View">
                              <span
                                // to={`/payment-detail/${item?.createdAt}`}
                                onClick={async () => {
                                  console.log(item?.note !== undefined);
                                  await localStorage.setItem(
                                    "booking_for_is_wallet",
                                    item?.note !== undefined
                                  );
                                  dispatch(
                                    changeBookingFor(
                                      item?.note !== undefined
                                        ? "wallet"
                                        : item?.membership_level !== undefined
                                        ? "membership"
                                        : "class"
                                    )
                                  );
                                  setTimeout(() => {
                                    navigate(
                                      `/payment-detail/${item?.createdAt}`
                                    );
                                  }, 500);
                                }}
                              >
                                <img src="assets/img/view.png" alt="" />
                              </span>
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <td className="text-center" colSpan={4}>No data found</td>
                  )}
                </tbody>
              </table>
            </div>
            <div className="mt-2">
              {list?.length > 10 && (
                <Paginations page={page} handlePage={setPage} total={total && total} />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PaymentSummary;
