import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import BackButton from "../../component/BackButton";
import Footer from "../../component/Footer";
import Loader from "../../component/Loader";
import { FetchTNC, tncListing } from "../slice";

function TNC() {
  const { type } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector((state) => state.main.tnc);
  const [loading, setLoading] = useState(false);
  console.log(data);
  console.log(data);

  useEffect(() => {
    setLoading(true);
    dispatch(FetchTNC({ type })).then(() => {
      setLoading(false);
    });
  }, [dispatch, type]);

  useEffect(() => {
    return () => {
      dispatch(tncListing([]));
    };
  }, [dispatch]);

  return (
    <>
      <section className="sec-bg py-4 px-3 bottom-space">
        <div className="container-fluid">
          <BackButton />
          <div className="row align-items-center">
            <div className="col-sm-12 col-md-12 col-lg-12 mb-3 text-center">
              <h3>Terms and Conditions</h3>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-10 col-lg-12 mb-4 p-5 bg-white">
              {loading ? (
                <Loader />
              ) : (
                <div
                  dangerouslySetInnerHTML={{
                    __html: data?.terms && data?.terms,
                  }}
                ></div>
              )}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default TNC;
