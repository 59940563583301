import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { consentSchema } from "../../utils/schema";
import { useDispatch, useSelector } from "react-redux";
import { onConsentCall, onConsentSelector } from "../slice";
import { consentList } from "../../api/authentications";
import { FormikProvider, useFormik } from "formik";
import { Formik, Form, Field, FieldArray } from "formik";
import { toast } from "react-toastify";
import Loader from "../../component/Loader";
function ConsentModal({
  show,
  handleShow,
  tncValue,
  setModalName,
  onConsent,
  onClose,
  sendConsentData,
  list,
}) {
  const dispatch = useDispatch();
  const consentData = useSelector(onConsentSelector);
  // const [isMedicationNeeded, setIsMedicationNeeded] = useState("");
  const [consent_list, setConsent_list] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!consentData) {
      setLoading(true);
      consentList({ page: 1, limit: 10000 }).then((res) => {
        console.log(res, "Consent Listing");
        setLoading(false);
        if (res?.code === 1) {
          let data = res?.data?.consent_list;
          data?.map((item) => {
            return {
              ...item,
              user_value: [{ consent: "", value: "", note: "" }],
            };
          });
          setConsent_list(data);
        } else {
          toast.error(res?.message);
        }
      });
    }
  }, [consentData]);

  const formik = useFormik({
    initialValues: {
      user_consent:
        consentData?.user_consent?.length > 0
          ? consentData?.user_consent
          : consent_list?.length > 0
          ? consent_list
          : [],
    },
    enableReinitialize: true,
    validationSchema: consentSchema,
    onSubmit: (val) => {
      console.log(val);
      let allChecked = val?.user_consent?.every(
        (it) => it?.user_value && it?.user_value?.value !== ""
      );

      if (allChecked) {
        dispatch(onConsentCall(val));
        onConsent();
        sendConsentData(val);
      } else {
        toast.error("Kindly select for all consent points.");
      }
    },
  });

  useEffect(() => {
    if (consentData && tncValue) {
      formik.resetForm({ user_consent: consentData?.user_consent });
    }
  }, [consentData, tncValue]);

  return (
    <Modal
      show={show}
      onHide={() => {
        if (consentData && tncValue) {
          onConsent();
        } else {
          onClose();
        }
        handleShow();
      }}
      size="xl"
      centered
    >
      <Modal.Body>
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content other-city b-r-20">
            <div className="modal-header text-center">
              <h2 className="mdl-ttl">I Consent</h2>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  handleShow();
                  onClose();
                }}
              ></button>
            </div>
            <div className="modal-body">
              {loading ? (
                <Loader />
              ) : (
                <form
                  className="row px-lg-5 mx-lg-5"
                  onSubmit={formik.handleSubmit}
                >
                  <div className="form-group col-sm-12 col-md-12 mb-4">
                    <FormikProvider value={formik}>
                      <FieldArray
                        name="user_consent"
                        render={() => (
                          <>
                            {formik.values.user_consent?.map((field, i) => {
                              return (
                                <div className=" mt-3">
                                  <label>{field?.label}</label>

                                  <div className="termCustomRadio">
                                    <p>
                                      {field?.values?.map((val, ind) => {
                                        return (
                                          <span className="me-3">
                                            <input
                                              type={field?.input_field_type}
                                              id={`test${i}${ind}`}
                                              name={`radio-group${i}`}
                                              checked={
                                                val?.value?.toLowerCase() ===
                                                formik.values.user_consent?.[
                                                  i
                                                ]?.user_value?.value?.toLowerCase()
                                              }
                                              onChange={() => {
                                                formik.setFieldValue(
                                                  `user_consent.${i}.user_value.value`,
                                                  val?.value
                                                );
                                              }}
                                            />
                                            <label htmlFor={`test${i}${ind}`}>
                                              {val?.value}
                                            </label>
                                          </span>
                                        );
                                      })}
                                    </p>
                                  </div>
                                  {/* {errors?.consent1 && (
                                  <span className="text-danger">
                                    {errors?.consent1?.message}
                                  </span>
                                )} */}

                                  {formik.values.user_consent?.[i]?.id === 6 &&
                                    formik.values.user_consent?.[
                                      i
                                    ]?.user_value?.value?.toLowerCase() ===
                                      "yes" && (
                                      <div class="input-container term-checkbox mt-4 ms-3 d-lg-flex align-items-center">
                                        <span class="position-relative align-items-center">
                                          <input
                                            type="text"
                                            class="textBox form-control ms-2 medication"
                                            placeholder="Name of medication and why it is required?"
                                            name={`user_consent.${i}.user_value.note`}
                                            value={field?.user_value?.note}
                                            onChange={(e) => {
                                              formik.setFieldValue(
                                                `user_consent.${i}.user_value.note`,
                                                e.target.value
                                              );
                                            }}
                                          />
                                        </span>
                                      </div>
                                    )}

                                  {formik.values.user_consent?.[i]?.user_value
                                    ?.note?.length < 1 && (
                                    <span className="text-danger">
                                      Required
                                    </span>
                                  )}
                                </div>
                              );
                            })}
                          </>
                        )}
                      />
                    </FormikProvider>
                  </div>
                  <div className="form-group col-sm-12 col-md-12 text-center">
                    <button
                      type="submit"
                      className="btn btn-submit cursor-pointer"
                      id="IConsentHide"
                    >
                      I Consent
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ConsentModal;
