import {
  deleteData,
  getData,
  getDataforUrl,
  postFormData,
  putFormData,
} from "./index";

// schoolId, classId, child_price, adult_price, students [{studentId: 1, student_type:"child/adult"}], time_slots: [{date:"", time:"24:00". time_slot_for:"junior/senior"}]

export const AddBookingToCart = async (payload) => {
  return postFormData("booking/addToCart", payload).then((data) => {
    return data;
  });
};

export const GetBookingDetails = async (payload) => {
  return getDataforUrl("booking/getBookingDetails", payload).then((data) => {
    return data;
  });
};

export const GetCart = async (payload) => {
  return getData("booking/cartList", payload).then((data) => {
    return data;
  });
};
//  schollId
export const GetClassListWithMontlyTimeSlot = async (payload) => {
  return getData("user/classList", payload).then((data) => {
    return data;
  });
};

export const GetSchoolList = async (payload) => {
  return getData("admin/schoolList", payload).then((data) => {
    return data;
  });
};

// cart_id
export const DeleteFromCart = async (payload) => {
  return deleteData("booking/removeFromCart", payload).then((data) => {
    return data;
  });
};
//  payment_status 1= success 2 = cancelled, total_amount
export const BookingPayment = async (payload) => {
  return postFormData("booking/makePayment", payload).then((data) => {
    return data;
  });
};
