import { getData, postFormData } from "./index";

export const paymentList = async (payload) => {
  return getData("user/paymentList", payload).then((data) => {
    return data;
  });
};

export const paymentDetails = async (payload) => {
  return getData("user/paymentDetails", payload).then((data) => {
    return data;
  });
};
export const paymentDetailsForBookings = async (payload) => {
  return getData("booking/getPaymentDetails", payload).then((data) => {
    return data;
  });
};
export const addWalletAmount = async (payload) => {
  return postFormData("user/addWalletAmount", payload).then((data) => {
    return data;
  });
};
export const createPaymentLinkForPendingBooking = async (payload) => {
  return postFormData(
    "booking/createPaymentLinkForPendingBooking",
    payload
  ).then((data) => {
    return data;
  });
};
