import React from "react";
import { useSelector } from "react-redux";
import { schoolListSelector } from "../features/slice";

function EventCardMain({ data, viewMore }) {
  const schoolList = useSelector(schoolListSelector);

  return (
    <>
      <div className="bg-white p-4 rounded shadow mb-3 h-100">
        <div className="event-box-flex px-0">
          <span>
            <img
              src={
                data?.image?.includes("http")
                  ? data?.image
                  : "assets/img/headerlogo.png"
              }
              className="event-img"
              alt=""
            />
          </span>
          <span>
            <h5>{data?.name || ""}</h5>
            <p className="mb-0">
              {data?.age_group.toLowerCase() === "both"
                ? `Adult | Child`
                : data?.age_group.toLowerCase() || ""}
            </p>
            <p className="theme-color1 mb-0">
              <img src="assets/img/address.png" className="" alt="" />{" "}
              {schoolList?.find((item) => item?.id === data?.schoolId)?.name ||
                ""}
            </p>
          </span>
        </div>
        <div className="joining-flex">
          <span>{/* <small>{item?.area}</small> */}</span>
          <span className="left-seats cursor-pointer" onClick={viewMore}>
            More Info
          </span>
        </div>
      </div>
    </>
  );
}

export default EventCardMain;
