import { deleteData, getData, getDataforUrl, postFormData } from "./index";

export const GetDefaultEventList = async (payload) => {
  return getData("user/eventList", payload).then((data) => {
    return data;
  });
};

export const GetEventDetail = async (payload) => {
  return getData(
    `admin/getEventDetails/${payload?.id}`,
    payload?.studentId
  ).then((data) => {
    return data;
  });
};

export const GetOtherEventList = async (payload) => {
  return getDataforUrl("admin/otherEventList", payload).then((data) => {
    return data;
  });
};

export const GetEventCartList = async (payload) => {
  return getData("booking/eventCartList", payload).then((data) => {
    return data;
  });
};

export const removeFromEventCart = async (payload) => {
  return deleteData("booking/removeFromEventCart", payload).then((data) => {
    return data;
  });
};

export const makePaymentForEventBooking = async (payload) => {
  return postFormData("booking/makePaymentForEventBooking", payload).then(
    (data) => {
      return data;
    }
  );
};
export const addEventToCart = async (payload) => {
  return postFormData("booking/addEventToCart", payload).then((data) => {
    return data;
  });
};

export const GetEventBookingDetails = async (payload) => {
  return getDataforUrl("booking/getEventBookingDetails", payload).then(
    (data) => {
      return data;
    }
  );
};

export const GetbookingList = async (payload) => {
  return getData("booking/bookingList", payload).then((data) => {
    return data;
  });
};
