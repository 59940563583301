import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  AddBooking,
  areaCodeListSelector,
  DeleteCartItem,
  deleteStudentListing,
  eventTotalInCartSelector,
  FetchAreaCodeList,
  FetchCartEventList,
  FetchUserDetail,
  GetCartClassList,
  GetCartClassListSelector,
  GetCartTotalSelector,
  getEventAddedToCartListSelector,
  GetStudentList,
  MonthlyDateClassList,
  monthlyListSelector,
  removeStudentLisitng,
  SchoolList,
  schoolListSelector,
  studentListSelector,
  userDetailSelector,
} from "../slice";
import Carousel from "react-multi-carousel";
import { responsive } from "../../utils";
import StudentCard from "../../component/student/StudentCard";
import { useToggle } from "../../utils/hooks";
import moment from "moment";
import { toast } from "react-toastify";
import Delete from "../../component/Modal/Delete";
import Spinner from "react-bootstrap/Spinner";
import { addEventToCart, removeFromEventCart } from "../../api/Events";
import {
  FetchDefaultEventList,
  getEventLists,
  setEventDetail,
} from "../event/EventSlice";
import EventLevels from "../../component/Modal/EventLevels";
import Loader from "./../../component/Loader";
function BookClass() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetail = useSelector(userDetailSelector);
  const studentList = useSelector(studentListSelector);
  const [student, setStudent] = useState([]);
  const defaultEventList = useSelector(getEventLists);
  const schoolList = useSelector(schoolListSelector);
  // const monthlyList = useSelector(monthlyListSelector);
  const totalCartAmount = useSelector(GetCartTotalSelector);
  const totalEventAmount = useSelector(eventTotalInCartSelector);
  const cartList = useSelector(GetCartClassListSelector);
  const cartEventList = useSelector(getEventAddedToCartListSelector);
  const areacodeList = useSelector(areaCodeListSelector);
  const [selectedTiming, setSelectedTiming] = useState([]);
  const [open, toggle] = useToggle(false);
  const [show, toggle2] = useToggle(false);
  const [openClassFor, setOpenClassFor] = useState("");
  const [index, setIndex] = useState(0);
  const [child_price, setChild_price] = useState("");
  const [adult_price, setAdult_price] = useState("");
  const [delId, setDelId] = useState("");
  const [delOpen, delToggle] = useToggle(false);
  const [loading, setLoading] = useState(true);
  const [teacherId, setTeacherId] = useState("");
  const [areaId, setAreaId] = useState("");
  const [levelShow, setLevelShow] = useState(false);
  const [levels, setLevels] = useState([]);
  const [selectedLevels, setSelectedLevels] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [schoolId, setSchoolId] = useState(null);
  const [discipline, setDiscipline] = useState("");
  const [class_type, setClass_type] = useState("");
  const [age_group, setAge_group] = useState("");
  const [classListLoader, setClassListLoader] = useState(false);
  const [monthlyList, setMonthlyList] = useState([]);
  const [addToCartSubmitting, setAddToCartSubmitting] = useState(false);
  const [isActiveMembership, setIsActiveMembership] = useState(false);
  const [isTrialConsumed, setIsTrialConsumed] = useState(false);
  useEffect(() => {
    dispatch(FetchUserDetail({}));
  }, [dispatch]);

  const studnet = useCallback(() => {
    setLoading(true);
    dispatch(GetStudentList({})).then((res) => {
      setDiscipline(res?.payload?.data?.student_list?.[0]?.discipline);
      setClass_type(res?.payload?.data?.student_list?.[0]?.student_type);
      setStudent([{ ...res?.payload?.data?.student_list?.[0] }]);
      setAge_group(res?.payload?.data?.student_list?.[0]?.student_type);
      setAreaId(res?.payload?.data?.student_list?.[0]?.areacode);
      setSchoolId(res?.payload?.data?.student_list?.[0]?.schoolId);
      setLoading(false);
    });
  }, [dispatch]);

  useEffect(() => {
    studnet();
  }, [studnet]);

  const getClassList = useCallback(() => {
    if (discipline && schoolId && class_type) {
      setClassListLoader(true);
      dispatch(
        MonthlyDateClassList({
          page: 1,
          limit: 10000000000,
          schoolId,
          discipline,
          studentId: student?.[0]?.id || "",
          class_type: class_type,
        })
      ).then((res) => {
        setClassListLoader(false);
        if (res?.payload?.code === 1) {
          let arr = res?.payload?.data?.class_list?.map((item) => {
            let innerArr = item?.time_slot_list?.map((ti) => {
              let aa = ti?.time_slot?.map((inner) => {
                return {
                  ...inner,
                  checked: false,
                };
              });
              return {
                ...ti,
                time_slot: aa,
              };
            });

            return {
              ...item,
              time_slot_list: innerArr,
            };
          });
          setMonthlyList(arr);
        }
      });
    }
  }, [dispatch, schoolId, discipline, student, class_type]);

  useEffect(() => {
    getClassList();
  }, [getClassList]);

  useEffect(() => {
    return () => {
      dispatch(removeStudentLisitng(null));
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(SchoolList({ areacode: areaId }));
  }, [dispatch, areaId]);

  const getCart = useCallback(() => {
    dispatch(GetCartClassList({}));
    dispatch(FetchCartEventList({}));
    dispatch(FetchAreaCodeList({}));
  }, [dispatch]);

  const getEvents = useCallback(() => {
    if (schoolId) {
      dispatch(
        FetchDefaultEventList({
          page: 1,
          limit: 1000,
          studentId: student?.length > 0 ? student?.[0]?.id : "",
          discipline,
          schoolId,
          age_group,
        })
      );
    }
  }, [dispatch, student, discipline, schoolId, age_group]);

  useEffect(() => {
    getEvents();
  }, [getEvents]);

  useEffect(() => {
    getCart();
  }, [getCart]);

  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const {
      carouselState: { currentSlide },
    } = rest;
    return (
      <div className="carousel-button-group top-0" style={{ right: 0 }}>
        <button
          onClick={() => previous()}
          className={"slick-prev slick-arrow"}
          aria-label="Previous"
          type="button"
        >
          Previous
        </button>
        <button
          onClick={() => next()}
          className="slick-next slick-arrow"
          aria-label="Next"
          type="button"
        >
          Next
        </button>
      </div>
    );
  };

  const handleTimeSlot = (e, item, classes, outerIndex, innerIndex) => {
    const checked = e.target.checked;

    let st = [...student];

    console.log('my console->',st)

    const addTimings = () => {
      let arr = [...monthlyList];

      if (checked) {
        let arr2 = arr?.map((itemm) => {
          let innerArr = itemm?.time_slot_list?.map((ti) => {
            let aa = ti?.time_slot?.map((inner) => {
              if (
                inner?.classId?.toString() === item?.classId?.toString() &&
                inner?.time_slot_id?.toString() ===
                  item?.time_slot_id?.toString() &&
                inner?.date?.toString() === item?.date?.toString() &&
                inner?.start_time?.toString() ===
                  item?.start_time?.toString() &&
                inner?.end_time?.toString() === item?.end_time?.toString()
              ) {
                return {
                  ...inner,
                  checked: true,
                };
              }
              return inner;
            });
            return {
              ...ti,
              time_slot: aa,
            };
          });

          return {
            ...itemm,
            time_slot_list: innerArr,
          };
        });
        setMonthlyList(arr2);
        let timingsArr = [...selectedTiming];

        timingsArr?.push(1);
        setSelectedTiming(timingsArr);

        setMonthlyList(arr2);

        // arr?.[outerIndex].time_slots?.[innerIndex]?.checked: true
      } else {
        let arr2 = arr?.map((itemm) => {
          let innerArr = itemm?.time_slot_list?.map((ti) => {
            let aa = ti?.time_slot?.map((inner) => {
              if (
                inner?.classId?.toString() === item?.classId?.toString() &&
                inner?.time_slot_id?.toString() ===
                  item?.time_slot_id?.toString() &&
                inner?.date?.toString() === item?.date?.toString() &&
                inner?.start_time?.toString() ===
                  item?.start_time?.toString() &&
                inner?.end_time?.toString() === item?.end_time?.toString()
              ) {
                return {
                  ...inner,
                  checked: false,
                };
              }
              return inner;
            });
            return {
              ...ti,
              time_slot: aa,
            };
          });

          return {
            ...itemm,
            time_slot_list: innerArr,
          };
        });
        let timingsArr = [...selectedTiming];
        if (timingsArr?.length > 0) {
          timingsArr?.splice(0, 1);
          setSelectedTiming(timingsArr);
        }
        setMonthlyList(arr2);
      }
    };

    let isTrailAvailable = st.every((element) => {
      return element.trialAccountStatus === "1";
    });

    let membershipPurchased = st.every((element) => {
      return element.trialAccountStatus === "3";
    });

    let trailUtlized = st.every((element) => {
      return element.trialAccountStatus === "2";
    });
    let stud = st?.[0];
    console.log('total--', selectedTiming?.length +' '+ stud?.no_of_item_in_cart +' '+ stud?.no_of_bookings)

    if (checked) {
      if (isTrailAvailable) {
        if (
          selectedTiming?.length +
            stud?.no_of_item_in_cart +
            stud?.no_of_bookings <
          4
        ) {
          addTimings();
        } else {
          toast.error(
            "Only 4 trial lessons are available in trial membership."
          );
        }
      } else if (membershipPurchased) {
        addTimings();
      } else if (trailUtlized) {
        toast.error("You have utilised all lessons in your trial membership.");
      } else if (!isTrailAvailable) {
        toast.error(
          "Only 4 lessons available in trial membership. Kindly upgrade your membership to book classes."
        );
      }
    } else {
      addTimings();
    }
  };

  const handleAddToCart = () => {
    setAddToCartSubmitting(true);
    const addTimeSlots = () => {
      let arr = [...student].map((item) => {
        return {
          studentId: item?.id,
          student_type: item?.student_type,
          bookedInTrialAccount: item?.trialAccountStatus === "1" ? true : false,
        };
      });
      let arr2 = [...monthlyList]
        ?.map((item) => {
          let time_slots = [];

          item?.time_slot_list?.forEach((time) => {
            time?.time_slot?.forEach((slot) => {
              if (slot?.checked) {
                time_slots?.push({
                  date: slot?.date,
                  start_time: moment(slot?.start_time, "HH:mm:ss").format(
                    "HH:mm:ss"
                  ),
                  end_time: moment(slot?.end_time, "HH:mm:ss").format(
                    "HH:mm:ss"
                  ),
                  time_slot_for: slot?.time_slot_for,
                  time_slot_id: slot?.time_slot_id,
                });
              }
            });
          });

          return {
            classId: item?.id,
            teacherId: item?.assigned_teacher_id,
            child_price: item?.child_price,
            adult_price: item?.adult_price,
            time_slots,
          };
        })
        ?.filter((it) => it?.time_slots?.length > 0);

      dispatch(
        AddBooking({
          schoolId,
          areacode: areaId,
          students: JSON.stringify(arr),
          class_items: JSON.stringify(arr2),
        })
      )
        .then((res) => {
          setAddToCartSubmitting(false);

          if (res?.payload?.code === 1) {
            if (
              res?.payload?.message
                ?.toLowerCase()
                ?.includes(
                  "you can only book four classes/events for a trial student."
                )
            ) {
              toast.error(res?.payload?.message);
            } else {
              toast.success(res?.payload?.message);
            }
          } else {
            toast.error(res?.payload?.message);
          }
        })
        .then(() => {
          setSelectedTiming([]);
          studnet();
          dispatch(deleteStudentListing([]));
          dispatch(GetStudentList({}));
          getClassList();
        });
    };
    if (teacherId) {
      if (student.length > 0) {
        addTimeSlots();
      } else {
        toast.error("Kindly select a student.");
      }
    } else {
      toast.error(
        "No teacher available for this class. Kindly select another class."
      );
    }
  };

  const isItemAdded = () => {
    if (monthlyList.length > 0) {
      let selectedTiming = [];

      [...monthlyList]?.forEach((item) => {
        // return {
        //   date: item?.date,
        //   start_time: moment(item?.start_time, "HH:mm:ss").format("HH:mm:ss"),
        //   end_time: moment(item?.end_time, "HH:mm:ss").format("HH:mm:ss"),
        //   time_slot_for: item?.time_slot_for,
        //   time_slot_id: item?.time_slot_id,
        // };

        item?.time_slot_list?.forEach((time) => {
          if (time?.time_slot?.length > 0) {
            time?.time_slot?.forEach((slot) => {
              if (slot?.checked) {
                selectedTiming.push("checked");
              }
            });
          }
        });
      });

      return selectedTiming?.length > 0 ? true : false;
    }
  };

  const showAddToCart = isItemAdded();

  const confirmDelete = () => {
    dispatch(DeleteCartItem({ cart_id: JSON.stringify(delId) })).then((res) => {
      if (res?.payload?.code === 1) {
        dispatch(deleteStudentListing([]));
        dispatch(GetStudentList({}));
        studnet();

        setSelectedTiming([]);
        getCart();
        getClassList();

        toast.success(res?.payload?.message);
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  const handleRemoveEventFromCart = () => {
    removeFromEventCart({ cart_id: JSON.stringify(delId) }).then((res) => {
      if (res?.code === 1) {
        toast.success(res?.message);
        dispatch(GetStudentList({}));
        getCart();
        getEvents();
      } else {
        toast.error(res?.message);
      }
    });
  };

  const handleAddEventToCart = (evnt, levl, _child_price, _adult_price) => {
    let membershipPurchased = student.every((element) => {
      return element.trialAccountStatus === "3";
    });

    const eventtocart = () => {
      let payload = {};
      if (evnt?.teacher_details !== null) {
        payload = {
          areacode: evnt?.areacode_details?.id,
          schoolId: evnt?.schoolId,
          eventId: evnt?.id,
          date: evnt?.date,
          area: evnt?.areacode_details?.id,
          age_group: evnt?.age_group,
          teacherId: evnt?.teacher_details?.id,
          price:
            evnt?.levels?.length > 0
              ? ""
              : student?.[0]?.student_type === "child"
              ? evnt?.child_price
              : student?.[0]?.student_type === "adult"
              ? evnt?.adult_price
              : "",
          students: JSON.stringify(
            student?.map((it) => {
              return { studentId: it.id.toString() };
            })
          ),
          levels:
            levl?.length > 0
              ? JSON.stringify(
                  levl?.map((it) => {
                    return {
                      id: it?.id,
                      price:
                        student?.[0]?.student_type === "child"
                          ? it?.child_price
                          : student?.[0]?.student_type === "adult"
                          ? it?.adult_price
                          : "",
                    };
                  })
                )
              : JSON.stringify([]),
        };
      } else {
        payload = {
          areacode: evnt?.areacode_details?.id,
          schoolId: evnt?.schoolId,
          eventId: evnt?.id,
          date: evnt?.date,
          area: evnt?.areacode_details?.id,
          age_group: evnt?.age_group,
          price:
            evnt?.levels?.length > 0
              ? ""
              : student?.[0]?.student_type === "child"
              ? evnt?.child_price
              : student?.[0]?.student_type === "adult"
              ? evnt?.adult_price
              : "",
          students: JSON.stringify(
            student?.map((it) => {
              return { studentId: it.id.toString() };
            })
          ),
          levels:
            levl?.length > 0
              ? JSON.stringify(
                  levl?.map((it) => {
                    return {
                      id: it?.id,
                      price:
                        student?.[0]?.student_type === "child"
                          ? it?.child_price
                          : student?.[0]?.student_type === "adult"
                          ? it?.adult_price
                          : "",
                    };
                  })
                )
              : JSON.stringify([]),
        };
      }

      addEventToCart(payload).then((res) => {
        if (res?.code === 1) {
          if (
            res?.message
              ?.toLowerCase()
              ?.includes(
                "you can only book four classes/events for a trial student."
              )
          ) {
            toast.error(res?.message);
          } else {
            toast.success(res?.message);
          }
          getEvents();
          dispatch(GetCartClassList({}));
          dispatch(FetchCartEventList({}));
          setSelectedLevels([]);
          setLevelShow(false);
          setSelectedEvent(null);
        } else {
          toast.error(res?.message);
        }
      });
    };

    if (cartList?.includes(evnt)) {
      toast.error("Item already added in the cart. Kindly ");
    } else {
      if (student?.length === 0) {
        toast.error("Kindly select a student for the event.");
      } else {
        if (membershipPurchased) {
          eventtocart();
        } else {
          toast.error(
            "Kindly upgrade to full membership to purchase the event."
          );
        }
      }
    }
  };

  const ActiveMember = useCallback(() => {
    const arr = [...student];
    let ab = arr.every((it) => {
      console.log(it, "Student selected");
      if (it?.membership_details && it?.trialAccountStatus === "3") {
        const expiryDate = moment(it?.membership_details?.valid_till);
        const today = moment();
        let difference = moment.duration(expiryDate.diff(today)).asHours();
        console.log(difference, "Diff");

        if (difference >= 1) {
          return true;
        }
      } else if (it?.trialAccountStatus === "1") return true;
      return false;
    });

    console.log(ab, "Ab value");

    return ab;
  }, [student]);

  const ClassTillMembership = (i) => {
    if (monthlyList?.length > 0 && student?.length > 0) {
      let arr = [...student];

      let arr2 = [...monthlyList];
      console.log('This is i==', i)
      console.log(arr, arr2, "MOnthly list and student");

      let obj = arr2.find((it, ind) => i === ind);
      console.log(obj)
      if (obj && obj?.time_slot_list?.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  };

  const TrialConsumed = useCallback(() => {
    const arr = [...student];
    let ab = arr.every((it) => {
      console.log(it, "Student selected");
      if (it?.trialAccountStatus === "2") {
        return true;
      }
      return false;
    });

    return ab;
  }, [student]);

  useEffect(() => {
    if (student?.length > 0) {
      let ab = ActiveMember();
      console.log(ab, "Is active member");
      setIsActiveMembership(ab);
      let aa = TrialConsumed();

      setIsTrialConsumed(aa);
    }
  }, [student?.length, isActiveMembership, ActiveMember, TrialConsumed]);

  // const isActiveMembership = isInactiveMember();

  return (
    <section className="sec-bg py-4 px-3 bottom-space">
      <div className="container-fluid">
        <div className="row">
          {userDetail?.consent?.length === 0 && (
            <div className="cols-m-12 text-center dangerMessage">
              {userDetail?.consent?.length === 0 ? (
                <span className="">
                  You may not be able to make bookings if you have not entered
                  your consent preferences in ‘Edit Profile’ –{" "}
                  <span
                    onClick={() => {
                      navigate("/edit-profile");
                    }}
                    className="cursor-pointer"
                  >
                    <b>Click here</b>
                  </span>
                  .
                </span>
              ) : (
                ""
              )}
            </div>
          )}
          <div className="col-sm-12 col-md-12 col-lg-12 mt-3">
            <h3>Booking Page</h3>
            <p>Which Student to Book For</p>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-8 col-xl-9 mb-3">
            <div className="row align-items-center">
              <div className="col-sm-12 col-md-12 col-lg-12 mt-3"></div>
              {loading ? (
                <div className="col-sm-12 text-center">
                  <Spinner animation="grow" />
                </div>
              ) : (
                <div className="col-sm-12 col-md-12 col-lg-12 mt-3 position-relative">
                  <div className="owl-slider Studentevent-slider student-container">
                    {studentList?.length > 0 ? (
                      <Carousel
                        responsive={responsive}
                        renderButtonGroupOutside={true}
                        customButtonGroup={<ButtonGroup />}
                        arrows={false}
                        ssr={true}
                      >
                        {studentList?.map((item, i) => (
                          <StudentCard
                            item={item}
                            key={i}
                            student={student}
                            setSchoolId={setSchoolId}
                            setStudent={setStudent}
                            setDiscipline={setDiscipline}
                            setClass_type={setClass_type}
                            setAreaId={setAreaId}
                            setAge_group={setAge_group}
                            setSelectedTiming={setSelectedTiming}
                          />
                        ))}
                      </Carousel>
                    ) : (
                      <div className="text-center">No student added.</div>
                    )}
                  </div>
                </div>
              )}

              <div className="col-sm-12 col-md-12 col-lg-12">
                <hr />
              </div>

              <div className="col-sm-12 col-md-12 col-lg-12 mt-3">
                <div className="d-flex align-items-center">
                  <span>All Areas:</span>
                  <div className="form-style mw-auto ms-2">
                    <div className="input-container">
                      <select
                        className="form-control ps-4 pe-5 form-select"
                        value={areaId || undefined}
                        onChange={(e) => {
                          const val = e.target.value;
                          setAreaId(val);
                        }}
                      >
                        <option value="">Area*</option>
                        {areacodeList?.map((item, i) => (
                          <option value={item?.id || ""} key={i}>
                            {item?.areacode}
                          </option>
                        ))}
                      </select>
                      <img
                        src="assets/img/address.png"
                        className="input-img"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              {areaId && (
                <div className="col-sm-12 col-md-12 col-lg-12 mt-3">
                  <div className="d-flex align-items-center">
                    <span>All Areas:</span>
                    <div className="form-style mw-auto ms-2">
                      <div className="input-container">
                        <select
                          className="form-control ps-4 pe-5 form-select"
                          value={schoolId || undefined}
                          onChange={(e) => {
                            const val = e.target.value;
                            setSchoolId(val);
                          }}
                        >
                          <option value="">School Venue*</option>
                          {schoolList?.map((item, i) => (
                            <option value={item?.id || ""} key={i}>
                              {item?.name}
                            </option>
                          ))}
                        </select>
                        <img
                          src="assets/img/address.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {schoolId &&
                schoolList
                  ?.filter((item) => {
                    return item.id === parseInt(schoolId);
                  })
                  ?.map((item, i) => (
                    <div className="col-sm-12 col-md-12 col-lg-12 mt-3" key={i}>
                      <div className="bg-white p-3 rounded expandible">
                        <div className="row">
                          <div className="col-sm-12 col-md-12 col-lg-12 mb-2 text-capitalize">
                            <h4>{item?.name}</h4>
                            <p className="mb-2">
                              <img
                                src="assets/img/address.png"
                                alt=""
                                className="me-1"
                              />
                              {item?.address}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              {schoolId && areaId && (
                <div className="col-sm-12 col-md-12 col-lg-12 mt-3">
                  {classListLoader ? (
                    <Loader />
                  ) : (
                    <div
                      className="accordion Classes-colles"
                      id="accordionExample"
                    >
                      {student?.[0]?.trialAccountStatus === "0" ? (
                        <p className="text-danger">
                          Kindly purchase membership to book classes.
                        </p>
                      ) : (
                        monthlyList?.map((item, i) => {
                          let isClassAvailableTillMembershipDate =
                            ClassTillMembership(i);
                          return (
                            <div
                              className="bg-white p-2 text-capitalize mt-3"
                              key={i}
                            >
                              <div className="accordion-item">
                                <h2 className="accordion-header" id="heading1">
                                  <button
                                    className="accordion-button displayFlex "
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#collapse1${item.id}`}
                                    aria-expanded="true"
                                    aria-controls="collapse1"
                                    onClick={() => {
                                      setTeacherId(item?.assigned_teacher_id);
                                      setOpenClassFor(item?.id);
                                      // setChild_price(item?.child_price);
                                      // setAdult_price(item?.adult_price);
                                    }}
                                  >
                                    <div className="event-box-flex px-0">
                                      <span>
                                        <img
                                          src={
                                            item?.image?.includes("http")
                                              ? item?.image
                                              : "assets/img/kick.png"
                                          }
                                          className="event-img"
                                          alt=""
                                        />
                                      </span>
                                      <span>
                                        <h5>{item?.name}</h5>
                                        <p className="mb-0">
                                          {item?.child_price && (
                                            <>
                                              child{" "}
                                              <span className="theme-color me-2">
                                                <i
                                                  className="fa fa-gbp me-1"
                                                  aria-hidden="true"
                                                ></i>
                                                {item?.child_price}
                                              </span>
                                            </>
                                          )}
                                          {item?.adult_price && (
                                            <>
                                              adult
                                              <span className="theme-color ms-2">
                                                <i
                                                  className="fa fa-gbp me-1"
                                                  aria-hidden="true"
                                                ></i>
                                                {item?.adult_price}
                                              </span>
                                            </>
                                          )}
                                        </p>
                                      </span>
                                    </div>
                                  </button>
                                </h2>
                                <div
                                  id={`collapse1${item.id}`}
                                  className={
                                    open && openClassFor === item?.id
                                      ? "accordion-collapse collapse show"
                                      : "accordion-collapse collapse "
                                  }
                                  aria-labelledby="heading1"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body">
                                    <div className="calendar">
                                      <div className="month mb-3">
                                        <ul>
                                          <li
                                            className="prev cursor-pointer"
                                            onClick={() => {
                                              if (index === 0) {
                                                setIndex(
                                                  item?.time_slot_list?.length -
                                                    1
                                                );
                                              } else if (
                                                index <=
                                                item?.time_slot_list?.length - 1
                                              ) {
                                                setIndex(index - 1);
                                              } else {
                                                setIndex(0);
                                              }
                                            }}
                                          >
                                            &#10094;
                                          </li>
                                          <li>
                                            {item?.time_slot_list[index]?.month}{" "}
                                            {item?.time_slot_list[index]?.year}
                                          </li>
                                          <li
                                            className="next cursor-pointer"
                                            onClick={() => {
                                              if (
                                                index <
                                                item?.time_slot_list?.length - 1
                                              ) {
                                                setIndex(index + 1);
                                              } else {
                                                setIndex(0);
                                              }
                                            }}
                                          >
                                            &#10095;
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    {classListLoader ? (
                                      <Loader />
                                    ) : isTrialConsumed ? (
                                      <span className="text-danger">
                                        You have consumed your trial account
                                        lessons. Kindly upgrade your membership
                                        to start booking.
                                      </span>
                                    ) : !isActiveMembership ? (
                                      <span className="text-danger">
                                        You membership is expired. Kindly renew
                                        your membership to start booking.
                                      </span>
                                    ) : !isClassAvailableTillMembershipDate ? (
                                      <span className="text-red">
                                        No available classes before your
                                        membership expiry date. Kindly upgrade
                                        your membership to start booking.
                                      </span>
                                    ) : isActiveMembership ? (
                                      <div className="row student-container">
                                        {item?.time_slot_list?.[index]
                                          ?.time_slot?.length > 0 ? (
                                          item?.time_slot_list?.[
                                            index
                                          ]?.time_slot?.map((itemm, ind) => (
                                            <div
                                              className="col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-3"
                                              key={ind}
                                            >
                                              <input
                                                type="checkbox"
                                                name="classes"
                                                id={`classes1${ind}${i}`}
                                                className="checkBoxClass"
                                                checked={itemm?.checked}
                                                onChange={(e) => {
                                                  if (
                                                    userDetail?.consent
                                                      ?.length === 0
                                                  ) {
                                                    toast.error(
                                                      "Kindly fill the consent for your profile to start booking."
                                                    );
                                                  } else {
                                                    if (itemm?.isCancelled) {
                                                      toast.error(
                                                        "Class has been cancelled."
                                                      );
                                                    } else {
                                                      if (student?.length > 0) {
                                                        if (
                                                          userDetail?.wallet < 0
                                                        ) {
                                                          navigate(
                                                            "/make-payment",
                                                            {
                                                              state: {
                                                                amount:
                                                                  userDetail?.wallet,
                                                                payment_for:
                                                                  "wallet",
                                                              },
                                                            }
                                                          );
                                                          toast.error(
                                                            "Kindly clear your pending wallet amount to continue booking."
                                                          );
                                                        } else {
                                                          if (itemm?.isBooked) {
                                                            toast.error(
                                                              "You have already booked this time slot."
                                                            );
                                                          } else {
                                                            if (
                                                              itemm.isAddedInCart
                                                            ) {
                                                              toast.error(
                                                                "Item already added in cart."
                                                              );
                                                            } else {
                                                              if (
                                                                itemm?.available_seats >
                                                                  0 &&
                                                                itemm?.available_seats <=
                                                                  item?.total_seats
                                                              ) {
                                                                console.log(
                                                                  itemm,
                                                                  item,
                                                                  "Item to add to"
                                                                );
                                                                let innerTimeSlot =
                                                                  itemm;
                                                                let outerClassDetail =
                                                                  item;
                                                                let outerIndex =
                                                                  i;
                                                                let innerIndex =
                                                                  ind;
                                                                handleTimeSlot(
                                                                  e,
                                                                  innerTimeSlot,
                                                                  outerClassDetail,
                                                                  outerIndex,
                                                                  innerIndex
                                                                );
                                                              } else {
                                                                toast.error(
                                                                  "Sorry, all the available seats are booked for this class."
                                                                );
                                                              }
                                                            }
                                                          }
                                                        }
                                                      } else {
                                                        toast.error(
                                                          "Kindly select a student."
                                                        );
                                                      }
                                                    }
                                                  }
                                                }}
                                              />
                                              <label
                                                htmlFor={`classes1${ind}${i}`}
                                                className="student__details"
                                              >
                                                <h5>
                                                  {moment(itemm?.date).format(
                                                    "dddd"
                                                  )}{" "}
                                                  {moment(itemm?.date).format(
                                                    "DD MMM"
                                                  )}
                                                </h5>
                                                <p className="mb-0">
                                                  <label className="theme-color">
                                                    {itemm?.time_slot_for ===
                                                    "both"
                                                      ? "Age Groups: "
                                                      : "Age Group: "}
                                                  </label>{" "}
                                                  {itemm?.time_slot_for ===
                                                  "both"
                                                    ? `Adult | Child`
                                                    : itemm?.time_slot_for}
                                                </p>
                                                <p className="mb-0">
                                                  <label className="theme-color">
                                                    Time:
                                                  </label>{" "}
                                                  {moment(
                                                    itemm?.start_time,
                                                    "hh:mm:ss"
                                                  ).format("hh:mm a")}
                                                  -{" "}
                                                  {moment(
                                                    itemm?.end_time,
                                                    "hh:mm:ss"
                                                  ).format("hh:mm a")}
                                                </p>

                                                <p className="d-flex justify-content-between">
                                                  <span
                                                    className={
                                                      itemm?.isCancelled
                                                        ? "text-danger"
                                                        : "text-success"
                                                    }
                                                  >
                                                    Status:{" "}
                                                    {itemm?.isCancelled
                                                      ? "Cancelled"
                                                      : "Active"}
                                                  </span>
                                                  <span>
                                                    {itemm?.isBooked && (
                                                      <span className="text-danger">
                                                        Already Booked
                                                      </span>
                                                    )}
                                                    {itemm?.isAddedInCart && (
                                                      <span className="text-danger">
                                                        Already in Cart
                                                      </span>
                                                    )}
                                                  </span>
                                                </p>
                                              </label>
                                            </div>
                                          ))
                                        ) : (
                                          <span>No classes found.</span>
                                        )}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      )}

                      <div className="row">
                        {selectedTiming?.length > 0 && (
                          <div className="col-sm-12 col-md-12 col-lg-12 text-center mb-3">
                            <button
                              className="btn btn-submit fs-5 mb-1 w-auto"
                              type="button"
                              onClick={() => {
                                if (userDetail?.wallet < 0) {
                                  navigate("/make-payment", {
                                    state: {
                                      amount: userDetail?.wallet,
                                      payment_for: "wallet",
                                    },
                                  });
                                  toast.error(
                                    "Kindly clear your pending wallet amount to continue booking."
                                  );
                                } else {
                                  handleAddToCart();
                                }
                              }}
                              disabled={addToCartSubmitting}
                            >
                              {addToCartSubmitting ? (
                                <Loader />
                              ) : (
                                <>
                                  {" "}
                                  <img
                                    src="assets/img/cart.png"
                                    className=""
                                    alt=""
                                  />{" "}
                                  Add to Cart
                                </>
                              )}
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
              <div className="col-sm-12 col-md-12 col-lg-12 mt-3">
                <label className="fw-bold mt-3">
                  <h4>Events</h4>
                </label>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 mt-3 position-relative">
                <div className="owl-slider Studentevent-slider student-container bookClassEvents">
                  {student?.[0]?.trialAccountStatus === "0" ? (
                    <p className="text-danger">
                      Kindly purchase membership to book events.
                    </p>
                  ) : student?.length > 0 && isTrialConsumed ? (
                    <span className="text-danger">
                      Kindly upgrade your membership to start booking events.
                    </span>
                  ) : !isActiveMembership ? (
                    <span className="text-danger">
                      Your membership is expired. Kindly renew your membership
                      to start booking for events.
                    </span>
                  ) : defaultEventList?.length > 0 ? (
                    <Carousel
                      responsive={responsive}
                      renderButtonGroupOutside={true}
                      customButtonGroup={<ButtonGroup />}
                      arrows={false}
                      draggable={true}
                      swipeable={true}
                    >
                      {defaultEventList?.map((item, i) => (
                        <div className="mx-2" key={i}>
                          <div className="class__details bg-white p-2 cursor-pointer rounded h-160 position-relative w-100">
                            <div className="event-box-flex align-items-normal p-0">
                              <span>
                                <img
                                  src={
                                    item?.image?.includes("http")
                                      ? item?.image
                                      : "assets/img/headerlogo.png"
                                  }
                                  className="event-img"
                                  alt=""
                                />
                              </span>
                              <span className="ellipse">
                                <h6
                                  onClick={() => {
                                    navigate(`/event/${item?.id}`);
                                  }}
                                  className="cursor-pointer ellipse mb-0"
                                >
                                  {item?.name || "NA"}
                                </h6>
                                <p className="mb-0 fs-7 ellipse">
                                  {item?.discipline_details.name || "NA"}
                                </p>
                                <p className="mb-0 fs-7 ellipse">
                                  {item?.age_group.toLowerCase() === "both"
                                    ? "Adult |Child"
                                    : item?.age_group}
                                </p>
                                <p className="mb-0 fs-7 ellipse">
                                  <img
                                    src="assets/img/address.png"
                                    className=""
                                    alt=""
                                  />{" "}
                                  {item?.school_details?.name || "NA"}
                                </p>
                                <p className="mb-0 fs-7">
                                  <img
                                    src="assets/img/date-of-birth.png"
                                    className=""
                                    alt=""
                                  />{" "}
                                  {item?.date
                                    ? moment(item?.date).format("DD MMM YYYY")
                                    : "NA"}
                                </p>
                                {item?.levels?.length > 0 && (
                                  <p className="mb-0 fs-7 ellipse">
                                    <span>Levels: </span> {item?.levels?.length}
                                  </p>
                                )}
                              </span>
                            </div>
                            <div className="bookClassEventCard event-view-btns view-btns justify-content-sm-end position-sm-absolute eventButtons">
                              <button
                                className="left-seats py-1 px-2 fs-7 cursor-pointer mx-1"
                                onClick={() => {
                                  let studentId = student[0]?.id;
                                  navigate(`/event/${item?.id}/${studentId}`);
                                }}
                              >
                                View Event
                              </button>
                              {student.every((element) => {
                                return (
                                  element.trialAccountStatus === "3" &&
                                  element?.membership_details !== null
                                );
                              }) ? (
                                <button
                                  className="left-seats py-1 px-2 fs-7 cursor-pointer"
                                  onClick={() => {
                                    const studentDiscipline = [...student]?.[0]
                                      ?.student_type;

                                    const eventDiscipline =
                                      item?.age_group?.toLowerCase();

                                    const isSameDiscipline =
                                      studentDiscipline === eventDiscipline ||
                                      eventDiscipline === "both";

                                    if (userDetail?.consent?.length === 0) {
                                      toast.error(
                                        "Kindly fill the consents for your profile to start booking."
                                      );
                                    } else {
                                      if (isSameDiscipline) {
                                        if (item?.levels?.length > 0) {
                                          setLevelShow(!levelShow);
                                          setLevels(item?.levels);
                                          setSelectedEvent(item);
                                        } else {
                                          if (!item?.isBooked) {
                                            if (!item?.isAddedInCart) {
                                              let evnt = item;
                                              let levl = [];
                                              handleAddEventToCart(evnt, levl);
                                            } else {
                                              toast.error(
                                                "Event already added in cart."
                                              );
                                            }
                                          } else {
                                            toast.error(
                                              "Already booked for this event."
                                            );
                                          }
                                        }
                                      } else {
                                        toast.error(
                                          `${studentDiscipline} can not book event for ${eventDiscipline}`
                                        );
                                      }
                                    }
                                  }}
                                  disabled={
                                    (item?.levels?.length === 0 &&
                                      item?.isAddedInCart) ||
                                    (item?.levels?.length === 0 &&
                                      item?.isBooked)
                                  }
                                >
                                  {item?.levels?.length === 0 &&
                                  item?.isAddedInCart
                                    ? "Added to Cart"
                                    : item?.levels?.length === 0 &&
                                      item?.isBooked
                                    ? "Already Booked"
                                    : "Add to cart"}
                                </button>
                              ) : (
                                <span
                                  className="px-1 theme-color"
                                  onClick={() => {
                                    navigate(`/plans/${student?.[0]?.id}`);
                                  }}
                                >
                                  Upgrade to full Membership
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </Carousel>
                  ) : (
                    <div>No new events.</div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-4 col-xl-3 mb-3">
            <div className="py-4 px-3 record-listing h-100">
              {
                <div className="btn-accept btn-view w-auto">
                  Balance/Credit:{" "}
                  <i className="fa fa-gbp" aria-hidden="true"></i>{" "}
                  {userDetail?.wallet || 0}
                </div>
              }

              <div
                className={
                  cartList?.length > 0 || cartEventList?.cart_data?.length > 0
                    ? "overflowYScroll"
                    : ""
                }
              >
                {cartList?.length > 0
                  ? cartList?.map((item, i) => (
                      <div className="bg-white p-2 mt-4 rounded h-auto shadow position-relative">
                        <div className="row">
                          <div className="col-sm-6 col-md-6 col-lg-9 mb-2">
                            <div className="event-box-flex BookClassCartCard CartStdtTble">
                              <span>
                                <img
                                  src={
                                    item?.class_details?.image?.includes("http")
                                      ? item?.class_details?.image
                                      : "assets/img/kick.png"
                                  }
                                  className="event-img"
                                  alt=""
                                />
                              </span>
                              <span className="">
                                <h4>{item?.class_details?.name}</h4>
                                <p className="fw-bold mb-1">
                                  {item?.time_slot_list
                                    ?.map((item) => {
                                      return `${moment(item?.date).format(
                                        "DD MMM"
                                      )}, ${moment(
                                        item?.start_time,
                                        "HH:mm:ss"
                                      ).format("hh:mm a")}-${moment(
                                        item?.end_time,
                                        "HH:mm:ss"
                                      ).format("hh:mm a")}`;
                                    })
                                    .join(", ")}
                                </p>
                                {item?.student_list?.map((item, i) => (
                                  <p className="mb-0" key={i}>
                                    {`${item?.student_details?.first_name} ${item?.student_details?.last_name}`}
                                  </p>
                                ))}
                              </span>
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-3 mb-2 text-end">
                            <button className="btn btn-submit closeIcon p-0 mt-0 w-auto bg-transparent">
                              <img
                                src="assets/img/closeIcon.png"
                                className="img-fluid"
                                alt=""
                                onClick={() => {
                                  setDelId(item?.cart_id);
                                  delToggle();
                                }}
                              />
                            </button>

                            <h5 className="theme-color h5PriceClass">
                              <i className="fa fa-gbp" aria-hidden="true"></i>{" "}
                              {item?.amount}
                            </h5>
                          </div>
                        </div>
                      </div>
                    ))
                  : null}
                {cartEventList?.cart_data?.length > 0
                  ? cartEventList?.cart_data?.map((item) => (
                      <div
                        className="bg-white p-2 mt-4 rounded h-auto shadow position-relative"
                        key={item?.id}
                      >
                        <div className="row">
                          <div className="col-sm-6 col-md-6 col-lg-9 mb-2">
                            <div className="event-box-flex BookClassCartCard CartStdtTble">
                              <span>
                                <img
                                  src={
                                    item?.event_details?.image?.includes("http")
                                      ? item?.event_details?.image
                                      : "assets/img/kick.png"
                                  }
                                  className="event-img"
                                  alt=""
                                />
                              </span>
                              <span className="mb-0">
                                <h4>{item?.event_details?.name}</h4>
                                <p className="mb-0 text-muted">
                                  {item?.school_details?.name}
                                </p>
                                <p>
                                  On: {moment(item?.date).format("DD MMM YYYY")}
                                </p>
                                {item?.student_list?.map((item, i) => (
                                  <p className="mb-0" key={i}>
                                    {`${item?.student_details?.first_name} ${item?.student_details?.last_name}`}
                                  </p>
                                ))}
                              </span>
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-3 mb-2 text-end">
                            <button className="btn btn-submit closeIcon p-0 mt-0 w-auto bg-transparent">
                              <img
                                src="assets/img/closeIcon.png"
                                className="img-fluid"
                                alt=""
                                onClick={() => {
                                  setDelId(item?.cart_id);
                                  toggle2();
                                }}
                              />
                            </button>

                            <h5 className="theme-color h5PriceClass">
                              <i className="fa fa-gbp" aria-hidden="true"></i>{" "}
                              {item?.amount
                                ? item?.amount
                                : item?.event_details?.child_price
                                ? item?.event_details?.child_price
                                : item?.event_details?.adult_price
                                ? item?.event_details?.adult_price
                                : "NA"}
                            </h5>
                          </div>
                        </div>
                      </div>
                    ))
                  : null}
              </div>

              {(cartList?.length > 0 ||
                cartEventList?.cart_data?.length > 0) && (
                <div className="text-end">
                  <button
                    className="btn btn-submit fs-5 mb-1 w-auto"
                    onClick={() => {
                      navigate("/cart");
                    }}
                  >
                    <img src="assets/img/cart.png" className="" alt="" /> Go To
                    Cart
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        {delId && (
          <Delete
            show={delOpen}
            handleShow={(stat) => {
              if (stat === "confirm") {
                confirmDelete();
              }
              setDelId("");
              delToggle();
            }}
          />
        )}

        {levelShow && levels.length > 0 && selectedEvent && (
          <EventLevels
            show={levelShow}
            handleShow={() => {
              setLevelShow(!levelShow);
              // setLevels([]);
              // setSelectedEvent(null);
            }}
            setSelectedEvent={setSelectedEvent}
            child_price={selectedEvent?.child_price}
            adult_price={selectedEvent?.adult_price}
            levels={levels}
            setSelectedLevels={setSelectedLevels}
            onDone={(selectedLevel, child_price, adult_price) => {
              handleAddEventToCart(
                selectedEvent,
                selectedLevel,
                child_price,
                adult_price
              );
            }}
          />
        )}

        <Delete
          show={show}
          handleShow={(val) => {
            if (val === "confirm") {
              handleRemoveEventFromCart();
            }
            toggle2();
          }}
        />
      </div>
    </section>
  );
}

export default BookClass;
