import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { FetchMembershipListing } from "../../features/membership/MembershipSlice";
import { addMemberShip } from "../../features/slice";
import Loader from "../Loader";
import ReadMore from "./../ReadMore/ReadMore";

function BuyMembershipModal({
  show,
  handleShow,
  discipline,
  student_type,
  studentId,
  handleBuy,
  membershipId,
  submitting,
}) {
  console.log(membershipId, "Membership id inside modal");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const list = useSelector((state) => state.membership.list);
  const [showTNC, setShowTNC] = useState(false);
  const [selectedMembershipLevel, setSelectedMembershipLevel] = useState(null);
  const [tnc, setTnc] = useState(false);
  const [error, setError] = useState("");
  useEffect(() => {
    if (student_type && discipline) {
      dispatch(
        FetchMembershipListing({
          page: 1,
          limit: 10000,
          discipline: discipline,
          student_type,
        })
      ).then((res) => {
        if (res?.payload?.code === 1) {
          let arr = res?.payload?.data?.membership_list;

          setSelectedMembershipLevel(
            arr?.find((el) => {
              console.log(el?.id?.toString(), membershipId);
              return el?.id?.toString() === membershipId?.toString();
            })
          );
        }
      });
    }
  }, [dispatch, discipline, student_type, membershipId]);

  const handleAddMembership = (e) => {
    e.preventDefault();

    if (tnc) {
      handleBuy(selectedMembershipLevel);
    } else {
      setError("Kindly accept the terms and conditions.");
    }
  };

  return (
    <Modal show={show} onHide={handleShow} size="lg" centered>
      <Modal.Body>
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content other-city b-r-20">
            <div className="modal-header d-grid text-center">
              <h2 className="mdl-ttl">membership</h2>

              <button
                type="button"
                className="btn-close"
                onClick={() => {
                  handleShow();
                }}
              ></button>
            </div>
            <div className="modal-body">
              <div className="row student-container">
                <div>
                  {/* <input
                        type="checkbox"
                        name="memberships"
                        id={`membership-${selectedMembershipLevel?.id}`}
                        checked={selectedMembershipLevel?.id === selectedMembershipLevel?.id}
                        onChange={(e) => {
                          //   handleStudent(e, item);
                          let checked = e.target.checked;
                          if (checked) {
                            setSelectedMembershipLevel(item);
                          } else if (!checked) {
                            setSelectedMembershipLevel(null);
                          }
                        }}
                      /> */}
                  <label
                    htmlFor={`membership-${selectedMembershipLevel?.id}`}
                    className="w-100 bg-transparent shadow-none"
                  >
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-5 text-center">
                      <div className="membership-step-box h-auto mb-3">
                        <div className="text-center">
                          <img
                            src={
                              selectedMembershipLevel?.image?.includes("http")
                                ? selectedMembershipLevel?.image
                                : "assets/img/membership-icon.png"
                            }
                            className="membership-icon"
                            alt=""
                          />
                        </div>
                        <h5 className="text-capitalize text-center">
                          {selectedMembershipLevel?.name}
                        </h5>
                        <h2 className="text-center fs-5">
                          <span className="theme-color fw-bold">
                            <i className="fa fa-gbp" aria-hidden="true"></i>{" "}
                            {student_type === "child"
                              ? selectedMembershipLevel?.child_price
                              : student_type === "adult"
                              ? selectedMembershipLevel?.adult_price
                              : "NA"}
                          </span>{" "}
                          <small className="text-uppercase theme-color fs-6">
                            per annum
                          </small>
                        </h2>
                        <ul className="mem-li">
                          <ReadMore>{selectedMembershipLevel?.about} </ReadMore>
                        </ul>
                      </div>
                      <span
                        className="text-capitalize text-center theme-color fw-bold  cursor-pointer"
                        onClick={() => {
                          setShowTNC(!showTNC);
                        }}
                      >
                        terms & Conditions apply
                      </span>

                      {showTNC && (
                        <div className="bg-white p-3 mt-3">
                          <p>{selectedMembershipLevel?.terms_and_condition}</p>
                        </div>
                      )}
                      {
                        // <p className="mt-5">
                        //   By clicking the button below, you agree with the{" "}
                        //   <span
                        //     className="theme-color"
                        //     onClick={() => {
                        //       navigate(
                        //         "/terms-and-condition/purchase membership"
                        //       );
                        //     }}
                        //   >
                        //     terms and conditions
                        //   </span>{" "}
                        //   for purchasing membership.
                        // </p>
                        <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4 mt-4 text-center">
                          <div className="input-container term-checkbox">
                            <input
                              type="checkbox"
                              id="term-checkbox"
                              name="tnc"
                              checked={tnc ? tnc : false}
                              // {...register("tnc")}
                              onChange={(e) => {
                                console.log("Clicked");
                                setTnc(e.target.checked);
                                if (e.target.checked) {
                                  setError("");
                                }
                              }}
                            />
                            <label htmlFor="term-checkbox"></label>
                            <span>
                              I accept the{" "}
                              <span
                                className="theme-color fw-bold"
                                onClick={() => {
                                  navigate(
                                    "/terms-and-condition/purchase membership"
                                  );
                                }}
                              >
                                TERMS & CONDITION
                              </span>{" "}
                              applied for purchasing membership. .
                            </span>
                            {error && <div className="text-red">{error}</div>}
                          </div>
                        </div>
                      }

                      {
                        <button
                          type="button"
                          onClick={handleAddMembership}
                          className="btn btn-submit fs-6 mb-1 w-100"
                          disabled={submitting}
                        >
                          {submitting ? <Loader /> : "Buy"}
                        </button>
                      }
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default BuyMembershipModal;
