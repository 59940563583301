import React, { useState } from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input/input";
function Phone({
  onChange,
  value,
  className,
  setValue,
  name,
  validateMobile,
  isValid,
}) {
  // const [error, setError] = useState("");
  // const isValid = (value) => {
  //   if (value && !isValidPhoneNumber(value)) {
  //     setError("Invalid Number");
  //     return false;
  //   } else {
  //     setError("");
  //   }
  //   return true;
  // };
  return (
    <>
      <PhoneInput
        name={name}
        defaultCountry="GB"
        country="GB"
        className={className}
        placeholder={`Start with ‘0’ and no spaces please`}
        onBlur={(e) => {
          console.log(value);

          isValid(value.replace(" ", ""));
        }}
        useNationalFormatForDefaultCountryValue
        // countryCallingCodeEditable
        input={"phoneInputClass"}
        //   withCountryCallingCode={true}
        // countryCallingCodeEditable={false}
        limitMaxLength
        value={value || undefined}
        onChange={(phone) => {
          setValue("mobile", phone);
        }}
      />
      {/* {error && <label className="text-danger">{error}</label>} */}
    </>
  );
}

export default Phone;
