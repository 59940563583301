import React from "react";
import moment from "moment";

function MembershipCard({ data }) {
  console.log(data, "Membership data");
  return (
    <div className="bg-white p-3 rounded h-100 shadow cursor-pointer">
      <div className="row">
        <div className="col-sm-6 col-md-12 col-lg-12 col-xl-7 mb-2 text-capitalize">
          <h5>{data?.membership_details?.name}</h5>
          <p>
            <label className="fw-bold theme-color">Student: </label>{" "}
            <span>
              {data?.student_details
                ? `${data?.student_details?.first_name} ${data?.student_details?.last_name}`
                : "NA"}
            </span>
          </p>
          <p>
            <label className="fw-bold theme-color">Valid Till: </label>{" "}
            <span>
              {data?.valid_till && data?.valid_till !== "0000-00-00"
                ? moment(data?.valid_till)?.format("DD MMM YYYY")
                : "NA"}
            </span>
          </p>
        </div>
        <div className="col-sm-6 col-md-12 col-lg-12 col-xl-5 mb-2 position-relative text-sm-end">
          <p className="text-capitalize text-muted">
            On: {moment(data?.createdAt).format("DD MMM YYYY")}
          </p>
          <div className="payment-type">
            <p className="text-capitalize theme-color fw-bold mb-0 fs-4">
              <i class="fa fa-gbp" aria-hidden="true"></i> {data?.total_amount}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MembershipCard;
