import React from "react";

function SelectStudent() {
  return (
    <section class="sec-bg py-4 px-3">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12 col-md-6 col-lg-6 mb-3">
            <h3 class="mb-4">Select Date</h3>
            <div class="calendar">
              <div class="month mb-3">
                <ul>
                  <li class="prev">&#10094;</li>
                  <li>jan 2022</li>
                  <li class="next">&#10095;</li>
                </ul>
              </div>

              <ul class="weekdays">
                <li>Mon</li>
                <li>Tue</li>
                <li>Wed</li>
                <li>Thu</li>
                <li>Fri</li>
                <li>Sat</li>
                <li>Sun</li>
              </ul>

              <ul class="days">
                <li>1</li>
                <li>2</li>
                <li>3</li>
                <li>4</li>
                <li>5</li>
                <li>6</li>
                <li>7</li>
                <li>8</li>
                <li>9</li>
                <li>
                  <span class="active">10</span>
                </li>
                <li>11</li>
                <li>12</li>
                <li>13</li>
                <li>14</li>
                <li>15</li>
                <li>16</li>
                <li>17</li>
                <li>18</li>
                <li>19</li>
                <li>20</li>
                <li>21</li>
                <li>22</li>
                <li>23</li>
                <li>24</li>
                <li>25</li>
                <li>26</li>
                <li>27</li>
                <li>28</li>
                <li>29</li>
                <li>30</li>
                <li>31</li>
              </ul>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-6 mb-3">
            <h3 class="mb-4">Select Time</h3>
            <h4 class="theme-color text-center mb-3">Availability</h4>
            <div class="bg-white p-3">
              <div class="checkboxs Availability">
                <div class="row">
                  <div class="col-6 col-sm-4 col-md-6 col-lg-4 col-xl-3 mb-2">
                    <label>
                      <input
                        type="radio"
                        value="8:00 am"
                        name="Availability"
                        checked
                      />
                      <div class="div-bg">
                        <p>
                          <span>8:00 am</span>
                        </p>
                      </div>
                    </label>
                  </div>
                  <div class="col-6 col-sm-4 col-md-6 col-lg-4 col-xl-3 mb-2">
                    <label>
                      <input type="radio" value="9:00 am" name="Availability" />
                      <div class="div-bg">
                        <p>
                          <span>9:00 am</span>
                        </p>
                      </div>
                    </label>
                  </div>
                  <div class="col-6 col-sm-4 col-md-6 col-lg-4 col-xl-3 mb-2">
                    <label>
                      <input
                        type="radio"
                        value="10:00 am"
                        name="Availability"
                      />
                      <div class="div-bg">
                        <p>
                          <span>10:00 am</span>
                        </p>
                      </div>
                    </label>
                  </div>
                  <div class="col-6 col-sm-4 col-md-6 col-lg-4 col-xl-3 mb-2">
                    <label>
                      <input
                        type="radio"
                        value="11:00 am"
                        name="Availability"
                      />
                      <div class="div-bg">
                        <p>
                          <span>11:00 am</span>
                        </p>
                      </div>
                    </label>
                  </div>
                  <div class="col-6 col-sm-4 col-md-6 col-lg-4 col-xl-3 mb-2">
                    <label>
                      <input type="radio" value="8:00 am" name="Availability" />
                      <div class="div-bg">
                        <p>
                          <span>8:00 am</span>
                        </p>
                      </div>
                    </label>
                  </div>
                  <div class="col-6 col-sm-4 col-md-6 col-lg-4 col-xl-3 mb-2">
                    <label>
                      <input type="radio" value="9:00 am" name="Availability" />
                      <div class="div-bg">
                        <p>
                          <span>9:00 am</span>
                        </p>
                      </div>
                    </label>
                  </div>
                  <div class="col-6 col-sm-4 col-md-6 col-lg-4 col-xl-3 mb-2">
                    <label>
                      <input
                        type="radio"
                        value="10:00 am"
                        name="Availability"
                      />
                      <div class="div-bg">
                        <p>
                          <span>10:00 am</span>
                        </p>
                      </div>
                    </label>
                  </div>
                  <div class="col-6 col-sm-4 col-md-6 col-lg-4 col-xl-3 mb-2">
                    <label>
                      <input
                        type="radio"
                        value="11:00 am"
                        name="Availability"
                      />
                      <div class="div-bg">
                        <p>
                          <span>11:00 am</span>
                        </p>
                      </div>
                    </label>
                  </div>
                  <div class="col-6 col-sm-4 col-md-6 col-lg-4 col-xl-3 mb-2">
                    <label>
                      <input type="radio" value="8:00 am" name="Availability" />
                      <div class="div-bg">
                        <p>
                          <span>8:00 am</span>
                        </p>
                      </div>
                    </label>
                  </div>
                  <div class="col-6 col-sm-4 col-md-6 col-lg-4 col-xl-3 mb-2">
                    <label>
                      <input type="radio" value="9:00 am" name="Availability" />
                      <div class="div-bg">
                        <p>
                          <span>9:00 am</span>
                        </p>
                      </div>
                    </label>
                  </div>
                  <div class="col-6 col-sm-4 col-md-6 col-lg-4 col-xl-3 mb-2">
                    <label>
                      <input
                        type="radio"
                        value="10:00 am"
                        name="Availability"
                      />
                      <div class="div-bg">
                        <p>
                          <span>10:00 am</span>
                        </p>
                      </div>
                    </label>
                  </div>
                  <div class="col-6 col-sm-4 col-md-6 col-lg-4 col-xl-3 mb-2">
                    <label>
                      <input
                        type="radio"
                        value="11:00 am"
                        name="Availability"
                      />
                      <div class="div-bg">
                        <p>
                          <span>11:00 am</span>
                        </p>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row align-items-center">
          <div class="col-sm-12 col-md-12 col-lg-12 mt-3">
            <hr />
          </div>
        </div>
        <div class="row align-items-center">
          <div class="col-sm-12 col-md-6 col-lg-6 mb-3">
            <h3 class="">Select Students</h3>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div class="input-container form-style m-0 ms-auto mw-350">
              <select class="form-control ps-4 form-select">
                <option>School Venue</option>
              </select>
              <img src="assets/img/address.png" class="input-img" alt="" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12 mt-3">
            <div class="owl-slider student-slider student-container">
              <div class="">
                <input
                  type="radio"
                  name="students"
                  id="student1"
                  checked="checked"
                />
                <label for="student1" class="student__details">
                  <p>
                    <img
                      src="assets/img/photo.png"
                      class="student__img"
                      alt=""
                    />
                  </p>
                  <p class="d-grid">
                    <span>student name</span>
                    <span>
                      <b>thomas kors</b>
                    </span>
                  </p>
                  <div class="d-flex justify-content-between">
                    <span>
                      <p class="d-grid">
                        <span>date of birth</span>
                        <span>
                          <b>12 aug 1996</b>
                        </span>
                      </p>
                    </span>
                    <span>
                      <p class="d-grid">
                        <span>discipline</span>
                        <span>
                          <b>karate</b>
                        </span>
                      </p>
                    </span>
                  </div>
                  <p class="d-grid">
                    <span>school venue</span>
                    <span>
                      <b>thomas kors</b>
                    </span>
                  </p>
                </label>
              </div>
              <div class="">
                <input type="radio" name="students" id="student2" />
                <label for="student2" class="student__details">
                  <p>
                    <img
                      src="assets/img/photo.png"
                      class="student__img"
                      alt=""
                    />
                  </p>
                  <p class="d-grid">
                    <span>student name</span>
                    <span>
                      <b>thomas kors</b>
                    </span>
                  </p>
                  <div class="d-flex justify-content-between">
                    <span>
                      <p class="d-grid">
                        <span>date of birth</span>
                        <span>
                          <b>12 aug 1996</b>
                        </span>
                      </p>
                    </span>
                    <span>
                      <p class="d-grid">
                        <span>discipline</span>
                        <span>
                          <b>karate</b>
                        </span>
                      </p>
                    </span>
                  </div>
                  <p class="d-grid">
                    <span>school venue</span>
                    <span>
                      <b>thomas kors</b>
                    </span>
                  </p>
                </label>
              </div>
              <div class="">
                <input type="radio" name="students" id="student3" />
                <label for="student3" class="student__details">
                  <p>
                    <img
                      src="assets/img/photo.png"
                      class="student__img"
                      alt=""
                    />
                  </p>
                  <p class="d-grid">
                    <span>student name</span>
                    <span>
                      <b>thomas kors</b>
                    </span>
                  </p>
                  <div class="d-flex justify-content-between">
                    <span>
                      <p class="d-grid">
                        <span>date of birth</span>
                        <span>
                          <b>12 aug 1996</b>
                        </span>
                      </p>
                    </span>
                    <span>
                      <p class="d-grid">
                        <span>discipline</span>
                        <span>
                          <b>karate</b>
                        </span>
                      </p>
                    </span>
                  </div>
                  <p class="d-grid">
                    <span>school venue</span>
                    <span>
                      <b>thomas kors</b>
                    </span>
                  </p>
                </label>
              </div>
              <div class="">
                <input type="radio" name="students" id="student4" />
                <label for="student4" class="student__details">
                  <p>
                    <img
                      src="assets/img/photo.png"
                      class="student__img"
                      alt=""
                    />
                  </p>
                  <p class="d-grid">
                    <span>student name</span>
                    <span>
                      <b>thomas kors</b>
                    </span>
                  </p>
                  <div class="d-flex justify-content-between">
                    <span>
                      <p class="d-grid">
                        <span>date of birth</span>
                        <span>
                          <b>12 aug 1996</b>
                        </span>
                      </p>
                    </span>
                    <span>
                      <p class="d-grid">
                        <span>discipline</span>
                        <span>
                          <b>karate</b>
                        </span>
                      </p>
                    </span>
                  </div>
                  <p class="d-grid">
                    <span>school venue</span>
                    <span>
                      <b>thomas kors</b>
                    </span>
                  </p>
                </label>
              </div>
              <div class="">
                <input type="radio" name="students" id="student5" />
                <label for="student5" class="student__details">
                  <p>
                    <img
                      src="assets/img/photo.png"
                      class="student__img"
                      alt=""
                    />
                  </p>
                  <p class="d-grid">
                    <span>student name</span>
                    <span>
                      <b>thomas kors</b>
                    </span>
                  </p>
                  <div class="d-flex justify-content-between">
                    <span>
                      <p class="d-grid">
                        <span>date of birth</span>
                        <span>
                          <b>12 aug 1996</b>
                        </span>
                      </p>
                    </span>
                    <span>
                      <p class="d-grid">
                        <span>discipline</span>
                        <span>
                          <b>karate</b>
                        </span>
                      </p>
                    </span>
                  </div>
                  <p class="d-grid">
                    <span>school venue</span>
                    <span>
                      <b>thomas kors</b>
                    </span>
                  </p>
                </label>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12 mt-3">
            <div class="bg-white p-4 rounded h-100 shadow">
              <div class="row align-items-center">
                <div class="col-sm-12 col-md-6 col-lg-8 mb-2">
                  <div class="event-box-flex">
                    <span>
                      <img src="assets/img/kick.png" class="event-img" alt="" />
                    </span>
                    <span>
                      <h4>
                        <a href="event-detail.html">kick boxing</a>
                      </h4>
                      <p class="mb-0">adult | child | mixed</p>
                      <p class="theme-color1">individual class</p>
                    </span>
                  </div>
                  <p class="ps-sm-5">
                    <img src="assets/img/address.png" class="" alt="" /> 3017,
                    Airplane Avenue, Arimo Idaho- 83214
                  </p>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-4 mb-2 text-center">
                  <h3>
                    <i class="fa fa-gbp" aria-hidden="true"></i> 35
                  </h3>
                  <button class="btn btn-submit fs-5 mb-1 w-auto">
                    <img src="assets/img/cart.png" class="" alt="" /> Added in
                    Cart
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-md-12 col-lg-12 mt-3">
            <div class="bg-white p-4 rounded h-100 shadow">
              <h3>About Event</h3>
              <p class="mt-2">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
                commodo ligula eget dolor. Aenean massa. Cum sociis natoque
                penatibus et magnis dis parturient montes, nascetur ridiculus
                mus. Donec quam felis, ultricies nec, pellentesque eu, pretium
                quis, sem. Nulla consequat massa quis enim. Donec pede justo,
                fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo,
              </p>
            </div>
          </div>

          <div class="col-sm-12 col-md-12 col-lg-12 mt-3">
            <hr />
          </div>

          <div class="col-sm-12 col-md-12 col-lg-12 mt-3">
            <h3>Other Class/Grading</h3>
            <div class="row">
              <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-3">
                <div class="bg-white p-4 rounded h-100 shadow">
                  <div class="event-box-flex">
                    <span>
                      <img src="assets/img/kick.png" class="event-img" alt="" />
                    </span>
                    <span>
                      <h5>kick boxing</h5>
                      <p class="mb-0">adult | child | mixed</p>
                      <p class="theme-color1 mb-0">individual class</p>
                    </span>
                  </div>
                  <div class="joining-flex">
                    <span>
                      <img src="assets/img/photo.png" class="ppl-img" alt="" />
                      <img src="assets/img/photo.png" class="ppl-img" alt="" />
                      <small>20 people are joining</small>
                    </span>
                    <span class="left-seats">15 seats left</span>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-3">
                <div class="bg-white p-4 rounded h-100 shadow">
                  <div class="event-box-flex">
                    <span>
                      <img src="assets/img/kick.png" class="event-img" alt="" />
                    </span>
                    <span>
                      <h5>kick boxing</h5>
                      <p class="mb-0">adult | child | mixed</p>
                      <p class="theme-color1 mb-0">individual class</p>
                    </span>
                  </div>
                  <div class="joining-flex">
                    <span>
                      <img src="assets/img/photo.png" class="ppl-img" alt="" />
                      <img src="assets/img/photo.png" class="ppl-img" alt="" />
                      <small>20 people are joining</small>
                    </span>
                    <span class="left-seats">15 seats left</span>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-3">
                <div class="bg-white p-4 rounded h-100 shadow">
                  <div class="event-box-flex">
                    <span>
                      <img src="assets/img/kick.png" class="event-img" alt="" />
                    </span>
                    <span>
                      <h5>kick boxing</h5>
                      <p class="mb-0">adult | child | mixed</p>
                      <p class="theme-color1 mb-0">individual class</p>
                    </span>
                  </div>
                  <div class="joining-flex">
                    <span>
                      <img src="assets/img/photo.png" class="ppl-img" alt="" />
                      <img src="assets/img/photo.png" class="ppl-img" alt="" />
                      <small>20 people are joining</small>
                    </span>
                    <span class="left-seats">15 seats left</span>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-3">
                <div class="bg-white p-4 rounded h-100 shadow">
                  <div class="event-box-flex">
                    <span>
                      <img src="assets/img/kick.png" class="event-img" alt="" />
                    </span>
                    <span>
                      <h5>kick boxing</h5>
                      <p class="mb-0">adult | child | mixed</p>
                      <p class="theme-color1 mb-0">individual class</p>
                    </span>
                  </div>
                  <div class="joining-flex">
                    <span>
                      <img src="assets/img/photo.png" class="ppl-img" alt="" />
                      <img src="assets/img/photo.png" class="ppl-img" alt="" />
                      <small>20 people are joining</small>
                    </span>
                    <span class="left-seats">15 seats left</span>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-3">
                <div class="bg-white p-4 rounded h-100 shadow">
                  <div class="event-box-flex">
                    <span>
                      <img src="assets/img/kick.png" class="event-img" alt="" />
                    </span>
                    <span>
                      <h5>kick boxing</h5>
                      <p class="mb-0">adult | child | mixed</p>
                      <p class="theme-color1 mb-0">individual class</p>
                    </span>
                  </div>
                  <div class="joining-flex">
                    <span>
                      <img src="assets/img/photo.png" class="ppl-img" alt="" />
                      <img src="assets/img/photo.png" class="ppl-img" alt="" />
                      <small>20 people are joining</small>
                    </span>
                    <span class="left-seats">15 seats left</span>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-3">
                <div class="bg-white p-4 rounded h-100 shadow">
                  <div class="event-box-flex">
                    <span>
                      <img src="assets/img/kick.png" class="event-img" alt="" />
                    </span>
                    <span>
                      <h5>kick boxing</h5>
                      <p class="mb-0">adult | child | mixed</p>
                      <p class="theme-color1 mb-0">individual class</p>
                    </span>
                  </div>
                  <div class="joining-flex">
                    <span>
                      <img src="assets/img/photo.png" class="ppl-img" alt="" />
                      <img src="assets/img/photo.png" class="ppl-img" alt="" />
                      <small>20 people are joining</small>
                    </span>
                    <span class="left-seats">15 seats left</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SelectStudent;
