import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { getDataforUrl } from "./../../api/index";
import { GetBookingDetails } from "./../../api/booking";
import moment from "moment";
import ReadMore from "../../component/ReadMore/ReadMore";
import Loader from "../../component/Loader";
import { createPaymentLinkForPendingBooking } from "../../api/payment";
import { toast } from "react-toastify";
import { FetchUserDetail, userDetailSelector } from "../slice";

function BookingDetail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const { id } = useParams();
  const [useWalletAmount, setUseWalletAmount] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const userData = useSelector(userDetailSelector);
  console.log(userData, "user data");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(FetchUserDetail({}));
  }, [dispatch]);

  useEffect(() => {
    setLoading(true);
    GetBookingDetails(id).then((res) => {
      setLoading(false);
      if (res?.code === 1) {
        setData(res?.data);
      }
    });
  }, [id]);

  // const handlePayment = () => {
  //   let payload;
  //   let user_current_wallet_amount = userData?.wallet
  //     ? Number(userData?.wallet)
  //     : 0;
  //   let previous_transaction_details =
  //     data?.boking_details?.booking_payment_details;

  //   let previous_wallet_used_amount =
  //     previous_transaction_details?.wallet_used_amount
  //       ? Number(previous_transaction_details?.wallet_used_amount)
  //       : 0;
  //   let total_amount = previous_transaction_details?.total_amount
  //     ? Number(previous_transaction_details?.total_amount)
  //     : 0;

  //   if (useWalletAmount) {
  //     let wallet_amount =
  //       previous_wallet_used_amount === total_amount
  //         ? 0
  //         : user_current_wallet_amount > 0 &&
  //           user_current_wallet_amount >
  //             total_amount - previous_wallet_used_amount &&
  //           previous_wallet_used_amount < total_amount
  //         ? total_amount - previous_wallet_used_amount
  //         : 0;
  //     payload = {
  //       wallet_amount,
  //       transaction_amount: total_amount - wallet_amount,
  //     };
  //     console.log(payload, "Payload of handle payment");
  //   } else {
  //   }
  //   // createPaymentLinkForPendingBooking().then((res) => {
  //   //   if (res?.code === 1) {
  //   //     toast.success(res?.message);
  //   //   } else {
  //   //     toast.error(res?.message);
  //   //   }
  //   // });
  // };
  if (loading) return <Loader />;
  return (
    <section className="sec-bg">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 text-center theme-color">
            <label className="fw-bold text-uppercase fs-3">
              Booking Detail
            </label>
          </div>
          <div className="col-sm-12 ps-3">
            <label className="fw-bold ps-1">Payment Status: </label>
            <span className="ps-2">
              {data?.booking_details?.booking_payment_details?.payment_status}
            </span>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-12 mt-3">
            <div className=" p-2 text-capitalize mt-3">
              {data?.booking_details?.booking_classes?.length > 0
                ? data?.booking_details?.booking_classes?.map((classes, i) => {
                    return (
                      <>
                        <label className="fw-bold mb-2">
                          <span>Classes Booked</span>
                        </label>

                        <div className="bg-white shadow mb-2 p-3">
                          <p className="mb-1">
                            <label className="fw-bold mb-1">Class Name:</label>{" "}
                            {classes.class_details?.name}
                          </p>
                          <p className="mb-1">
                            <label className="fw-bold mb-1">Venue:</label>{" "}
                            {classes.school_details?.name},{" "}
                            {classes.school_details?.address}
                          </p>
                          <div className="table-responsive">
                            <table className="table storetable">
                              <thead className=" text-center ">
                                <tr>
                                  <th>Date</th>
                                  <th>Time</th>
                                  <th>Students</th>
                                </tr>
                              </thead>

                              <tbody>
                                {classes.time_slot_list
                                  //   ?.sort(function compare(a, b) {
                                  //     console.log(a, b);
                                  //     var dateA = new Date(a?.date);
                                  //     var dateB = new Date(b?.date);
                                  //     return dateA - dateB;
                                  //   })
                                  ?.map((time_slot, index) => {
                                    return (
                                      <tr key={index} className="text-center">
                                        <td>
                                          {" "}
                                          {moment(time_slot?.date).format(
                                            "DD MMM YYYY"
                                          )}
                                        </td>
                                        <td>
                                          {" "}
                                          {moment(
                                            time_slot?.start_time,
                                            "hh:mm:ss"
                                          ).format("hh:mm a")}
                                          -{" "}
                                          {moment(
                                            time_slot?.end_time,
                                            "hh:mm:ss"
                                          ).format("hh:mm a")}
                                        </td>
                                        <td>
                                          {time_slot?.student_list
                                            ?.map(
                                              (student) =>
                                                `${student?.student_details?.first_name} ${student?.student_details?.last_name}`
                                            )
                                            ?.join(", ")}
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </>
                    );
                  })
                : null}

              {data?.booking_details?.booking_events?.length > 0 && (
                <div className="col-sm-12 col-md-12 col-lg-12 mt-3">
                  <div className=" p-2 text-capitalize mt-3">
                    <label className="fw-bold mb-2">
                      <h4>Events Booked</h4>
                    </label>
                    {data?.booking_details?.booking_events?.length > 0
                      ? data?.booking_details?.booking_events?.map(
                          (events, i) => {
                            return (
                              <div className="">
                                <div className=" bg-white shadow mb-2 p-3">
                                  <p className="mb-0">
                                    <label className="fw-bold mb-1">
                                      Event Name:
                                    </label>{" "}
                                    {events.event_details?.name}
                                  </p>

                                  <p className="mb-0">
                                    <label className="fw-bold mb-1">
                                      Venue:
                                    </label>{" "}
                                    {events.school_details?.name},{" "}
                                    {events.school_details?.address}
                                  </p>
                                  <p className="mb-0">
                                    <label className="fw-bold mb-1">
                                      Event Date:
                                    </label>{" "}
                                    {moment(events.event_details?.date).format(
                                      "DD MMM YYYY"
                                    )}
                                  </p>
                                  {events.event_details?.start_time && (
                                    <p className="mb-0">
                                      <label className="fw-bold mb-1">
                                        Event Time:
                                      </label>{" "}
                                      {events.event_details?.start_time
                                        ? moment(
                                            events.event_details?.start_time,
                                            "hh:mm:ss"
                                          ).format("hh:mm a")
                                        : "NA"}
                                    </p>
                                  )}

                                  {events.about && (
                                    <p className="mb-0">
                                      <label className="fw-bold mb-1">
                                        About:
                                      </label>{" "}
                                      {events.about ? (
                                        <ReadMore>{events.about}</ReadMore>
                                      ) : (
                                        "NA"
                                      )}
                                    </p>
                                  )}
                                  {events.levels?.length > 0 && (
                                    <div className="table-responsive">
                                      <table className="table storetable">
                                        <thead className=" text-center ">
                                          <tr>
                                            <th>Level Name</th>
                                            <th>Level Time</th>
                                            <th>Students</th>
                                            <th>Level Note</th>
                                          </tr>
                                        </thead>

                                        <tbody>
                                          {events.levels?.map(
                                            (level, index) => {
                                              return (
                                                <tr
                                                  key={index}
                                                  className="text-center"
                                                >
                                                  <td>
                                                    {level?.level_details?.name}
                                                  </td>

                                                  <td>
                                                    {" "}
                                                    {moment(
                                                      level?.level_details
                                                        ?.time,
                                                      "hh:mm:ss"
                                                    ).format("hh:mm a")}
                                                  </td>
                                                  <td>
                                                    {level?.student_list
                                                      ?.map(
                                                        (student) =>
                                                          `${student?.student_details?.first_name} ${student?.student_details?.last_name}`
                                                      )
                                                      ?.join(", ")}
                                                  </td>
                                                  <td>
                                                    {level?.level_details?.note}
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                  )}
                                </div>
                              </div>
                            );
                          }
                        )
                      : null}
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* {data?.booking_details?.payment_status === "0" && (
            <>
              {data?.booking_details?.booking_payment_details?.total_amount >
                data?.booking_details?.booking_payment_details
                  ?.wallet_amount && (
                <div className="col-sm-12 col-md-12 col-lg-12 mt-2">
                  <div className="form-group  mb-4 ps-2">
                    <div className="input-container term-checkbox ">
                      <input
                        type="checkbox"
                        id="termCheckbox2"
                        name="useWalletAmount"
                        checked={useWalletAmount}
                        onChange={(e) => {
                          setUseWalletAmount(!useWalletAmount);
                        }}
                      />
                      <label htmlFor="termCheckbox2"></label>
                      <span>Use wallet amount</span>
                    </div>
                  </div>
                </div>
              )}
              <div className="col-sm-12 col-md-12 col-lg-12 my-3 text-center">
                <button
                  className="btn-accept px-3 py-2 border-0 "
                  disabled={submitting === true}
                  type="button"
                  onClick={() => {
                    handlePayment();
                  }}
                >
                  {submitting ? <Loader /> : "Pay"}
                </button>
              </div>
            </>
          )} */}
        </div>
      </div>
    </section>
  );
}

export default BookingDetail;
