import { configureStore } from "@reduxjs/toolkit";
import EventSlice from "../features/event/EventSlice";
import MembershipSlice from "../features/membership/MembershipSlice";
import NotificationSlice from "../features/notification/NotificationSlice";
import PaymentSlice from "../features/payment/PaymentSlice";
import RecordSlice from "../features/records/RecordsSlice";
import MainSlice from "../features/slice";
import StudentSlice from "../features/student/StudentSlice";

export const store = configureStore({
  reducer: {
    record: RecordSlice,
    student: StudentSlice,
    payment: PaymentSlice,
    notification: NotificationSlice,
    membership: MembershipSlice,
    event: EventSlice,
    main: MainSlice,
  },
});
