import React from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";

function Membership({ show, handleShow }) {
  const dispatch = useDispatch();

  const Comp = () => {
    return (
      <div className="modal-dialog modal-dialog-centered modal-xl">
        <div className="modal-content other-city b-r-20">
          <div className="modal-header d-grid text-center">
            <h2 className="mdl-ttl">membership</h2>
            <p className="mdl-sbttl">
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
            </p>
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                handleShow();
              }}
            ></button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-6 col-xl-4 mt-5">
                <div className="membership-step-box h-auto">
                  <div className="text-center">
                    <img
                      src="assets/img/membership-icon.png"
                      className="membership-icon"
                      alt=""
                    />
                  </div>
                  <h5 className="text-capitalize text-center">
                    trial membership
                  </h5>
                  <h2 className="text-center fs-5">
                    <span className="theme-color fw-bold">
                      <i className="fa fa-gbp" aria-hidden="true"></i> 24
                    </span>{" "}
                    <small className="text-uppercase theme-color fs-6">
                      per annual
                    </small>
                  </h2>
                  <ul className="mem-li">
                    <li>
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                      Aenean commodo ligula eget dolor.
                    </li>
                    <li>
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                      Aenean commodo ligula eget dolor.
                    </li>
                    <li>
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                      Aenean commodo ligula eget dolor.
                    </li>
                    <li>
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                      Aenean commodo ligula eget dolor.
                    </li>
                  </ul>
                  <p className="text-capitalize text-center theme-color fw-bold">
                    terms & Condition apply
                  </p>
                </div>
                <button className="btn btn-submit fs-6 mb-1 w-100">Buy</button>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-6 col-xl-4 mt-5">
                <div className="membership-step-box h-auto">
                  <div className="text-center">
                    <img
                      src="assets/img/membership-icon.png"
                      className="membership-icon"
                      alt=""
                    />
                  </div>
                  <h5 className="text-capitalize text-center">
                    new membership
                  </h5>
                  <h2 className="text-center fs-5">
                    <span className="theme-color fw-bold">
                      <i className="fa fa-gbp" aria-hidden="true"></i> 25 child
                      | <i className="fa fa-gbp" aria-hidden="true"></i> 35
                      adult
                    </span>
                  </h2>
                  <p className="text-center">
                    <small className="text-uppercase theme-color fs-6">
                      per annual
                    </small>
                  </p>
                  <ul className="mem-li">
                    <li>
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                      Aenean commodo ligula eget dolor.
                    </li>
                    <li>
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                      Aenean commodo ligula eget dolor.
                    </li>
                    <li>
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                    </li>
                    <li>
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                    </li>
                  </ul>
                  <p className="text-capitalize text-center theme-color fw-bold">
                    terms & Condition apply
                  </p>
                </div>
                <button className="btn btn-submit fs-6 mb-1 w-100">Buy</button>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-6 col-xl-4 mt-5">
                <div className="membership-step-box h-auto">
                  <div className="text-center">
                    <img
                      src="assets/img/membership-icon.png"
                      className="membership-icon"
                      alt=""
                    />
                  </div>
                  <h5 className="text-capitalize text-center">
                    renew membership
                  </h5>
                  <h2 className="text-center fs-5">
                    <span className="theme-color fw-bold">
                      <i className="fa fa-gbp" aria-hidden="true"></i> 24
                    </span>{" "}
                    <small className="text-uppercase theme-color fs-6">
                      per annual
                    </small>
                  </h2>
                  <ul className="mem-li">
                    <li>
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                      Aenean commodo ligula eget dolor.
                    </li>
                    <li>
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                      Aenean commodo ligula eget dolor.
                    </li>
                    <li>
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                      Aenean commodo ligula eget dolor.
                    </li>
                    <li>
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                      Aenean commodo ligula eget dolor.
                    </li>
                  </ul>
                  <p className="text-capitalize text-center theme-color fw-bold">
                    terms & Condition apply
                  </p>
                </div>
                <button className="btn btn-submit fs-6 mb-1 w-100">Buy</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return <Modal show={show} onHide={handleShow} dialogAs={Comp} />;
}

export default Membership;
