import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { paymentDetails, paymentList } from "./../../api/payment";

const initialState = {
  list: [],
  detail: {},
  total: 0,
  booking_for_is_wallet: localStorage.getItem("booking_for_is_wallet")
    ? localStorage.getItem("booking_for_is_wallet")
    : false,
  detailOfPaymentDetail: localStorage.getItem("detailOfPaymentDetail")
    ? localStorage.getItem("detailOfPaymentDetail")
    : null,
};

export const PaymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    paymentListing: (state, action) => {
      state.list = action.payload;
    },
    details: (state, action) => {
      state.detail = action.payload;
    },
    count: (state, action) => {
      state.total = action.payload;
    },
    changeBookingFor: (state, action) => {
      state.booking_for_is_wallet = action.payload;
    },
    changeDetailOfPaymentDetail: (state, action) => {
      state.detailOfPaymentDetail = action.payload;
    },
  },
});

export const FetchPaymentList = createAsyncThunk(
  "FetchPaymentList",
  async (obj, { dispatch }) => {
    try {
      const response = await paymentList(obj);
      console.log(response, "Payment List");
      if (response?.code === 1) {
        dispatch(paymentListing(response?.data?.payment_list));
        dispatch(count(response?.data?.total_payment_count));
      }
      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const FetchPaymentDetail = createAsyncThunk(
  "FetchPaymentDetail",
  async (obj, { dispatch }) => {
    try {
      const response = await paymentDetails(obj);
      console.log(response, "Payment Details");
      if (response?.code === 1) {
        dispatch(details(response?.data?.booking_list));
      }

      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const {
  paymentListing,
  details,
  count,
  changeBookingFor,
  changeDetailOfPaymentDetail,
} = PaymentSlice.actions;

export default PaymentSlice.reducer;
