import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import OtpInput from "react-otp-input";
import { Formik } from "formik";
import * as Yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { otpSchema } from "../../utils/schema";
import { format } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import {
  FetchUserDetail,
  ResendOtpCall,
  userDetailSelector,
  verifyOtpCall,
} from "../slice";
import { toast } from "react-toastify";
import Loader from "../../component/Loader";
import BackButton from "../../component/BackButton";

function OtpVerification() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [counter, setCounter] = React.useState(120);
  const [loading, setLoading] = useState(false);
  // const user = JSON.parse(localStorage.getItem("forgetData"));
  const email = localStorage.getItem("email");
  const user = useSelector(userDetailSelector);
  const id = useSelector((store) => store.main.forgetData);
  useEffect(() => {
    dispatch(FetchUserDetail({}));
  }, [dispatch]);

  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    control,
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onSubmit",
    resolver: yupResolver(otpSchema),
    shouldFocusError: true,
  });

  const onSubmit = (data) => {
    setLoading(true);
    dispatch(
      verifyOtpCall({
        userId: id,
        code: data?.otpValues,
        user_type: "user",
      })
    ).then(async (res) => {
      setLoading(false);
      if (res?.payload?.code === 1) {
        await localStorage.setItem("token_u", res?.payload?.data?.token);
        navigate("/reset-password", { replace: true });
        toast.success(res?.payload?.message);
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  React.useEffect(() => {
    let timer;
    if (counter > 0) {
      timer = setTimeout(() => setCounter((c) => c - 1), 1000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [counter]);

  const handleResend = () => {
    setLoading(true);
    dispatch(ResendOtpCall({ email: email, user_type: "user" })).then(
      async (res) => {
        setLoading(false);
        setCounter(10);
        if (res?.payload?.code === 1) {
          toast.success(res?.payload?.message);
        } else {
          toast.error(res?.payload?.message);
        }
      }
    );
  };
  return (
    <section className="bg-image">
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6">
            <div className="logo-bg position-relative">
              <p className="cursor-pointer">
                <img
                  src="assets/img/headerlogo.png"
                  className="img-fluid cursor-pointer"
                  alt="logo"
                  onClick={() => {
                    navigate("/");
                  }}
                />
              </p>
              <p>
                <img
                  src="assets/img/banner_logo.png"
                  className="logo-img"
                  alt="logo"
                />
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 mt-5">
            <BackButton />
            <div className="form-style mt-5">
              <form className="row" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group col-sm-12 col-md-12 text-center mb-5">
                  <div className="">
                    <img
                      src="assets/img/otp.png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
                <div className="form-group col-sm-12 col-md-12 text-center mb-4">
                  <h2 className="mdl-ttl">OTP Verification</h2>
                  <p>
                    Please enter 6 digit code which has been send on your
                    <br /> registered email address.
                  </p>
                  {error && <div className="alert alert-danger">{error}</div>}
                </div>
                <div className="form-group col-sm-12 col-md-12 mb-4">
                  <div className="passcode-wrapper">
                    {/* <input
                        id="codeBox1"
                        type="number"
                        maxlength="1"
                        placeholder="0"
                      /> */}
                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <OtpInput
                          value={value}
                          onChange={(val) => {
                            if (val) {
                              onChange(val);
                            }
                          }}
                          placeholder="000000"
                          inputStyle="otpInputs"
                          numInputs={6}
                          // separator={<span> </span>}
                          renderSeparator={<span> </span>}
                          renderInput={(props) => <input {...props} />}
                        />
                      )}
                      name="otpValues"
                      control={control}
                    />
                  </div>
                  {errors?.otpValues && (
                    <div className="text-red">{errors?.otpValues?.message}</div>
                  )}
                </div>
                {/* <div className="form-group col-sm-12 col-md-12 text-center">
                  <p className="otp-timing">00:35</p>
                </div> */}
                <div className="form-group col-sm-12 col-md-12 text-center">
                  <button className="btn btn-submit" type="submit">
                    {loading ? <Loader /> : "Verify"}
                  </button>
                </div>
                <div className="form-group col-sm-12 col-md-12 text-center mb-4">
                  <div>
                    {counter === 0 ? (
                      <span
                        className="forgot-pass cursor-pointer p-0"
                        onClick={() => {
                          handleResend();
                        }}
                      >
                        Resend Code
                      </span>
                    ) : (
                      <div>Resend code in {format(counter)}</div>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default OtpVerification;
