import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import StudentCardFull from "../../component/student/StudentCardFull";
import { GetStudentList, studentListSelector } from "../slice";

function AllStudent() {
  const dispatch = useDispatch();
  const studentList = useSelector(studentListSelector);
  const [loading, setLoading] = useState(true);
  const getStudentList = useCallback(() => {
    setLoading(true);
    dispatch(GetStudentList({})).then(() => {
      setLoading(false);
    });
  }, [dispatch]);
  useEffect(() => {
    getStudentList();
  }, [getStudentList]);
  return (
    <>
      {/* <section className="mt-4">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
              <div className="btn-flex">
                <a
                  href="make-bookings.html"
                  className="white-btn shadow tanitems"
                >
                  Make Bookings
                </a>
                <Link to="/all-student" className="white-btn shadow tanitems">
                  All Students
                </Link>
                <a href="my-booking.html" className="white-btn shadow tanitems">
                  My Bookings
                </a>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
              <div className="view-btns justify-content-end">
                <span className="btn-accept btn-view w-auto">
                  Balance/Credit:
                  <i className="fa fa-gbp" aria-hidden="true"></i> 24
                </span>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="sec-bg py-4 px-3 bottom-space">
        <div className="container-fluid">
          <div className="row ">
            <div className="col-sm-12 col-md-6 col-lg-6 mt-3">
              <h3>All Students</h3>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 mt-3 text-end">
              <Link to="/add-student" className="h3">
                + Add New Student
              </Link>
            </div>

            {studentList?.map((item, i) => (
              <div className="col-sm-12 col-md-6 col-lg-4 mt-3" key={i}>
                <StudentCardFull data={item} refresh={getStudentList} />
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default AllStudent;
