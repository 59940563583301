import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Membership from "../../component/Modal/Membership";
import EventCard from "./../../component/EventCard";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import WeekStrip from "react-weekstrip-daypicker";

function Records() {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const details = useSelector((state) => state.student.details);
  const [value, setValue] = useState("");

  const handleShow = () => {
    setShow(!show);
  };

  const getDetails = useCallback(() => {}, []);

  useEffect(() => {
    let isRendered = false;

    if (!isRendered) {
      getDetails();
    }
    return () => {
      isRendered = false;
    };
  }, [getDetails]);
  return (
    <section>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3 mb-3">
            <div className="sec-bg py-4 px-3 record-listing h-100">
              <h3 className="sec-title text-center fs-4 mb-3">
                class or grading
              </h3>
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12  mt-3">
                  <EventCard handleShow={handleShow} sectionName="class" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3">
            <div className="sec-bg py-4 px-3 record-listing h-100">
              <h3 className="sec-title text-center fs-4 mb-3">events</h3>

              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12 text-center mb-3 position-relative">
                  {/* <h3 className="theme-color text-capitalize">2-8 jan 2022</h3> */}
                  <div className="calender-date text-uppercase">
                    {/* <span className="active">
                      <p className="mb-0">sun</p>
                      <p className="mb-0">2</p>
                    </span>
                    <span className="">
                      <p className="mb-0">mon</p>
                      <p className="mb-0">3</p>
                    </span>
                    <span className="">
                      <p className="mb-0">tue</p>
                      <p className="mb-0">4</p>
                    </span>
                    <span className="">
                      <p className="mb-0">wed</p>
                      <p className="mb-0">5</p>
                    </span>
                    <span className="">
                      <p className="mb-0">thur</p>
                      <p className="mb-0">6</p>
                    </span>
                    <span className="">
                      <p className="mb-0">fri</p>
                      <p className="mb-0">7</p>
                    </span>
                    <span className="">
                      <p className="mb-0">sat</p>
                      <p className="mb-0">8</p>
                    </span> */}
                    {/* <DatePicker
                      value={value}
                      onChange={setValue}
                      type="calendar"
                      containerClassName="calender-date text-uppercase"
                    /> */}
                    {/* <Calendar
                      className="calender-date text-uppercase"
                      value={value}
                      onChange={setValue}
                    /> */}
                    <WeekStrip />
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6 mt-3">
                  <EventCard sectionName="event" />
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6 mt-3">
                  <EventCard sectionName="event" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3 mb-3">
            <div className="sec-bg py-4 px-3 record-listing h-100">
              <h3 className="sec-title text-center fs-4 mb-3">
                members records
              </h3>
              <Link to="/add-student" className="">
                <button className="btn btn-create-profile shadow fw-bold w-100 text-center fs-5 p-2">
                  + Add New Student
                </button>
              </Link>

              <h6 className="mt-5">Account Holder Details</h6>
              <div className="bg-white p-2 mt-3">
                <p className="detail-account text-capitalize position-relative">
                  <img
                    src="assets/img/First-Name.png"
                    className="icon-title"
                    alt=""
                  />
                  <span>full name</span>
                  <span>
                    <b>thomas kors</b>
                  </span>
                </p>
                <p className="detail-account position-relative">
                  <img
                    src="assets/img/email.png"
                    className="icon-title"
                    alt=""
                  />
                  <span className="text-capitalize">email ID</span>
                  <span>
                    <b>thomas.kors@gmail.com</b>
                  </span>
                </p>
                <p className="detail-account position-relative">
                  <img
                    src="assets/img/First-Name.png"
                    className="icon-title"
                    alt=""
                  />
                  <span className="text-capitalize">username</span>
                  <span>
                    <b>thomas_1345</b>
                  </span>
                </p>
                <p className="detail-account text-capitalize position-relative">
                  <img
                    src="assets/img/date-of-birth.png"
                    className="icon-title"
                    alt=""
                  />
                  <span className="">date of birth</span>
                  <span>
                    <b>13 aug 1985</b>
                  </span>
                </p>
                <p className="detail-account text-capitalize position-relative">
                  <img
                    src="assets/img/address.png"
                    className="icon-title"
                    alt=""
                  />
                  <span className="">full address</span>
                  <span>
                    <b>3017, airplane avenue, arimo idaho- 83214</b>
                  </span>
                </p>
                <p className="detail-account text-capitalize position-relative">
                  <img
                    src="assets/img/mobile.png"
                    className="icon-title"
                    alt=""
                  />
                  <span className="">emergency contact number</span>
                  <span>
                    <b>860-658-6589</b>
                  </span>
                </p>
                <button className="btn btn-submit myButton fs-5 mb-1 w-100">
                  Cancel Account
                </button>
              </div>

              <h6 className="mt-5">Student Details</h6>
              <div className="bg-white p-2 text-capitalize mt-3">
                <div
                  className="accordion Student-Details-colles"
                  id="accordionExample"
                >
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        <p className="student_detail-account position-relative">
                          <img
                            src="assets/img/First-Name.png"
                            className="icon-title"
                            alt=""
                          />
                          <span>student name</span>
                          <span>
                            <b>thomas kors</b>
                          </span>
                        </p>
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className={
                        show
                          ? "accordion-collapse collapse show"
                          : "accordion-collapse collapse "
                      }
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p className="student_detail-account position-relative">
                          <img
                            src="assets/img/address.png"
                            className="icon-title"
                            alt=""
                          />
                          <span className="">full address</span>
                          <span>
                            <b>3017, airplane avenue, arimo idaho- 83214</b>
                          </span>
                        </p>
                        <p className="student_detail-account position-relative">
                          <img
                            src="assets/img/membership-status.png"
                            className="icon-title"
                            alt=""
                          />
                          <span className="">membership status</span>
                          <span>
                            <b>active</b>
                          </span>
                        </p>
                        <p className="student_detail-account position-relative">
                          <img
                            src="assets/img/membership-status.png"
                            className="icon-title"
                            alt=""
                          />
                          <span className="">membership lavel</span>
                          <span>
                            <b>child</b>
                          </span>
                        </p>
                        <p className="student_detail-account position-relative">
                          <img
                            src="assets/img/date-of-birth.png"
                            className="icon-title"
                            alt=""
                          />
                          <span className="">date of birth</span>
                          <span>
                            <b>13 aug 1995</b>
                          </span>
                        </p>
                        <p className="student_detail-account position-relative">
                          <img
                            src="assets/img/discipline.png"
                            className="icon-title"
                            alt=""
                          />
                          <span className="">discipline</span>
                          <span>
                            <b>karate</b>
                          </span>
                        </p>
                        <button className="btn btn-submit myButton fs-5 mb-1 w-100">
                          Cancel Account
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Membership show={show} handleShow={handleShow} />
    </section>
  );
}

export default Records;
