import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  GetDefaultEventList,
  GetEventDetail,
  GetEventList,
  GetOtherEventList,
} from "../../api/Events";

const initialState = {
  status: "loading",
  list: [],
  detail: null,
  otherEventList: [],
  isAddedInCart: false,
  // event: localStorage.getItem("event")
  //   ? JSON.parse(localStorage.getItem("event"))
  //   : null,
};
export const getEventLists = (state) => state.event.list;

export const FetchDefaultEventList = createAsyncThunk(
  "FetchDefaultEventList",
  async (obj, { dispatch }) => {
    await GetDefaultEventList(obj).then((res) => {
      if (res?.code === 1) {
        dispatch(list(res?.data?.event_list));
        dispatch(count(res?.data?.total_event));
        return res;
      }
    });
  }
);

export const getEventDetails = (state) => state.event.detail;
export const isAddedToCartSelector = (state) => state?.event?.isAddedInCart;

export const FetchEventDetail = createAsyncThunk(
  "eventDetail",
  async (obj, { dispatch }) => {
    await GetEventDetail(obj).then((res) => {
      if (res?.code === 1) {
        dispatch(details(res?.data?.event_details));
        dispatch(isAddedToCart(res?.data?.isAddedInCart));

        return res;
      }
    });
  }
);

export const getOtherEventLists = (state) => state.event.otherEventList;

export const FetchOtherEventList = createAsyncThunk(
  "othereventList",
  async (obj, { dispatch }) => {
    await GetOtherEventList(obj).then((res) => {
      if (res?.code === 1) {
        dispatch(otherList(res?.data?.event_list));
        return res;
      }
    });
  }
);

export const EventSlice = createSlice({
  name: "event",
  initialState,
  reducers: {
    loading: (state, action) => {
      state.status = "loading";
    },
    idle: (state, action) => {
      state.status = "idle";
    },
    list: (state, action) => {
      state.list = action.payload;
    },
    details: (state, action) => {
      state.detail = action.payload;
    },
    otherList: (state, action) => {
      state.otherEventList = action?.payload;
    },
    isAddedToCart: (state, action) => {
      state.isAddedInCart = action.payload;
    },
    setEventDetail: (state, action) => {
      state.event = action.payload;
    },
    count: (state, action) => {
      state.total = action.payload;
    },
  },
});

export const {
  loading,
  isAddedToCart,
  idle,
  otherList,
  list,
  details,
  setEventDetail,
  count,
} = EventSlice.actions;

export default EventSlice.reducer;
