import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { membershipList } from "../../api/membership";

const initialState = {
  list: [],
  discipline: localStorage.getItem("discipline")
    ? localStorage.getItem("discipline")
    : "",
  student_type: localStorage.getItem("student_type")
    ? localStorage.getItem("student_type")
    : "",

  student_data: localStorage.getItem("student_data")
    ? JSON.parse(localStorage.getItem("student_data"))
    : "",
};

export const MembershipSlice = createSlice({
  name: "membership",
  initialState,
  reducers: {
    listing: (state, action) => {
      state.list = action.payload;
    },
    changeDiscipline: (state, action) => {
      state.discipline = action.payload;
    },
    changeStudentType: (state, action) => {
      state.student_type = action.payload;
    },
    changeStudentData: (state, action) => {
      state.student_data = action.payload;
    },
  },
});

export const FetchMembershipListing = createAsyncThunk(
  "membershipListing",
  async (obj, { dispatch }) => {
    try {
      const response = await membershipList(obj);
      if (response?.code === 1) {
        dispatch(listing(response?.data?.membership_list));
      }
      return response;
    } catch (error) {
      toast.error(error?.message);
    }
  }
);

export const {
  listing,
  changeDiscipline,
  changeStudentType,
  changeStudentData,
} = MembershipSlice.actions;

export default MembershipSlice.reducer;
