import {
  deleteData,
  getData,
  getDataforUrl,
  postDataNoBody,
  postFormData,
  putFormData,
} from "./index";

export const StudentList = async (payload) => {
  return getData("student/studentList", payload).then((data) => {
    return data;
  });
};

// first_name, last_name, dob yyyy-mm-dd, student_type (child/adult), discipline, address, longitude, latitude, emergency_contactNo, emergency_countryCode, medical_note

export const addStudent = async (payload) => {
  return postFormData("student/addStudent", payload).then((data) => {
    return data;
  });
};

export const userDetail = async (payload) => {
  return getData("user/getUserDetails", payload).then((data) => {
    return data;
  });
};

export const TeacherList = async (payload) => {
  return getData("user/teacherList", payload).then((data) => {
    return data;
  });
};

export const removeStudent = async (payload) => {
  return deleteData("student/removeStudent", payload).then((data) => {
    return data;
  });
};

export const editUserProfile = async (payload) => {
  return putFormData("user/editProfile", payload).then((data) => {
    return data;
  });
};

export const studentDetail = async (payload) => {
  return getDataforUrl("student/studentDetails", payload).then((data) => {
    return data;
  });
};

export const addTrailMemberShip = async (payload) => {
  return postFormData("auth/addTrailMemberShip", payload).then((data) => {
    return data;
  });
};
