import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getData } from "../api";
import {
  AddAddress,
  addConsent,
  addMemberShipLevel,
  addStudent,
  forgotPassword,
  getAllSchoolVenues,
  Login,
  ResendOtp,
  resetPassword,
  Signup,
  VerifyOtp,
} from "../api/authentications";
import {
  AddBookingToCart,
  BookingPayment,
  DeleteFromCart,
  GetBookingDetails,
  GetCart,
  GetClassListWithMontlyTimeSlot,
  GetSchoolList,
} from "../api/booking";
import {
  GetbookingList,
  GetEventBookingDetails,
  GetEventCartList,
} from "../api/Events";
import { disciplineList } from "../api/membership";
import {
  areaCodeList,
  membershipList,
  mySubscribedMembershipList,
} from "../api/school";
import {
  studentDetail,
  StudentList,
  TeacherList,
  userDetail,
} from "../api/student";

const initialState = {
  isLoading: false,
  eventInCartList: null,
  cartList: [],
  totalBooking: 0,
  isAddedInCart: false,
  totalCartAmount: 0,
  totalAMountOfEventsInCart: 0,
  forgetData: null,
  tnc: null,
};

export const loginCall = createAsyncThunk(
  "loginCall",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));
      const response = await Login(obj);
      await localStorage.setItem("user", JSON.stringify(response?.data?.user));
      dispatch(isLoading(false));

      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const consentCall = createAsyncThunk(
  "consentCall",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));
      const response = await addConsent(obj);

      dispatch(isLoading(false));

      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const createAccountCall = createAsyncThunk(
  "createAccountCall",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));
      const response = await Signup(obj);

      dispatch(isLoading(false));

      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const verifyOtpCall = createAsyncThunk(
  "VerifyOtpCall",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));
      const response = await VerifyOtp(obj);

      dispatch(isLoading(false));

      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const addAddressCall = createAsyncThunk(
  "addAddressCall",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));
      const response = await AddAddress(obj);

      dispatch(isLoading(false));

      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const ResendOtpCall = createAsyncThunk(
  "ResendOtpCall",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));
      const response = await ResendOtp(obj);

      dispatch(isLoading(false));

      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const addStudentCall = createAsyncThunk(
  "addStudentCall",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));
      const response = await addStudent(obj);

      dispatch(isLoading(false));

      return response;
    } catch (error) {
      toast.error(error.message);
      return error;
    }
  }
);

export const getSchools = createAsyncThunk(
  "getSchools",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));
      const response = await getAllSchoolVenues(obj);

      dispatch(isLoading(false));

      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const addMemberShip = createAsyncThunk(
  "addMemberShip",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));
      const response = await addMemberShipLevel(obj);
      await localStorage.setItem("user", JSON.stringify(response?.data?.user));
      dispatch(isLoading(false));

      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const resetPasswordCall = createAsyncThunk(
  "resetPasswordCall",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));
      const response = await resetPassword(obj);

      dispatch(isLoading(false));

      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const forgotPasswordCall = createAsyncThunk(
  "forgotPassword",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));
      const response = await forgotPassword(obj);

      dispatch(isLoading(false));

      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const AddBooking = createAsyncThunk(
  "AddBooking",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));
      const response = await AddBookingToCart(obj);
      dispatch(isLoading(false));
      dispatch(GetCartClassList({}));
      dispatch(FetchCartEventList({}));

      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const MonthlyDateClassList = createAsyncThunk(
  "MonthlyDateClassList",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));
      const response = await GetClassListWithMontlyTimeSlot(obj);

      dispatch(isLoading(false));

      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const SchoolList = createAsyncThunk(
  "SchoolList",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));
      const response = await GetSchoolList(obj);

      dispatch(isLoading(false));

      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const GetStudentList = createAsyncThunk(
  "GetStudentList",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));
      const response = await StudentList(obj);

      dispatch(isLoading(false));

      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const GetTeacherList = createAsyncThunk(
  "GetTeacherList",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));
      const response = await TeacherList(obj);
      if (response.code === 1) {
        dispatch(teacherList(response.data.teacher_list));
      }

      dispatch(isLoading(false));

      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const DeleteCartItem = createAsyncThunk(
  "DeleteCartItem",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));
      const response = await DeleteFromCart(obj);
      dispatch(GetCartClassList({}));
      dispatch(isLoading(false));

      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const BookingDetail = createAsyncThunk(
  "bookingdetail",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));
      const response = await GetBookingDetails(obj);
      dispatch(isLoading(false));

      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const PayForBookings = createAsyncThunk(
  "payforbookings",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));
      const response = await BookingPayment(obj);
      dispatch(isLoading(false));
      dispatch(GetCartClassList({}));
      dispatch(FetchCartEventList({}));

      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const GetCartClassList = createAsyncThunk(
  "GetCartList",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));
      const response = await GetCart(obj);

      dispatch(isLoading(false));

      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const FetchCartEventList = createAsyncThunk(
  "fetchCartEventList",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));
      const response = await GetEventCartList(obj);
      if (response.code === 1) {
        dispatch(eventCartList(response.data.cart_list));
        dispatch(isLoading(false));
      }

      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const EventBookingDetail = createAsyncThunk(
  "eventbookingdetail",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));
      const response = await GetEventBookingDetails(obj);
      dispatch(isLoading(false));

      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const FetchBookingList = createAsyncThunk(
  "getchBookingList",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));
      const response = await GetbookingList(obj);
      dispatch(bookingLists(response?.data?.booking_list));
      dispatch(bookingCount(response.data.booking_count));

      dispatch(isLoading(false));

      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const FetchUserDetail = createAsyncThunk(
  "fetchuserdetail",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));
      const response = await userDetail(obj);
      dispatch(details(response?.data?.user));
      dispatch(isLoading(false));

      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const FetchAreaCodeList = createAsyncThunk(
  "FetchAreaCodeList",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));
      const response = await areaCodeList(obj);
      if (response?.code === 1) {
        dispatch(areaCodeLists(response?.data?.areacode_list));
      }

      dispatch(isLoading(false));

      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const FetchMemberShipList = createAsyncThunk(
  "FetchMemberShipList",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));
      const response = await membershipList(obj);
      if (response?.code === 1) {
        dispatch(membershipLists(response?.data?.membership_list));
      }

      dispatch(isLoading(false));

      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const FetchMyMemberShipList = createAsyncThunk(
  "FetchMyMemberShipList",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));
      const response = await mySubscribedMembershipList(obj);
      if (response?.code === 1) {
        dispatch(myMembershipLists(response?.data?.membership_list));
      }

      dispatch(isLoading(false));

      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const FetchStudentDetail = createAsyncThunk(
  "FetchStudentDetail",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));
      const response = await studentDetail(obj);
      if (response?.code === 1) {
        dispatch(studentDetails(response?.data?.student_details));
      }

      dispatch(isLoading(false));

      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const FetchDisciplineList = createAsyncThunk(
  "DisciplineList",
  async (obj, { dispatch }) => {
    try {
      const response = await disciplineList(obj);
      if (response?.code === 1) {
        dispatch(disciplineListing(response?.data?.discipline_list));
      }
      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const FetchTNC = createAsyncThunk("TNC", async (obj, { dispatch }) => {
  try {
    const response = await getData("/user/getTermAndCondition", obj);
    if (response?.code === 1) {
      console.log(response, "TNC");
      dispatch(tncListing(response?.data));
    }
    return response;
  } catch (error) {
    toast.error(error.message);
  }
});
export const MainSlice = createSlice({
  name: "mainSlice",
  initialState,
  reducers: {
    tncListing: (state, action) => {
      state.tnc = action.payload;
    },
    disciplineListing: (state, action) => {
      state.disciplinesList = action.payload;
    },
    studentDetails: (state, action) => {
      state.studentDetail = action.payload;
    },
    membershipLists: (state, action) => {
      state.membershipList = action.payload;
    },
    areaCodeLists: (state, action) => {
      state.areaCodeList = action.payload;
    },
    isLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    onConsentCall: (state, action) => {
      state.consent = action.payload;
    },
    eventCartList: (state, action) => {
      state.eventInCartList = action.payload;
    },
    bookingLists: (state, action) => {
      state.bookingList = action.payload;
    },
    bookingCount: (state, action) => {
      state.totalBooking = action.payload;
    },
    details: (state, action) => {
      state.detail = action.payload;
    },
    teacherList: (state, action) => {
      state.teacherList = action.payload;
    },
    myMembershipLists: (state, action) => {
      state.myMembershipList = action.payload;
    },
    removeStudentLisitng: (state, action) => {
      state.studentList = action.payload;
    },
    updateForgetData: (state, action) => {
      state.forgetData = action.payload;
    },
    deleteStudentListing: (state, action) => {
      state.studentList = action.payload;
    },
  },
  extraReducers: {
    [GetStudentList.fulfilled]: (state, action) => {
      state.studentList = action?.payload?.data?.student_list;
    },
    [SchoolList.fulfilled]: (state, action) => {
      state.schoolList = action?.payload?.data?.school_list;
    },
    [MonthlyDateClassList.fulfilled]: (state, action) => {
      state.monthlyClassList = action?.payload?.data?.class_list;
    },
    [GetCartClassList.fulfilled]: (state, action) => {
      state.cartList = action?.payload?.data?.cart_list?.cart_data;
      if (action?.payload?.data?.cart_list?.total_amount) {
        state.totalCartAmount = action?.payload?.data?.cart_list?.total_amount;
      } else {
        state.totalCartAmount = 0;
      }
    },
    [FetchCartEventList.fulfilled]: (state, action) => {
      state.totalAMountOfEventsInCart =
        action?.payload?.data?.cart_list?.total_amount;
    },
  },
});

export const getStudentDetailSelector = (state) => state?.main?.studentDetail;

export const eventTotalInCartSelector = (state) =>
  state?.main?.totalAMountOfEventsInCart;

export const userDetailSelector = (state) => state?.main?.detail;
export const tncSelector = (state) => state?.main?.tnc;

export const bookingListSelector = (state) => state?.main?.bookingList;
export const bookingCountSelector = (state) => state?.main?.totalBooking;

export const studentListSelector = (state) => state?.main?.studentList;

export const schoolListSelector = (state) => state?.main?.schoolList;
export const monthlyListSelector = (state) => state?.main?.monthlyClassList;
export const GetCartClassListSelector = (state) => state?.main?.cartList;
export const GetCartTotalSelector = (state) => state?.main?.totalCartAmount;

export const onConsentSelector = (state) => state?.main?.consent;

export const getEventAddedToCartListSelector = (state) =>
  state?.main?.eventInCartList;
export const teacherListSelector = (state) => state?.main?.teacherList;
export const areaCodeListSelector = (state) => state?.main?.areaCodeList;
export const membershipListSelector = (state) => state?.main?.membershipList;

export const {
  isLoading,
  onConsentCall,
  eventCartList,
  disciplineListing,
  bookingLists,
  bookingCount,
  deleteStudentListing,
  details,
  isAddedToCart,
  teacherList,
  removeStudentLisitng,
  studentDetails,
  areaCodeLists,
  membershipLists,
  myMembershipLists,
  updateForgetData,
  tncListing,
} = MainSlice.actions;

export default MainSlice.reducer;
