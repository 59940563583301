import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router";
import * as Yup from "yup";
import { addWalletAmount } from "../../api/payment";
import moment from "moment";
import { toast } from "react-toastify";
import { postFormData } from "../../api";
import { useSelector } from "react-redux";
import { userDetailSelector } from "../slice";
import { addStudentDataReducer } from "../student/StudentSlice";
import { useDispatch } from "react-redux";
function MakePayment() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { state } = location;
  console.log(state, "Location");
  const user = useSelector(userDetailSelector);
  const schema = Yup.object({
    total_amount: Yup.number()
      .positive("Kindly enter a valid amount.")
      .required("Required")
      .typeError("Kindly enter a valid amount."),
    // payment_mode: Yup.string().required("Kindly select a payment mode."),
  });
  const formik = useFormik({
    initialValues: {
      total_amount: Math.abs(state?.amount) || "",
    },
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: (values) => {
      console.log(values, "values");
      if (state.payment_for === "wallet") {
        const payload = {
          data: {
            amount: values.total_amount,
            date: moment().format("YYYY-MM-DD"),
            transaction_id: "",
          },
          payment_for: "wallet",
          api: "user/addWalletAmount",
        };

        (async () => {
          await localStorage.setItem(
            "bookingMembershipData",
            JSON.stringify(payload)
          );
          dispatch(addStudentDataReducer(payload));
        })();

         addWalletAmount({
           ...payload?.data,
           currency: "GBP",
           merchant_support_email: user?.email,
         }).then((res) => {
           console.log(res, "Wallet ");
           if (res?.code === 1) {
            if(res?.data?.payment_link?.paymentLink?.url){
               let URI = res?.data?.payment_link?.paymentLink?.url;
               window.open(URI, "_self");
            }
             toast.success(res?.message);
           } else {
             toast.error(res?.message);
           }
           return res;
         });

        // postFormData("booking/createPaymentLink", {
        //   amount: payload.data?.amount,
        //   currency: "GBP",
        //   merchant_support_email: user.email,
        //   payment_for: "wallet",
        //   wallet_used_amount: 0,
        // }).then((res) => {
        //   console.log(res, "Create payment api");
        //   if (res?.code === 1) {
        //     let URI = res?.data?.result?.paymentLink?.url;
        //     window.open(URI, "_self");
        //   } else {
        //     toast.error(res?.message);
        //   }
        // });
      }
    },
  });

  return (
    <section className="sec-bg py-4 px-3 bottom-space">
      <div className="container-fluid">
        <div className="form-style form-mw ">
          <form className="row" onSubmit={formik.handleSubmit}>
            <div className="col-sm-12 col-md-12 col-lg-12 mt-3 text-center">
              <img
                src="assets/img/select-payment.png"
                className=""
                alt=""
                height={100}
              />
            </div>
            <div className="form-group col-sm-12 col-md-12 text-center mb-5">
              <h2 className="mdl-ttl">Make Payment</h2>
            </div>
            <div
              style={{
                maxWidth: "500px",
                margin: "auto",
              }}
            >
              <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-5 ">
                <label className="fw-bold">Amount</label>
                <div className="input-container">
                  <input
                    type="number"
                    className="form-control ps-4"
                    placeholder="Amount"
                    name="total_amount"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.total_amount}
                  />
                  <img
                    src="assets/img/First-Name.png"
                    className="input-img"
                    alt=""
                  />
                </div>
                {formik.errors.total_amount && formik.touched.total_amount && (
                  <span className="text-danger">
                    {formik.errors?.total_amount}
                  </span>
                )}
              </div>
              {/* <div className="col-sm-12 col-md-12 col-lg-8"></div>
              <div className="col-sm-12 col-md-12 col-lg-12 mt-3">
                <h4>Payment Mode</h4>
                {formik.errors.payment_mode && formik.touched.payment_mode && (
                  <span className="text-danger">
                    {formik.errors?.payment_mode}
                  </span>
                )}
                <div className="checkboxs payment-mode d-flex justify-content-start">
                  <label>
                    <input
                      type="radio"
                      value="card"
                      name="payment_mode"
                      onChange={formik.handleChange}
                    />
                    <div className="div-bg">
                      <p>
                        <span>
                          <img
                            src={
                              formik.values.payment_mode === "card"
                                ? "assets/img/dabit-card-active.png"
                                : "assets/img/dabit-card.png"
                            }
                            alt=""
                          />{" "}
                          debit/credit card
                        </span>
                      </p>
                    </div>
                  </label>

                  <label>
                    <input
                      type="radio"
                      value="netBanking"
                      name="payment_mode"
                      onChange={formik.handleChange}
                    />
                    <div className="div-bg">
                      <p>
                        <span>
                          <img
                            src={
                              formik.values.payment_mode === "netBanking"
                                ? "assets/img/net-banking-active.png"
                                : "assets/img/net-banking.png"
                            }
                            alt=""
                          />{" "}
                          net banking
                        </span>
                      </p>
                    </div>
                  </label>
                </div>
              </div> */}
              <div className="col-sm-12 col-md-12 col-lg-12 mt-3 text-center">
                <button
                  className="btn btn-accept px-5 py-3 mt-2 fs-5"
                  type="submit"
                  id="paymenyDone"
                >
                  Pay
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}

export default MakePayment;
