import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

function Notification() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const list = useSelector((state) => state.notification.list);

  const getNotification = useCallback(() => {}, []);

  useEffect(() => {
    let isRendered = false;

    if (!isRendered) {
      getNotification();
    }
    return () => {
      isRendered = false;
    };
  }, []);
  return (
    <section className="sec-bg py-4 px-3 bottom-space">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-sm-12 col-md-12 col-lg-12 mb-3 text-center">
            <h3>Notification</h3>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-sm-12 col-md-10 col-lg-8 mb-4 notificationPage">
            {list?.length > 0 ? (
              list?.map(() => {
                return (
                  <div className="noti-text bg-white shadow p-2 mb-3">
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                    <p className="day-time">
                      <i className="fa fa-clock-o" aria-hidden="true"></i>{" "}
                      12:00pm
                    </p>
                  </div>
                );
              })
            ) : (
              <div>No notifications.</div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Notification;
