import React, { useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, HashRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { FetchUserDetail, userDetailSelector } from "./features/slice";
// import { fetchToken, onMessageListener } from "./firebase";
import Routess, { RenderRoutes } from "./routes/Router";
function App() {
  // const dispatch = useDispatch();
  // // inside the jsx being returned:
  // const user = useSelector(userDetailSelector);
  // const [isTokenFound, setTokenFound] = useState(
  //   user?.device_token ? true : false
  // );

  // const registerToken = useCallback(() => {
  //   if (!isTokenFound) fetchToken(setTokenFound, isTokenFound);
  // }, [isTokenFound]);

  // const registerServiceWorker = useCallback(() => {
  //   if ("serviceWorker" in navigator) {
  //     navigator.serviceWorker
  //       .register("firebase-messaging-sw.js", {
  //         scope: `${process.env.PUBLIC_URL}/`,
  //         updateViaCache: "none",
  //       })
  //       .then(function (registration) {
  //         console.log("Running");
  //         registerToken(registration);
  //       })
  //       .catch(function (err) {
  //         console.log("Service worker registration failed, error:", err);
  //       });
  //   }
  // }, [registerToken]);

  // useEffect(() => {
  //   const askForPermission = () =>
  //     Notification.requestPermission().then((permission) => {
  //       if (Notification.permission === "granted") {
  //         registerServiceWorker();
  //       } else if (Notification.permission !== "denied") {
  //         Notification.requestPermission().then((permission) => {
  //           if (permission === "granted") {
  //             registerServiceWorker();
  //           } else {
  //             askForPermission();
  //           }
  //         });
  //       }
  //     });
  //   if (user) {
  //     askForPermission();
  //   }
  // }, [registerServiceWorker, user]);

  // onMessageListener()
  //   .then((payload) => {
  //     // setShow(true);
  //     // setNotification({
  //     //   title: payload.notification.title,
  //     //   body: payload.notification.body,
  //     // });
  //     toast(
  //       `${payload.notification.title}
  //      ${payload?.notification.body}`,
  //       {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "light",
  //       }
  //     );
  //     console.log(payload);
  //   })
  //   .catch((err) => console.log("failed: ", err));

  // useEffect(() => {
  //   if (!user) {
  //     dispatch(FetchUserDetail());
  //   }
  // }, [dispatch, user]);

  // /bookinclass-react.js/build/

  return (
    <>
      <BrowserRouter basename="/">
        <RenderRoutes routers={Routess} />
      </BrowserRouter>
    </>
  );
}

export default App;
