import { Route, Routes } from "react-router-dom";
import Footer from "../component/Footer";
import Navbar from "../component/Navbar";
import EventDetail from "../features/event/EventDetail";
import CreateAccount from "../features/login/CreateAccount";
import ForgetPassword from "../features/login/ForgetPassword";
import Login from "../features/login/Login";
import MyMembership from "../features/membership/MyMembership";
import PaymentSummary from "../features/payment/PaymentSummary";
import Records from "../features/records/Records";
import AddStudent from "../features/student/AddStudent";
import PrivateRoute from "./PrivateRoute";
import ChangePlan from "./../features/membership/ChangePlan";
import AccountCancellation from "../features/student/AccountCancellation";
import ChangePassword from "../features/login/ChangePassword";
import Cart from "../features/payment/Cart";
import EditProfile from "../features/profile/EditProfile";
import Notification from "./../features/notification/Notification";
import OtpVerification from "./../features/login/OtpVerification";
import SelectStudent from "../features/student/SelectStudent";
import ResetPassword from "../features/login/ResetPassword";
import { useLocation } from "react-router";
import { useLayoutEffect } from "react";
import Dashboard from "../features/Dashboard";
import AllStudent from "../features/student/AllStudent";
import Address from "../features/login/Address";
import AddStudentDetails from "../features/login/AddStudentDetails";
import BookClass from "../features/class/BookClass";
import Bookings from "../features/booking/Bookings";
import { useSelector } from "react-redux";
import PaymentDetail from "../features/payment/PaymentDetail";
import EventListing from "../features/event/EventListing";
import MakePayment from "../features/payment/MakePayment";
import TNC from "../features/tnc/TNC";
import DetailOfPaymentDetail from "../features/payment/DetailOfPaymentDetail";
import CompletedPayment from "../features/payment/CompletedPayment";
import BookingDetail from "../features/booking/Detail";

const Routess = [
  {
    path: "/records",
    element: Records,
  },
  {
    path: "/add-student",
    element: AddStudent,
  },
  {
    path: "/edit-student/:id",
    element: AddStudent,
  },

  {
    path: "/event/:id/:studentId",
    element: EventDetail,
  },
  {
    path: "/event/:id",
    element: EventDetail,
  },
  {
    path: "/make-payment",
    element: MakePayment,
  },
  {
    path: "/events",
    element: EventListing,
  },
  {
    path: "/payments",
    element: PaymentSummary,
  },
  {
    path: "/my-membership",
    element: MyMembership,
  },
  {
    path: "/plans/:id",
    element: ChangePlan,
  },
  {
    path: "/account-cancellation",
    element: AccountCancellation,
  },
  {
    path: "/account-cancellation/:id",
    element: AccountCancellation,
  },
  {
    path: "/account-cancellation/student/:id",
    element: AccountCancellation,
  },
  {
    path: "/change-password",
    element: ChangePassword,
  },

  {
    path: "/cart",
    element: Cart,
  },
  {
    path: "/edit-profile",
    element: EditProfile,
  },
  {
    path: "/notification",
    element: Notification,
  },
  {
    path: "/select-student",
    element: SelectStudent,
  },
  {
    path: "/dashboard",
    element: Dashboard,
  },
  {
    path: "/student",
    element: AllStudent,
  },
  {
    path: "/book-class",
    element: BookClass,
  },
  {
    path: "/bookings",
    element: Bookings,
  },
  {
    path: "/payment-detail/:id",
    element: PaymentDetail,
  },
  {
    path: "/detail",
    element: DetailOfPaymentDetail,
  },
  {
    path: "/completedOrders",
    element: CompletedPayment,
  },
  {
    path: "/booking/:id",
    element: BookingDetail,
  },
];

export default Routess;

export function RenderRoutes({ routers }) {
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  const isLoading = useSelector((state) => state.main.isLoading);
  return (
    <>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Login />} />
        <Route path="/create-account" element={<CreateAccount />} />
        <Route path="/address" element={<Address />} />
        <Route path="/add-student-details" element={<AddStudentDetails />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/otp" element={<OtpVerification />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/terms-and-condition/:type" element={<TNC />} />

        {routers.map((route, i) => {
          return (
            <Route
              key={i}
              path={route.path}
              element={
                <PrivateRoute>
                  <Navbar />
                  <route.element />
                  <Footer />
                </PrivateRoute>
              }
            />
          );
        })}
        <Route path="*" element={<Login />} />
      </Routes>
      {/* {isLoading && <Spinner animation="grow" />} */}
    </>
  );
}
