import moment from "moment";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { changeDetailOfPaymentDetail } from "../features/payment/PaymentSlice";
import ReadMore from "./ReadMore/ReadMore";

function PaymentClassCard({ data, amount, date, allData }) {
  console.log(data, amount, date);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <div
      className="bg-white p-3 rounded h-100 shadow cursor-pointer"
      onClick={() => {
        (async () => {
          await localStorage.setItem(
            "detailOfPaymentDetail",
            JSON.stringify(allData)
          );
        })();
        dispatch(changeDetailOfPaymentDetail(allData));
        setTimeout(() => {
          navigate("/detail");
        }, 500);
      }}
    >
      <div className="row">
        <div className="col-sm-6 col-md-12 col-lg-12 col-xl-8 mb-2 text-capitalize">
          {data?.class_details && <h5>{data?.class_details?.name || ""}</h5>}
          {data?.event_details && <h5>{data?.event_details?.name || ""}</h5>}
          <p className="mb-2">
            {data?.time_slot_list
              ?.map((it) => it?.time_slot_for)
              .filter(function (item, pos, arr) {
                return arr.indexOf(item) === pos;
              })
              .join(" | ")}
          </p>
          <p className="theme-color1 mb-2">
            {data?.school_details?.name || ""}
          </p>
          <p className="mb-2">
            {" "}
            <img src="assets/img/address.png" class="" alt="" />{" "}
            {data?.school_details?.address || ""}
          </p>
          <p className="mb-0">
            {data?.student_list
              ?.map(
                (it) =>
                  `${it?.student_details?.first_name} ${it?.student_details?.last_name}`
              )
              .join(", ")}
          </p>
        </div>
        <div className="col-sm-6 col-md-12 col-lg-12 col-xl-4 mb-2 position-relative text-sm-end">
          <p className="text-capitalize text-muted">
            On: {moment(date).format("DD MMM YYYY")}
          </p>
          <div className="payment-type">
            <p className="text-capitalize theme-color fw-bold mb-0 fs-4">
              <i class="fa fa-gbp" aria-hidden="true"></i> {amount}
            </p>
          </div>
        </div>

        {data?.event_details && (
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-2 text-capitalize">
            <label className="fw-bold">About Event: </label>
            <p>
              <ReadMore>{data?.event_details?.about}</ReadMore>
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default PaymentClassCard;
