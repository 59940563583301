import React from "react";
import { Modal } from "react-bootstrap";

function MembershipTNC({ show, handleShow, data }) {
  return (
    <Modal
      show={show}
      onHide={() => {
        handleShow("hide");
      }}
      size="lg"
      centered
    >
      <Modal.Body>
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content other-city b-r-20">
            <div className="modal-header text-center">
              <div>
                <h2 className="mdl-ttl">Terms and Conditions</h2>
              </div>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  handleShow("hide");
                }}
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12 mt-3">
                  <div className="h-100 ">
                    <p>{data && data}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default MembershipTNC;
