import React, { useEffect, useState } from "react";
import moment from "moment";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BookingDetail, EventBookingDetail } from "../../features/slice";
import ReadMore from "../ReadMore/ReadMore";
import Loader from "./../Loader";

function OrderSummary({ show, handleShow, item, id, page }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [eventData, setEventData] = useState(null);
  const [loading, setLoading] = useState(null);

  useEffect(() => {
    // if (id && page === "event") {
    //   dispatch(EventBookingDetail(id)).then((res) => {
    //     if (res?.payload?.code === 1) {
    //       setData(res?.payload?.data);
    //     }
    //   });
    // } else {
    setLoading(true);
    dispatch(BookingDetail(id)).then((res) => {
      if (res?.payload?.code === 1) {
        setData(res?.payload?.data);
        setLoading(false);
      }
    });
    // }
  }, [id, dispatch]);

  return (
    <Modal show={true} onHide={handleShow} size="lg" centered>
      <div className="modal-dialog modal-dialog-centered modal-xl">
        <div className="modal-content">
          <div className="modal-header d-grid text-center">
            <h3 className="mdl-ttl">order summary</h3>
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                handleShow("hide");
              }}
            ></button>
          </div>
          <div className="modal-body">
            {loading ? (
              <Loader />
            ) : (
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12 mt-3 text-center">
                  <img
                    src="assets/img/order-summary.png"
                    className=""
                    alt=""
                    style={{ height: "150px" }}
                  />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4 mt-3">
                  <div className="bg-white p-2 text-capitalize mt-3">
                    <p className="detail-account position-relative">
                      <img
                        src="assets/img/First-Name.png"
                        className="icon-title"
                        alt=""
                      />
                      <span>full name</span>
                      <span>
                        <b>
                          {data?.user_details
                            ? `${data?.user_details?.first_name} ${data?.user_details?.last_name}`
                            : ""}
                        </b>
                      </span>
                    </p>
                  </div>
                </div>

                {/* <div className="col-sm-12 col-md-6 col-lg-4 mt-3">
                  <div className="bg-white p-2 text-capitalize mt-3">
                    <p className="detail-account position-relative">
                      <img
                        src="assets/img/membership-status.png"
                        className="icon-title"
                        alt=""
                      />
                      <span className="">Event Name</span>
                      <span>
                        <b>
                          {data?.booking_details?.booking_events
                            ?.map((item) => {
                              return item?.event_details?.name;
                            })
                            .join(", ") || "NA"}
                        </b>
                      </span>
                    </p>
                  </div>
                </div> */}
                {/* <div className="col-sm-12 col-md-6 col-lg-4 mt-3">
                  <div className="bg-white p-2 text-capitalize mt-3">
                    <p className="detail-account position-relative">
                      <img
                        src="assets/img/date-of-birth.png"
                        className="icon-title"
                        alt=""
                      />
                      <span className="">date of birth</span>
                      <span>
                        <b>
                          {data?.user_details?.address_details?.dob || "NA"}
                        </b>
                      </span>
                    </p>
                  </div>
                </div> */}

                {/* <div className="col-sm-12 col-md-6 col-lg-4 mt-3">
                  <div className="bg-white p-2 text-capitalize mt-3">
                    <p className="detail-account position-relative">
                      <img
                        src="assets/img/discipline.png"
                        className="icon-title"
                        alt=""
                      />
                      <span className="">Event Type</span>
                      <span>
                        <b>
                          {
                            data?.booking_details?.booking_events?.[0]
                              ?.event_details?.type
                          }
                        </b>
                      </span>
                    </p>
                  </div>
                </div> */}

                <div className="col-sm-12 col-md-6 col-lg-4 mt-3">
                  <div className="bg-white p-2 text-capitalize mt-3">
                    <p className="detail-account position-relative">
                      <i
                        className="fa fa-gbp icon-title"
                        aria-hidden="true"
                      ></i>

                      <span className="">Total Amount</span>
                      <span>
                        <b>{data?.booking_details?.total_amount || "NA"}</b>
                      </span>
                    </p>
                  </div>
                </div>

                <div className="col-sm-12 col-md-12 col-lg-12 mt-3">
                  <div className="bg-white p-2 text-capitalize mt-3">
                    {/* {data?.booking_details?.booking_classes?.length > 0 ? (
                      <div className="p-2">
                        <label className="fw-bold mb-2">
                          <h4>Classes Booked</h4>
                        </label>
                        {data?.booking_details?.booking_classes?.map(
                          (classes, i) => {
                            const sortedList = [
                              ...classes?.time_slot_list,
                            ]?.sort(function compare(a, b) {
                              console.log(a, b);
                              var dateA = new Date(a.date);
                              var dateB = new Date(b.date);
                              return dateA - dateB;
                            });
                            return sortedList?.map((time_slot, ind) => {
                              return time_slot?.student_list?.map(
                                (student, index) => {
                                  return (
                                    <div className="shadow mb-2 p-3">
                                      <p className="mb-0">
                                        <label className="fw-bold mb-1">
                                          Student Name:
                                        </label>{" "}
                                        {student?.student_details?.first_name +
                                          " " +
                                          student?.student_details?.last_name}
                                      </p>
                                      <p className="mb-0">
                                        <label className="fw-bold mb-1">
                                          Membership Level:
                                        </label>{" "}
                                        {student?.student_details
                                          ?.trialAccountStatus === "3"
                                          ? "Active"
                                          : student?.student_details
                                              ?.trialAccountStatus === "1"
                                          ? "Trial"
                                          : "NA"}
                                      </p>
                                      <p className="mb-0">
                                        <label className="fw-bold mb-1">
                                          Class Name:
                                        </label>{" "}
                                        {classes?.class_details?.name}
                                      </p>
                                      <p className="mb-0">
                                        <label className="fw-bold mb-1">
                                          Venue:
                                        </label>{" "}
                                        {classes?.school_details?.name},{" "}
                                        {classes?.school_details?.address}
                                      </p>
                                      <p className="mb-0">
                                        <label className="fw-bold mb-1">
                                          Date:
                                        </label>{" "}
                                        {moment(time_slot?.date).format(
                                          "DD MMM YYYY"
                                        )}
                                      </p>
                                      <p className="mb-0">
                                        <label className="fw-bold mb-1">
                                          Time:
                                        </label>{" "}
                                        {moment(
                                          time_slot?.start_time,
                                          "hh:mm:ss"
                                        ).format("hh:mm a")}
                                        -{" "}
                                        {moment(
                                          time_slot?.end_time,
                                          "hh:mm:ss"
                                        ).format("hh:mm a")}
                                      </p>
                                    </div>
                                  );
                                }
                              );
                            });
                          }
                        )}
                      </div>
                    ) : null} */}
                    {data?.booking_details?.booking_classes?.length > 0 ? (
                      <div className="p-2">
                        <label className="fw-bold mb-2">
                          <h4>Classes Booked</h4>
                        </label>
                        {data?.booking_details?.booking_classes?.map(
                          (classes, i) => {
                            const sortedList = [
                              ...classes?.time_slot_list,
                            ]?.sort(function compare(a, b) {
                              console.log(a, b);
                              var dateA = new Date(a.date);
                              var dateB = new Date(b.date);
                              return dateA - dateB;
                            });

                            return (
                              <div className="shadow mb-2 p-3">
                                <p className="mb-1">
                                  <label className="fw-bold mb-1">
                                    Class Name:
                                  </label>{" "}
                                  {classes?.class_details?.name}
                                </p>
                                <p className="mb-1">
                                  <label className="fw-bold mb-1">Venue:</label>{" "}
                                  {classes?.school_details?.name},{" "}
                                  {classes?.school_details?.address}
                                </p>
                                <div className="table-responsive">
                                  <table className="table storetable">
                                    <thead className=" text-center ">
                                      <tr>
                                        <th>Date</th>
                                        <th>Time</th>
                                        <th>Students</th>
                                      </tr>
                                    </thead>

                                    <tbody>
                                      {sortedList?.map((time_slot, index) => {
                                        return (
                                          <tr
                                            key={index}
                                            className="text-center"
                                          >
                                            <td>
                                              {" "}
                                              {moment(time_slot?.date).format(
                                                "DD MMM YYYY"
                                              )}
                                            </td>
                                            <td>
                                              {" "}
                                              {moment(
                                                time_slot?.start_time,
                                                "hh:mm:ss"
                                              ).format("hh:mm a")}
                                              -{" "}
                                              {moment(
                                                time_slot?.end_time,
                                                "hh:mm:ss"
                                              ).format("hh:mm a")}
                                            </td>
                                            <td>
                                              {time_slot?.student_list
                                                ?.map(
                                                  (student) =>
                                                    `${student?.student_details?.first_name} ${student?.student_details?.last_name}`
                                                )
                                                ?.join(", ")}
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            );

                            // return sortedList?.map((time_slot, ind) => {
                            //   return time_slot?.student_list?.map(
                            //     (student, index) => {
                            //       return (
                            //         <div className="shadow mb-2 p-3">
                            //           <p className="mb-0">
                            //             <label className="fw-bold mb-1">
                            //               Student Name:
                            //             </label>{" "}
                            //             {student?.student_details?.first_name +
                            //               " " +
                            //               student?.student_details?.last_name}
                            //           </p>
                            //           <p className="mb-0">
                            //             <label className="fw-bold mb-1">
                            //               Membership Level:
                            //             </label>{" "}
                            //             {student?.student_details
                            //               ?.trialAccountStatus === "3"
                            //               ? "Active"
                            //               : student?.student_details
                            //                   ?.trialAccountStatus === "1"
                            //               ? "Trial"
                            //               : "NA"}
                            //           </p>

                            //           <p className="mb-0">
                            //             <label className="fw-bold mb-1">
                            //               Date:
                            //             </label>{" "}
                            //             {moment(time_slot?.date).format(
                            //               "DD MMM YYYY"
                            //             )}
                            //           </p>
                            //           <p className="mb-0">
                            //             <label className="fw-bold mb-1">
                            //               Time:
                            //             </label>{" "}
                            // {moment(
                            //   time_slot?.start_time,
                            //   "hh:mm:ss"
                            // ).format("hh:mm a")}
                            // -{" "}
                            // {moment(
                            //   time_slot?.end_time,
                            //   "hh:mm:ss"
                            // ).format("hh:mm a")}
                            //           </p>
                            //         </div>
                            //       );
                            //     }
                            //   );
                            // });
                          }
                        )}
                      </div>
                    ) : null}
                    {data?.booking_details?.booking_events?.length > 0 ? (
                      <div className="p-2">
                        <label className="fw-bold mb-2">
                          <h4>Events Booked</h4>
                        </label>
                        {data?.booking_details?.booking_events?.map(
                          (events, i) => {
                            return (
                              <div className="shadow mb-2 p-3">
                                <p className="mb-0">
                                  <label className="fw-bold mb-1">
                                    Event Name:
                                  </label>{" "}
                                  {events?.event_details?.name}
                                </p>
                                {/* <p className="mb-0">
                                  <label className="fw-bold mb-1">
                                    Level Name:
                                  </label>{" "}
                                  {events?.level_details?.name}
                                </p>
                                {events?.level_details?.time && (
                                  <p className="mb-0">
                                    <label className="fw-bold mb-1">
                                      Time:
                                    </label>{" "}
                                    {moment(
                                      events?.level_details?.time,
                                      "hh:mm:ss"
                                    ).format("hh:mm a")}
                                  </p>
                                )} */}
                                <p className="mb-0">
                                  <label className="fw-bold mb-1">Venue:</label>{" "}
                                  {events?.school_details?.name},{" "}
                                  {events?.school_details?.address}
                                </p>
                                <p className="mb-0">
                                  <label className="fw-bold mb-1">
                                    Event Date:
                                  </label>{" "}
                                  {moment(events?.event_details?.date).format(
                                    "DD MMM YYYY"
                                  )}
                                </p>
                                {events?.event_details?.start_time && (
                                  <p className="mb-0">
                                    <label className="fw-bold mb-1">
                                      Event Time:
                                    </label>{" "}
                                    {events?.event_details?.start_time
                                      ? moment(
                                          events?.event_details?.start_time,
                                          "hh:mm:ss"
                                        ).format("hh:mm a")
                                      : "NA"}
                                  </p>
                                )}
                                {/* {events?.level_details?.note && (
                                  <p className="mb-0">
                                    <label className="fw-bold mb-1">
                                      Level Note:
                                    </label>{" "}
                                    {events?.level_details?.note}
                                  </p>
                                )} */}
                                {events?.about && (
                                  <p className="mb-0">
                                    <label className="fw-bold mb-1">
                                      About:
                                    </label>{" "}
                                    {events?.about ? (
                                      <ReadMore>{events?.about}</ReadMore>
                                    ) : (
                                      "NA"
                                    )}
                                  </p>
                                )}
                                {events?.levels?.length > 0 && (
                                  <div className="table-responsive">
                                    <table className="table storetable">
                                      <thead className=" text-center ">
                                        <tr>
                                          <th>Level Name</th>
                                          <th>Level Time</th>
                                          <th>Students</th>
                                          <th>Level Note</th>
                                        </tr>
                                      </thead>

                                      <tbody>
                                        {events?.levels?.map((level, index) => {
                                          return (
                                            <tr
                                              key={index}
                                              className="text-center"
                                            >
                                              <td>
                                                {level?.level_details?.name}
                                              </td>

                                              <td>
                                                {" "}
                                                {moment(
                                                  level?.level_details?.time,
                                                  "hh:mm:ss"
                                                ).format("hh:mm a")}
                                              </td>
                                              <td>
                                                {level?.student_list
                                                  ?.map(
                                                    (student) =>
                                                      `${student?.student_details?.first_name} ${student?.student_details?.last_name}`
                                                  )
                                                  ?.join(", ")}
                                              </td>
                                              <td>
                                                <ReadMore>
                                                  {level?.level_details?.note}
                                                </ReadMore>
                                              </td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </table>
                                  </div>
                                )}
                              </div>
                            );
                          }
                        )}
                      </div>
                    ) : null}
                  </div>
                </div>

                {/* <div className="col-sm-12 col-md-6 col-lg-4 mt-3">
                  <div className="bg-white p-2 text-capitalize mt-3">
                    <p className="detail-account position-relative">
                      <img
                        src="assets/img/address.png"
                        className="icon-title"
                        alt=""
                      />
                      <span className="">Address</span>
                      <span>
                        <b>
                          {data?.user_details
                            ? `${data?.user_details?.address_details?.property_number}, ${data?.user_details?.address_details?.property_name}, ${data?.user_details?.address_details?.street_number}, ${data?.user_details?.address_details?.town}, ${data?.user_details?.address_details?.country}-${data?.user_details?.address_details?.postal_code}`
                            : "NA"}
                        </b>
                      </span>
                    </p>
                  </div>
                </div> */}

                {/* {data?.booking_details?.booking_classes?.map((item) => {
                  return item?.student_list?.map((it) => {
                    if (it?.student_details?.medical_note) {
                      return (
                        <div className="col-sm-12 col-md-12 col-lg-12 mt-3">
                          <div className="bg-white p-2 mt-3">
                            <p className="detail-account position-relative">
                              <img
                                src="assets/img/medical.png"
                                className="icon-title"
                                alt=""
                              />
                              <span className="text-capitalize">
                                medical note
                              </span>
                              <span>
                                <b></b>
                              </span>
                            </p>
                          </div>
                        </div>
                      );
                    }
                  });
                }) || null} */}

                <div className="col-sm-12 col-md-12 col-lg-12 mt-3 text-center">
                  <button
                    className="btn btn-submit"
                    onClick={() => {
                      handleShow();
                      navigate("/book-class", { replace: true });
                    }}
                  >
                    Done
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default OrderSummary;
