import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import moment from "moment";
import { Controller, useForm } from "react-hook-form";
import {
  addMemberShip,
  addStudentCall,
  areaCodeListSelector,
  FetchAreaCodeList,
  FetchDisciplineList,
  FetchStudentDetail,
  getSchools,
  userDetailSelector,
} from "../slice";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { addStudentDetailSchema } from "../../utils/schema";
import S3FileUpload from "react-s3";
import { uploadFile } from "react-s3";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import { config, htmlStringToPdf, monthOption } from "../../utils/index.js";
import { FetchMembershipListing } from "../membership/MembershipSlice";
import Phone from "../../component/phoneInput/Phone";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { addTrailMemberShip } from "../../api/student";
import BuyMembershipModal from "../../component/Modal/BuyMembershipModal";
import BuyMembershipPayment from "../../component/Modal/BuyMembershipPayment";
import { addStudentDataReducer } from "./StudentSlice";
import { postFormData } from "../../api";
import { isValidPhoneNumber } from "libphonenumber-js";
import { sendMembershipSticker } from "../../api/membership";
import { v4 as uuidv4 } from "uuid";
import S3 from "aws-s3";
import Loader from "../../component/Loader";
const S3Client = new S3(config);
window.Buffer = window.Buffer || require("buffer").Buffer;

function AddStudent() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [time, setTime] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const areaCodes = useSelector(areaCodeListSelector);
  const [schoolList, setSchoolList] = useState([]);
  const membershipLists = useSelector((state) => state.membership.list);
  const [data, setData] = useState(null);
  const [showBuyMembership, setShowBuyMembership] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const userData = useSelector(userDetailSelector);
  const [yearList, setYearList] = useState([]);
  const disciplines = useSelector((state) => state.main.disciplinesList);
  const [submitting, setSubmitting] = useState(false);
  const [renewBuySubmitting, setRenewBuySubmitting] = useState(false);
  const [isRenewal, setIsRenewal] = useState(false);
  const [isExpired, setIsExpired] = useState(false);

  const getDisciplines = useCallback(() => {
    dispatch(FetchDisciplineList({ page: 1, limit: 1000000 }));
  }, [dispatch]);

  useEffect(() => {
    getDisciplines();
  }, [getDisciplines]);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    setError,
    control,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onSubmit",
    resolver: yupResolver(addStudentDetailSchema),
    shouldFocusError: true,
  });

  const watchDiscipline = watch("discipline");
  const watchStudentType = watch("student_type");
  const watchAreaCode = watch("areacode");
  const watchTrailMembership = watch("trial_membership");
  const watchMobile = watch("mobile");
  const watchMembershipLevel = watch("membership_level");
  const watchDay = watch("day");
  const watchMonth = watch("month");
  const watchYear = watch("year");
  const watchUseWalletAmount = watch("useWalletAmount");
  const watchTNC = watch("tnc");

  const onSubmit = (dataa) => {
    const valid = dataa?.mobile ? isValid(dataa?.mobile) : true;
    let month = watchMonth;
    let year = watchYear;
    let day = watchDay;
    const date1 = new Date();
    const date2 = new Date(`${month}/${day}/${year}`);
    let diff = (date1.getTime() - date2.getTime()) / 1000;
    diff /= 60 * 60 * 24;
    diff = Math.abs(Math.round(diff / 365.25));

    const st_type = diff >= 4 && diff < 16 ? "child" : "adult";
    const payload = {
      ...dataa,
      student_type: st_type,
    };
    if (valid) {
      if (watchTrailMembership === "1") {
        setSubmitting(true);
        handleAddStudent(payload).then((response) => {
          if (response?.payload?.code === 1) {
            addTrailMemberShip({
              studentId: response?.payload?.data?.student?.id,
            }).then((res) => {
              setSubmitting(false);

              if (res?.code === 1) {
                navigate("/book-class", { replace: true });
                toast.success(res?.payload?.message);
              }
            });
          }
        });
      } else if (
        (watchTrailMembership === "3" && isRenewal) ||
        (watchTrailMembership === "3" && isExpired)
      ) {
        setShowBuyMembership(true);
      } else if (
        watchTrailMembership === "3" &&
        data?.membership_details?.membership_details?.id ===
          parseInt(watchMembershipLevel)
      ) {
        setSubmitting(true);

        handleAddStudent(payload).then((res) => {
          setSubmitting(false);

          if (res?.payload?.code === 1) {
            navigate("/book-class", { replace: true });
          }
        });
      } else if (watchTrailMembership === "3") {
        setShowBuyMembership(true);
      } else if (watchTrailMembership === "2") {
        setSubmitting(true);

        handleAddStudent(payload).then((response) => {
          setSubmitting(false);

          if (response?.payload?.code === 1) {
            navigate("/book-class", { replace: true });
            toast.success(response?.payload?.message);
          }
        });
      } else if (watchTrailMembership === "0") {
        setError("trial_membership", {
          message: "Kindly select a membership type.",
        });
      }
    }
  };

  const handleAddStudent = async (dataa) => {
    const countryCode = dataa?.mobile
      ? formatPhoneNumberIntl(dataa?.mobile).split(" ")[0]
      : "";
    return dispatch(
      addStudentCall({
        first_name: dataa?.studentFirstName,
        last_name: dataa?.studentLastName,
        dob: `${dataa?.year}-${dataa?.month}-${dataa?.day}`,
        dob_year: dataa?.year,
        dob_month: dataa?.month,
        dob_day: dataa?.day,
        student_type: dataa?.student_type,
        discipline: dataa?.discipline,
        emergency_contactNo: dataa?.mobile
          ? dataa?.mobile.replace(countryCode, "")
          : "",
        emergency_countryCode: dataa?.mobile ? countryCode : "",
        medical_note: dataa?.note,
        image: dataa?.image || "",
        studentId: id ? id : "",
        areacode: dataa?.areacode,
        schoolId: dataa?.schoolId,
        trial_membership: dataa?.membership_level
          ? ""
          : dataa?.trial_membership
          ? dataa?.trial_membership
          : "",
        membership_level:
          (watchTrailMembership === "3" &&
            data?.membership_details?.membership_details?.id?.toString() !==
              watchMembershipLevel?.toString()) ||
          (watchTrailMembership === "3" && isRenewal) ||
          (watchTrailMembership === "3" && isExpired)
            ? dataa?.membership_level
            : "",
        gender: dataa?.gender || "",
        // no_of_days: dataa?.membership_level ? dataa?.no_of_days : "",
        // price: dataa?.membership_level ? dataa?.price : "",
        wallet_used_amount: dataa?.wallet_used_amount || "",
        transaction_amount: dataa?.transaction_amount || "",
        currency: dataa?.currency ? dataa?.currency : "",
        merchant_support_email: dataa?.merchant_support_email
          ? dataa?.merchant_support_email
          : "",
        redirect_on: dataa?.redirect_on ? dataa?.redirect_on : "",
        payment_status: dataa?.payment_status,
      })
    ).then((response) => {
      if (response?.payload?.code === 1) {
        // if (!response?.payload?.data?.payment_link) {
        //   setSubmitting(false);
        // }
        toast.success(response?.payload?.message);
      } else {
        toast.error(response?.payload?.message);
      }
      return response;
    });
  };

  const handleBuy = (memberLevel) => {
    let dataa = getValues();
    let month = watchMonth;
    let day = watchDay;
    let year = watchYear;
    const date1 = new Date();
    const date2 = new Date(`${month}/${day}/${year}`);
    let diff = (date1.getTime() - date2.getTime()) / 1000;
    diff /= 60 * 60 * 24;
    diff = Math.abs(Math.round(diff / 365.25));

    const st_type = diff >= 4 && diff < 16 ? "child" : "adult";
    let price = 0;
    let walletUsetAmount = 0;
    price =
      st_type === "child"
        ? Number(memberLevel?.child_price)
        : st_type === "adult"
        ? Number(memberLevel?.adult_price)
        : 0;

    if (watchUseWalletAmount) {
      walletUsetAmount =
        Number(userData?.wallet) >= Number(price)
          ? Number(price)
          : Number(userData?.wallet) > 0 &&
            Number(userData?.wallet) <= Number(price)
          ? Number(userData?.wallet)
          : 0;
    } else if (!watchUseWalletAmount) {
      walletUsetAmount = 0;
    }
    let transaction_amount = price - walletUsetAmount;

    const { tnc, useWalletAmount, ...rest } = dataa;

    const payloads = {
      data: {
        ...rest,
        student_type: st_type,
        studentId: id ? id : "",
        membership_level: memberLevel?.id,
        trialAccountStatus: "",
        wallet_used_amount: parseInt(walletUsetAmount).toFixed(2),
        transaction_amount: transaction_amount ? transaction_amount : 0,
        payment_status: walletUsetAmount === price ? "1" : "0",
        currency: "GBP",
        merchant_support_email: userData?.email,
        redirect_on: "other",
      },

      payment_for: "membership",
      api: "student/addStudent",
    };

    if (payloads?.data?.student_type) {
      setRenewBuySubmitting(true);
      setSubmitting(true);
      handleAddStudent(payloads?.data)
        .then((res) => {
          if (res?.payload?.code === 1) {
            // navigate("/student", { replace: true });
          } else {
            toast.error(res?.payload?.message);
          }
          return res;
        })
        .then((re) => {
          if (re?.payload?.code === 1) {
            const studentData = re?.payload?.data?.student;
            const userData = re?.payload?.data?.user_address;
            if (re?.payload?.data?.payment_link) {
              let URI = re?.payload?.data?.payment_link?.paymentLink?.url;
              (async () => {
                await localStorage.setItem(
                  "bookingMembershipData",
                  JSON.stringify({
                    payment_for: "membership",
                    sub_payment_for: "",
                    userMembershipId: re?.payload?.data?.userMembershipId,
                    studentData,
                    userData,
                  })
                );
                dispatch(
                  addStudentDataReducer({
                    payment_for: "membership",
                    sub_payment_for: "",
                    userMembershipId: re?.payload?.data?.userMembershipId,
                    studentData,
                    userData,
                  })
                );
              })();
              window.open(URI, "_self");
              // setShowBuyMembership(false);
              setSubmitting(true);

              setRenewBuySubmitting(false);
            } else {
              const sendHtmlAsString = async (nameD = "") => {
                let htmlPart = `<!DOCTYPE html>
    <html lang="en">

    <head>
        <meta charset="utf-8">
        <meta http-equiv="Content-Type" content="text/html;charset=UTF-8">
        <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">

        <title>Membership Details</title>

    </head>

    <body style="width: 100 mm;height: 56 mm;">
        <div class="mw-body"
            style="width: 100%;height: 100%;
        font-family: Roboto Slab,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji">
            <div>
                <table style="width: 100%;height: 100%;border-collapse: separate;
                border-spacing: 0 0em;text-transform: uppercase; font-size: 12px; font-weight: 700;margin: 1mm;padding: 5px;">
                    <tr>
                        <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
        border-right: 0;"><span>name</span></td>
                        <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;"><span style="color: #ff0000;"> ${
                          studentData?.first_name
                        } ${studentData?.last_name}</span></td>

                    </tr>
                    <tr>
                        <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                            border-right: 0;"><span>address</span></td>
                        <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;"><span
                                style="color: #ff0000;"> ${
                                  userData?.property_name
                                }, ${
                  userData?.property_number
                    ? `${userData?.property_number}, `
                    : ""
                } ${userData?.street_number}</span></td>

                    </tr>
                    <tr>
                        <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                            border-right: 0;"><span>town</span></td>
                        <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                            "><span style="color: #ff0000;"> ${
                              userData?.town
                            }</span></td>
                    </tr>
                    <tr>
                        <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                            border-right: 0;"><span>postcode</span></td>
                        <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                            "><span style="color: #ff0000;"> ${
                              userData?.postal_code
                            }</span></td>
                    </tr>
                    <tr>
                        <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                            border-right: 0;"><span>dob</span></td>
                        <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                            "><span style="color: #ff0000;"> ${moment(
                              studentData?.dob
                            ).format("DD MMM YYYY")}</span></td>
                    </tr>
                    <tr>
                        <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                            border-right: 0;"><span>school</span></td>
                        <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                            "><span style="color: #ff0000;"> ${
                              studentData?.school_details?.name
                            }</span></td>
                    </tr>
                     <tr>
                                    <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                                        border-right: 0;"><span>discipline</span></td>
                                    <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                                        "><span style="color: #ff0000;"> ${
                                          studentData?.discipline_details?.name
                                        }</span></td>
                                </tr>
                    <tr>
                        <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                            border-right: 0;"><span>first licence</span></td>
                        <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                            "><span style="color: #ff0000;"> ${moment(
                              studentData?.membership_details?.start_date
                            ).format("DD MMM YYYY")}</span></td>
                    </tr>
                    <tr>
                        <td style="border-collapse: collapse;padding: 1mm;
                            border-right: 0;"><span>date</span></td>
                        <td style="border-collapse: collapse;padding: 1mm;
                            "><span style="color: #ff0000;"> ${moment(
                              studentData?.membership_details?.valid_till
                            ).format("DD MMM YYYY")}</span></td>
                    </tr>
                </table>
            </div>
    </body>

    </html>`;

                const pdfOutput = await htmlStringToPdf(htmlPart);

                return pdfOutput;
              };
              sendHtmlAsString().then((ob) => {
                if (ob) {
                  const newFileName = uuidv4();
                  S3Client.uploadFile(ob, newFileName)
                    .then((data) => {
                      if (data?.location) {
                        const stickerPayload = {
                          user_membership_id:
                            studentData?.membership_details?.id,
                          file_url: data?.location,
                          userId: studentData?.userId,
                        };
                        sendMembershipSticker(stickerPayload).then((res) => {
                          if (res?.code === 1) {
                            setSubmitting(false);
                            setShowBuyMembership(false);
                            setRenewBuySubmitting(false);
                            navigate("/student", { replace: true });
                            toast.success(res?.message);
                          } else {
                            toast.error(res?.message);
                          }
                        });
                      }
                    })

                    .catch((err) => console.error(err));
                }
              });
            }
          }
        });
      // .then(() => {
      //   setSubmitting(false);
      // });
    } else {
      toast.error("Student type is not added. Kindly add student type.");
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(FetchStudentDetail(id)).then((res) => {
        const data = res?.payload?.data?.student_details;
        setData(data);
        reset({
          studentFirstName: data?.first_name,
          studentLastName: data?.last_name,
          // dob: new Date(data?.dob),
          year: new Date(data?.dob)?.getFullYear().toString(),
          month: new Date(data?.dob)?.getMonth() + 1,
          day: moment(data?.dob).format("DD"),
          student_type: data?.student_type,
          discipline: data?.discipline,
          mobile: data?.emergency_contactNo
            ? `${data?.emergency_countryCode}${data?.emergency_contactNo}`
            : "",
          membership_level: data?.membership_details?.membership_level || "",
          note: data?.medical_note || "",
          areacode: data?.areacode,
          schoolId: data?.schoolId,
          image: data?.image?.includes("http") ? data?.image : "",
          trial_membership: data?.trialAccountStatus,
          gender: data?.gender || "",
          // no_of_days: data?.membership_details?.no_of_days,
          // price:
          //   data?.student_type?.toLowerCase() === "child"
          //     ? data?.membership_details?.membership_details?.child_price
          //     : data?.student_type?.toLowerCase() === "adult"
          //     ? data?.membership_details?.membership_details?.adult_price
          //     : "",
        });
      });
    }
  }, [dispatch, id, reset]);

  useEffect(() => {
    let interval;
    if (isActive && time < 100) {
      interval = setInterval(() => {
        // if (time < 100)
        setTime((seconds) => (seconds < 99 ? seconds + 1 : seconds));
      }, 10);
    }
    return () => clearInterval(interval);
  }, [isActive, time]);

  const handleImage = (e) => {
    const { files } = e.target;
    setIsActive(true);

    uploadFile(files[0], config)
      .then((data) => {
        setValue("image", data?.location);
        setTime(100);
        setIsActive(true);
        setTimeout(() => {
          setTime(0);
          setIsActive(false);
        }, 2000);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    dispatch(getSchools({ areacode: watchAreaCode })).then((res) => {
      if (res?.payload?.code === 1) {
        setSchoolList(res?.payload?.data?.school_list);
      }
    });
  }, [watchAreaCode, dispatch]);

  useEffect(() => {
    dispatch(FetchAreaCodeList());
  }, [dispatch]);

  const isValid = (value) => {
    if (value && !isValidPhoneNumber(value)) {
      setError("mobile", { message: "Invalid Number" });
      return false;
    } else if (value && isValidPhoneNumber(value)) {
      setError("mobile", { message: null });
      return true;
    }
  };

  const handleStudentType = useCallback(() => {
    let day = watchDay;
    let month = watchMonth;
    let year = watchYear;
    if (day && month && year) {
      const date1 = new Date();
      const date2 = new Date(`${month}/${day}/${year}`);
      let diff = (date1.getTime() - date2.getTime()) / 1000;
      diff /= 60 * 60 * 24;
      diff = Math.abs(Math.round(diff / 365.25));

      return diff >= 4 && diff < 16 ? "child" : "adult";
    }
  }, [watchDay, watchMonth, watchYear]);

  const getMembershipListings = useCallback(
    (type, discipline) => {
      const st_type = type ? type : handleStudentType();
      const disc = discipline ? discipline : watchDiscipline;
      if (st_type && disc) {
        dispatch(
          FetchMembershipListing({
            page: 1,
            limit: 10000,
            student_type: st_type,
            discipline: disc,
          })
        );
      }
    },
    [handleStudentType, dispatch, watchDiscipline]
  );

  useEffect(() => {
    getMembershipListings();
  }, [getMembershipListings]);

  useEffect(() => {
    if (yearList?.length === 0) {
      let minYear = moment().subtract(4, "years").format("YYYY");
      let years = [];
      for (let i = 1920; i <= minYear; i++) {
        years.push(i?.toString());
      }
      years.sort((a, b) => b - a);
      setYearList(years);
    }
  }, [yearList?.length]);

  const handleRenewalDate = useCallback(() => {
    if (data?.membership_details) {
      const expiryDate = moment(data?.membership_details?.valid_till);
      const today = moment();
      let difference = moment.duration(expiryDate.diff(today)).asHours();

      if (
        (Number(data?.membership_details?.membership_details?.no_of_days) >
          30 &&
          difference < 30 &&
          difference >= 1) ||
        (Number(data?.membership_details?.membership_details?.no_of_days) ===
          7 &&
          difference < 7 &&
          difference >= 1) ||
        (Number(data?.membership_details?.membership_details?.no_of_days) ===
          1 &&
          difference < 2 &&
          difference >= 1)
      ) {
        return true;
      }
    }

    return false;
  }, [data]);

  const handleExpired = useCallback(() => {
    const expiryDate = moment(data?.membership_details?.valid_till);
    const today = moment();
    let difference = moment.duration(expiryDate.diff(today)).asHours();

    if (difference <= 0) {
      return true;
    }

    return false;
  }, [data]);

  useEffect(() => {
    if (data) {
      const isExpiredd = handleExpired();

      const isRenewald = handleRenewalDate();

      setIsExpired(isExpiredd);
      setIsRenewal(isRenewald);
    }
  }, [data, handleExpired, handleRenewalDate]);

  const stud_typ = () => {
    if (watchDay && watchMonth && watchYear) {
      const date1 = new Date();
      const date2 = new Date(`${watchMonth}/${watchDay}/${watchYear}`);
      let diff = (date1.getTime() - date2.getTime()) / 1000;
      diff /= 60 * 60 * 24;
      diff = Math.abs(Math.round(diff / 365.25));

      return diff >= 4 && diff < 16 ? "child" : "adult";
    }
  };

  let stType = stud_typ();

  // disciplines restriction based on stundent age
  const [studentAge, setStudentAge] = useState(null);
  const calculateAge = (day, month, year) => {
    if (!day || !month || !year) return null;

    const birthDate = new Date(year, month - 1, day);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };

  useEffect(() => {
    const age = calculateAge(watchDay, watchMonth, watchYear);
    setStudentAge(age);

    if (
      (age !== null && watchDiscipline === 2 && age < 7) ||
      (age !== null && watchDiscipline === 1 && age < 4)
    ) {
      setValue("discipline", null);
    }
  }, [watchDay, watchMonth, watchYear, setValue, watchDiscipline]);

  return (
    <section className="sec-bg py-4 px-3">
      <div className="container">
        <div className="form-style">
          <form className="row" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group col-sm-12 col-md-12 text-center mb-4">
              <h2 className="mdl-ttl">
                {id ? "Edit" : "add"} students details
              </h2>
            </div>

            {!id && (
              <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4 text-center">
                <div className="upload-btn-wrapper ">
                  <button className="btn">
                    {time > 0 && isActive ? (
                      <Progress type="circle" width={50} percent={time} />
                    ) : (
                      <img
                        src={
                          getValues("image")
                            ? getValues("image")
                            : "assets/img/Stdt1.png"
                        }
                        alt=""
                      />
                    )}

                    {time > 0 && isActive ? null : (
                      <div className="camera-icon">
                        <i className="fa fa-camera"></i>
                      </div>
                    )}
                  </button>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                      if (e.target.files.length > 0) {
                        handleImage(e);
                      }
                    }}
                  />
                </div>
              </div>
            )}

            <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
              <label className="fw-bold mb-2">Student First Name</label>
              <div className="input-container">
                <input
                  type="text"
                  className="form-control ps-4"
                  placeholder="Student First Name*"
                  disabled={id ? true : false}
                  {...register("studentFirstName")}
                />
                <img
                  alt=""
                  src="assets/img/First-Name.png"
                  className="input-img"
                />
              </div>
              {errors.studentFirstName && (
                <span className="text-danger">
                  {errors?.studentFirstName?.message}
                </span>
              )}
            </div>

            <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
              <label className="fw-bold mb-2">Student Last Name</label>
              <div className="input-container">
                <input
                  type="text"
                  className="form-control ps-4"
                  placeholder="Student Second Name*"
                  disabled={id ? true : false}
                  {...register("studentLastName")}
                />
                <img
                  alt=""
                  src="assets/img/First-Name.png"
                  className="input-img"
                />
              </div>
              {errors.studentLastName && (
                <span className="text-danger">
                  {errors?.studentLastName?.message}
                </span>
              )}
            </div>

            <div className="form-group col-sm-12 col-md-12 mb-4">
              <div className="">
                <label className="fw-bold">Gender</label>

                <div className="termCustomRadio">
                  <p>
                    <span className="me-3">
                      <Controller
                        name="gender"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <input
                            type="radio"
                            id="male"
                            name="radio-group"
                            checked={value === "male"}
                            onChange={() => {
                              onChange("male");
                            }}
                            disabled={id ? true : false}
                          />
                        )}
                      />
                      <label htmlFor="male">Male</label>
                    </span>
                    <span className="me-3">
                      <Controller
                        name="gender"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <input
                            type="radio"
                            id="female"
                            name="radio-group"
                            checked={value === "female"}
                            onChange={() => {
                              onChange("female");
                            }}
                            disabled={id ? true : false}
                          />
                        )}
                      />
                      <label htmlFor="female">Female</label>
                    </span>
                    <span className="me-3">
                      <Controller
                        name="gender"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <input
                            type="radio"
                            id="other"
                            name="radio-group"
                            checked={value === "other"}
                            onChange={() => {
                              onChange("other");
                            }}
                            disabled={id ? true : false}
                          />
                        )}
                      />
                      <label htmlFor="other">Other</label>
                    </span>
                  </p>
                </div>
              </div>
              {errors?.gender && (
                <span className="text-danger">{errors?.gender?.message}</span>
              )}
            </div>

            <div className="col-sm-12">
              <label className="fw-bold">Date Of Birth</label>{" "}
              {errors && (
                <span className="text-danger">
                  {errors?.student_type?.message}
                </span>
              )}
            </div>

            <div className="form-group col-sm-12 col-md-4 col-lg-4 mb-4">
              <div className="input-container">
                <Controller
                  name="year"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <select
                      onChange={(e) => {
                        setValue("year", e.target.value);
                        // handleStudentType(watchDay, watchMonth, e.target.value);
                        let st;
                        if (watchDay && watchMonth && e.target.value) {
                          const date1 = new Date();
                          const date2 = new Date(
                            `${watchMonth}/${watchDay}/${e.target.value}`
                          );
                          let diff = (date1.getTime() - date2.getTime()) / 1000;
                          diff /= 60 * 60 * 24;
                          diff = Math.abs(Math.round(diff / 365.25));

                          st = diff >= 4 && diff < 16 ? "child" : "adult";
                        }
                        getMembershipListings(st, watchDiscipline);
                      }}
                      disabled={id ? true : false}
                      value={value}
                      className="form-control ps-4"
                    >
                      <option value="">Select Year</option>
                      {/* <option value="2022">2022</option>
                      <option value="2021">2021</option>
                      <option value="2020">2020</option>
                      <option value="2019">2019</option>
                      <option value="2018">2018</option>
                      <option value="2017">2017</option>
                      <option value="2016">2016</option>
                      <option value="2015">2015</option>
                      <option value="2014">2014</option>
                      <option value="2013">2013</option>
                      <option value="2012">2012</option>
                      <option value="2011">2011</option>
                      <option value="2010">2010</option>
                      <option value="2009">2009</option>
                      <option value="2008">2008</option>
                      <option value="2007">2007</option>
                      <option value="2006">2006</option>
                      <option value="2005">2005</option>
                      <option value="2004">2004</option>
                      <option value="2003">2003</option>
                      <option value="2002">2002</option>
                      <option value="2001">2001</option>
                      <option value="2000">2000</option>

                      <option value="1999">1999</option>
                      <option value="1998">1998</option>
                      <option value="1997">1997</option>
                      <option value="1996">1996</option>
                      <option value="1995">1995</option>
                      <option value="1994">1994</option>
                      <option value="1993">1993</option>
                      <option value="1992">1992</option>
                      <option value="1991">1991</option>
                      <option value="1990">1990</option>
                      <option value="1989">1989</option>
                      <option value="1987">1987</option>
                      <option value="1986">1986</option>
                      <option value="1985">1985</option>
                      <option value="1984">1984</option>
                      <option value="1983">1983</option>
                      <option value="1982">1982</option>
                      <option value="1981">1981</option>
                      <option value="1980">1980</option> */}
                      {yearList?.map((item) => {
                        return (
                          <option value={item} key={item}>
                            {item}
                          </option>
                        );
                      })}
                    </select>
                  )}
                />

                <img
                  src="assets/img/date-of-birth.png"
                  className="input-img"
                  alt=""
                />
              </div>
              {errors && (
                <span className="text-danger">{errors?.year?.message}</span>
              )}
            </div>

            <div className="form-group col-sm-12 col-md-4 col-lg-4 mb-4">
              <div className="input-container">
                <Controller
                  name="month"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <select
                      onChange={(e) => {
                        setValue("month", e.target.value);
                        // handleStudentType(watchDay, e.target.value, watchYear);

                        let st;
                        if (watchDay && e.target.value && watchYear) {
                          const date1 = new Date();
                          const date2 = new Date(
                            `${e.target.value}/${watchDay}/${watchYear}`
                          );
                          let diff = (date1.getTime() - date2.getTime()) / 1000;
                          diff /= 60 * 60 * 24;
                          diff = Math.abs(Math.round(diff / 365.25));

                          st = diff >= 4 && diff < 16 ? "child" : "adult";
                        }
                        getMembershipListings(st, watchDiscipline);
                      }}
                      value={value}
                      className="form-control ps-4"
                      disabled={id ? true : false}
                    >
                      <option value="">Select Month</option>
                      {monthOption?.map((item) => (
                        <option value={item?.id} key={item?.id}>
                          {item?.value}
                        </option>
                      ))}
                    </select>
                  )}
                />

                <img
                  src="assets/img/date-of-birth.png"
                  className="input-img"
                  alt=""
                />
              </div>
              {errors && (
                <span className="text-danger">{errors?.month?.message}</span>
              )}
            </div>

            <div className="form-group col-sm-12 col-md-4 col-lg-4 mb-4">
              <div className="input-container">
                <Controller
                  name="day"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <select
                      onChange={(e) => {
                        setValue("day", e.target.value);
                        // handleStudentType(
                        //   e.target.value,
                        //   watchMonth,
                        //   watchYear
                        // );

                        let st;
                        if (e.target.value && watchMonth && watchYear) {
                          const date1 = new Date();
                          const date2 = new Date(
                            `${watchMonth}/${e.target.value}/${watchYear}`
                          );
                          let diff = (date1.getTime() - date2.getTime()) / 1000;
                          diff /= 60 * 60 * 24;
                          diff = Math.abs(Math.round(diff / 365.25));

                          st = diff >= 4 && diff < 16 ? "child" : "adult";
                        }
                        getMembershipListings(st, watchDiscipline);
                      }}
                      disabled={id ? true : false}
                      value={parseInt(value)}
                      className="form-control ps-4"
                    >
                      <option value={""}>Select Day</option>
                      {[...Array(watchMonth === "2" ? 28 : 31).keys()].map(
                        (item) => {
                          return (
                            <option value={item + 1} key={item + 1}>
                              {item + 1}
                            </option>
                          );
                        }
                      )}
                    </select>
                  )}
                />

                <img
                  src="assets/img/date-of-birth.png"
                  className="input-img"
                  alt=""
                />
              </div>
              {errors && (
                <span className="text-danger">{errors?.day?.message}</span>
              )}
            </div>

            <div
              className="form-group col-sm-12 col-md-12 col-lg-12 mb-4"
              style={{
                display: "none",
              }}
            >
              <label className="fw-bold mb-2">Student Type</label>
              <div className="checkboxs AddStdtCheck">
                <label>
                  <Controller
                    name="student_type"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <input
                        type="radio"
                        value="child"
                        name="student_type"
                        checked={value === "child"}
                        onChange={() => {
                          onChange("child");
                          let st = stud_typ();
                          getMembershipListings(st, watchDiscipline);
                        }}
                      />
                    )}
                  />
                  <div className="div-bg">
                    <p>
                      <span>child</span>
                    </p>
                  </div>
                </label>

                <label>
                  <Controller
                    name="student_type"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <input
                        type="radio"
                        value="adult"
                        name="student_type"
                        checked={value === "adult"}
                        onChange={() => {
                          onChange("adult");
                          getMembershipListings("adult", watchDiscipline);
                        }}
                      />
                    )}
                  />
                  <div className="div-bg">
                    <p>
                      <span>adult</span>
                    </p>
                  </div>
                </label>
              </div>
              {errors.student_type && (
                <span className="text-danger">
                  {errors?.student_type?.message}
                </span>
              )}
            </div>

            <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-2">
              <label className="fw-bold mb-2">Discipline</label>
              <div className="checkboxs AddStdtCheck">
                {disciplines
                  ?.filter(
                    (it) =>
                      it?.name?.toLowerCase() !== "all disciplines" ||
                      it?.id !== 6
                  )
                  ?.map((disciplin, i) => {
                    return (
                      <label key={i}>
                        <Controller
                          name="discipline"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <input
                              type="radio"
                              value={disciplin?.id}
                              name="discipline"
                              checked={value === disciplin?.id}
                              // disabled={id && watchTrailMembership === "3"}
                              disabled={
                                (id && watchTrailMembership === "3") ||
                                (disciplin?.id == 2 &&
                                  studentAge !== null &&
                                  studentAge < 7) ||
                                (disciplin?.id == 1 &&
                                  studentAge !== null &&
                                  studentAge < 4) ||
                                studentAge == null
                              }
                              onChange={() => {
                                if (!id) {
                                  onChange(disciplin?.id);
                                  // console.log(disciplin?.id);
                                  // if (data?.trialAccountStatus === "1") {
                                  //   console.log(
                                  //     watchStudentType,
                                  //     "Student type"
                                  //   );

                                  // }
                                  let st = stud_typ();
                                  getMembershipListings(st, disciplin?.id);
                                }
                              }}
                            />
                          )}
                        />
                        <div
                          className="div-bg"
                          style={{
                            background:
                              id &&
                              watchTrailMembership === "3" &&
                              watchDiscipline !== disciplin?.id
                                ? "#f0f0f1"
                                : "",
                          }}
                        >
                          <p>
                            <span>{disciplin?.name}</span>
                          </p>
                        </div>
                      </label>
                    );
                  })}

                {/* <label>
                  <Controller
                    name="discipline"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <input
                        type="radio"
                        value="kick boxing"
                        name="discipline"
                        checked={value === "kick boxing"}
                        onChange={() => {
                          onChange("kick boxing");
                          getMembershipListings(
                            watchStudentType,
                            "kick boxing"
                          );
                        }}
                      />
                    )}
                  />
                  <div className="div-bg">
                    <p>
                      <span>kick boxing</span>
                    </p>
                  </div>
                </label> */}
              </div>
              {errors.discipline && (
                <span className="text-danger">
                  {errors?.discipline?.message}
                </span>
              )}
            </div>
            <span
              style={{ color: "#3053dd", fontSize: "14px" }}
              className="mb-2"
            >
              Note: Enrollment is restricted to students aged 4 years and older
              for karate, and 7 years and older for kickboxing.
            </span>

            {
              <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                <label className="fw-bold">Membership</label>
                <div className="checkboxs AddStdtCheck">
                  {id &&
                  watchTrailMembership === "1" &&
                  watchTrailMembership === "2" ? null : (
                    <label>
                      <Controller
                        name="trial_membership"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <input
                            type="radio"
                            checked={value === "1"}
                            name="trial_membership"
                            onChange={() => {
                              setValue("trial_membership", "1");
                              setValue("membership_level", "");
                            }}
                          />
                        )}
                      />
                      <div className="div-bg">
                        <p>
                          <span>Pay For Trial Lessons</span>
                        </p>
                      </div>
                    </label>
                  )}

                  {
                    <label>
                      <Controller
                        name="trial_membership"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <input
                            type="radio"
                            checked={value === "3"}
                            name="trial_membership"
                            onChange={() => {
                              setValue("trial_membership", "3");
                              let st = stud_typ();
                              getMembershipListings(st, watchDiscipline);
                            }}
                          />
                        )}
                      />
                      <div className="div-bg">
                        <p>
                          <span>Buy Membership</span>
                        </p>
                      </div>
                    </label>
                  }
                </div>
                {errors.trial_membership && (
                  <span className="text-danger">
                    {errors?.trial_membership?.message}
                  </span>
                )}
              </div>
            }

            {id && watchTrailMembership === "3" && data?.membership_details ? (
              <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-2">
                <div>
                  <p className={isExpired ? "text-danger" : ""}>
                    Your Membership {`${isExpired ? "Expired" : "Expires"}`} on:{" "}
                    <span className="fw-bold">
                      {" "}
                      {moment(data?.membership_details?.valid_till).format(
                        "DD MMM YYYY"
                      )}
                    </span>
                  </p>
                </div>
              </div>
            ) : null}

            {watchTrailMembership === "3" && (
              <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                <label className="fw-bold mb-2">Membership Type</label>
                <div className="input-container">
                  <Controller
                    name="membership_level"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <select
                        name="membership_level"
                        value={value}
                        className="form-control ps-4"
                        onChange={(e) => {
                          setValue("membership_level", e.target.value);
                        }}
                        disabled={
                          watchTrailMembership === "3" &&
                          id &&
                          data?.membership_level
                            ? true
                            : false
                        }
                      >
                        <option value="">Select membership</option>
                        {membershipLists?.map((item) => (
                          <option value={item?.id} key={item?.id}>
                            {item?.name}
                          </option>
                        ))}
                      </select>
                    )}
                  />
                  <img
                    src="assets/img/address.png"
                    className="input-img"
                    alt=""
                  />
                </div>
                {errors.membership_level && (
                  <span className="text-danger">
                    {errors?.membership_level?.message}
                  </span>
                )}
              </div>
            )}

            <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
              <label className="fw-bold mb-2">Area</label>
              <div className="input-container">
                <Controller
                  name="areacode"
                  control={control}
                  values={getValues("areacode")}
                  render={({ field: { onChange, value } }) => (
                    <select
                      name="areacode"
                      value={value}
                      className="form-control ps-4"
                      onChange={(e) => {
                        onChange(e);
                      }}
                    >
                      <option value="">Select area name</option>
                      {areaCodes?.map((item) => (
                        <option value={item?.id} key={item?.id}>
                          {item?.areacode}
                        </option>
                      ))}
                    </select>
                  )}
                />
                <img
                  src="assets/img/address.png"
                  className="input-img"
                  alt=""
                />
              </div>
              {errors.areacode && (
                <span className="text-danger">{errors?.areacode?.message}</span>
              )}
            </div>

            <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
              <label className="fw-bold mb-2">School</label>
              <div className="input-container">
                <Controller
                  name="schoolId"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <select
                      name="schoolId"
                      value={value}
                      className="form-control ps-4"
                      onChange={onChange}
                    >
                      <option value="">Select Venue</option>
                      {schoolList?.map((item) => {
                        if (item?.areacode !== null) {
                          return (
                            <option value={item?.id} key={item?.id}>
                              {item?.name}
                            </option>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </select>
                  )}
                />
                <img
                  src="assets/img/address.png"
                  className="input-img"
                  alt=""
                />
              </div>
              {errors.schoolId && (
                <span className="text-danger">{errors?.schoolId?.message}</span>
              )}
            </div>

            <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
              <label className="fw-bold mb-2">Emergency Telephone Number</label>
              <div className="input-container">
                <Controller
                  name="mobile"
                  control={control}
                  // value={getValues("mobile")}
                  // rules={{
                  //   validate: (value) => {
                  //     return isValid(value);
                  //   },
                  // }}
                  defaultValue={null}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                  }) => (
                    <Phone
                      onChange={onChange}
                      value={value}
                      className="form-control ps-4"
                      setValue={setValue}
                      name="mobile"
                      isValid={isValid}
                    />
                  )}
                />
                <img src="assets/img/mobile.png" className="input-img" alt="" />
              </div>
              {errors.mobile && (
                <span className="text-danger">{errors?.mobile?.message}</span>
              )}
            </div>

            <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
              <label className="mb-2 d-flex justify-content-between">
                <span className="fw-bold">
                  <img src="assets/img/medical.png" alt="" /> Medical Note
                </span>
                {/* <small>Optional</small> */}
              </label>
              <div className="input-container">
                <textarea
                  className="form-control p-3"
                  rows="7"
                  placeholder="Write here..."
                  {...register("note")}
                ></textarea>
              </div>
              {errors.note && (
                <span className="text-danger">{errors?.note?.message}</span>
              )}
            </div>
            {/* <div className="form-group col-sm-12 col-md-12 text-center">
              <span className="fw-bold cursor-pointer">
                By clicking below, you agree with the{" "}
                <span
                  className="theme-color fw-bold"
                  onClick={() => {
                    navigate("/terms-and-condition/add new student");
                  }}
                >
                  TERMS & CONDITION
                </span>
                .
              </span>
            </div> */}

            {userData?.wallet > 0 &&
              watchTrailMembership === "3" &&
              (isRenewal || isExpired) && (
                <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4 ps-4">
                  <div className="input-container term-checkbox ">
                    <Controller
                      name="useWalletAmount"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <input
                          type="checkbox"
                          id="termCheckbox2"
                          name="tnc"
                          checked={value}
                          // {...register("tnc")}
                          onChange={(e) => {
                            onChange(e.target.checked);
                          }}
                        />
                      )}
                    />
                    <label htmlFor="termCheckbox2"></label>
                    <span>Use wallet amount</span>
                  </div>
                </div>
              )}

            <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4 text-center ">
              <div className="input-container term-checkbox ">
                <Controller
                  name="tnc"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <input
                      type="checkbox"
                      id="termCheckbox"
                      name="tnc"
                      checked={value}
                      // {...register("tnc")}
                      onChange={(e) => {
                        onChange(e.target.checked);
                      }}
                    />
                  )}
                />
                <label htmlFor="termCheckbox"></label>
                <span>
                  I accept the{" "}
                  <span
                    className="theme-color fw-bold"
                    onClick={() => {
                      // navigate("/terms-and-condition/add new student");
                      window.open(
                        "/terms-and-condition/add new student",
                        "_blank"
                      );
                    }}
                  >
                    TERMS & CONDITIONS
                  </span>{" "}
                  applied for adding new student. .
                </span>
                {errors && (
                  <div className="text-red">{errors?.tnc?.message}</div>
                )}
              </div>
            </div>

            <div
              className={
                id &&
                !data?.membership_details &&
                (data?.trialAccountStatus === "2" ||
                  data?.trialAccountStatus === "1")
                  ? "form-group col-sm-12 col-md-6 text-center"
                  : isRenewal
                  ? "form-group col-sm-12 col-md-4 text-center"
                  : isExpired
                  ? "form-group col-sm-12 col-md-6 text-center"
                  : "form-group col-sm-12 col-md-12 text-center"
              }
            >
              <button
                type="submit"
                className="btn btn-submit w-100 fs-4"
                disabled={submitting}
              >
                {submitting ? (
                  <Loader />
                ) : id && watchTrailMembership === "3" ? (
                  "Save"
                ) : (
                  "Save & Proceed"
                )}
              </button>
            </div>
            {(id &&
              ((!data?.membership_details &&
                data?.trialAccountStatus === "2") ||
                data?.trialAccountStatus === "1")) ||
            isExpired ? (
              <div className="form-group col-sm-12 col-md-6 text-center">
                <button
                  type="button"
                  onClick={() => {
                    if (watchMembershipLevel) {
                      const data = getValues();
                      onSubmit(data);
                    } else {
                      setError("tnc", {
                        message: "Kindly accept the terms and conditions.",
                      });
                      toast.error("Kindly select membership to buy.");
                    }
                  }}
                  disabled={renewBuySubmitting && renewBuySubmitting}
                  className="btn btn-submit w-100 fs-4"
                >
                  {renewBuySubmitting ? <Loader /> : "Buy Membership"}
                </button>
              </div>
            ) : null}

            {isRenewal && (
              <div className="form-group col-sm-12 col-md-8 text-center">
                <button
                  type="button"
                  onClick={() => {
                    if (watchTNC) {
                      const data = getValues();
                      onSubmit(data);
                    } else {
                      setError("tnc", {
                        message: "Kindly accept the terms and conditions.",
                      });
                    }
                  }}
                  disabled={renewBuySubmitting && renewBuySubmitting}
                  className="btn btn-submit w-100 fs-4"
                >
                  {renewBuySubmitting ? <Loader /> : "Renew Membership"}
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
      {/* <MembershipDetailModal
        show={showModalDetail}
        handleShow={setShowModalDetail}
        data={selectedMembership}
      /> */}

      <BuyMembershipPayment
        show={showPayment}
        handleShow={() => {
          setShowPayment(!showPayment);
        }}
      />

      {showBuyMembership && watchMembershipLevel && (
        <BuyMembershipModal
          submitting={submitting}
          show={showBuyMembership}
          handleShow={setShowBuyMembership}
          discipline={watchDiscipline}
          student_type={stType}
          studentId={id}
          handleBuy={handleBuy}
          membershipId={watchMembershipLevel}
        />
      )}
    </section>
  );
}

export default AddStudent;
