import React, { useCallback, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import {
  changeDiscipline,
  changeStudentData,
  changeStudentType,
} from "../../features/membership/MembershipSlice";
import { useDispatch } from "react-redux";

function StudentCard({
  item,
  student,
  setStudent,
  setDiscipline,
  setClass_type,
  setSchoolId,
  setAreaId,
  setAge_group,
  setSelectedTiming,
}) {
  const [isExpired, setIsExpired] = useState(false);
  const [isRenewal, setIsRenewal] = useState(true);
  const [isModalShow, setIsModalShow] = useState(false);
  const [data, setData] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleExpired = useCallback(() => {
    console.log(item, "item");
    const expiryDate = moment(item?.membership_details?.valid_till);
    const today = moment();
    let difference = moment.duration(expiryDate.diff(today)).asHours();

    if (difference <= 0) {
      return true;
    }

    return false;
  }, [item]);
  const handleRenewalDate = useCallback(() => {
    const expiryDate = moment(item?.membership_details?.valid_till);
    const today = moment();
    let difference = moment.duration(expiryDate.diff(today)).asDays();
    console.log(difference, "Data");
    let toHours = difference * 24 * 60 * 60;

    if (
      (Number(item?.membership_details?.membership_details?.no_of_days) > 30 &&
        difference < 30 &&
        difference > 0) ||
      (Number(item?.membership_details?.membership_details?.no_of_days) === 7 &&
        difference < 7 &&
        difference >= 1) ||
      (Number(item?.membership_details?.membership_details?.no_of_days) === 1 &&
        difference < 2 &&
        difference >= 1)
    ) {
      return true;
    }

    return false;
  }, [item]);

  useEffect(() => {
    if (item) {
      console.log(item, "Student card data");
      let ab = handleExpired();
      setIsExpired(ab);
      const isRenewald = handleRenewalDate();
      setIsRenewal(isRenewald);
    }
  }, [handleExpired, item]);

  const handleStudent = (e, item) => {
    console.log("itemmm", item, e);
    setData(item);

    let checked = e.target.checked;
    let arr = [...student];
    let index = arr.indexOf(item);

    if (checked) {
      if (index === -1) {
        arr = [item];
      }
    } else {
      if (index !== -1) {
        arr.splice(index, 1);
      }
    }
    setSelectedTiming([]);
    setAreaId(item?.areacode);
    setSchoolId(item?.schoolId);
    setDiscipline(item?.discipline);
    setClass_type(item?.student_type);
    setStudent(arr);
    setAge_group(arr?.[0]?.student_type);
    if (item?.membership_details !== null && isExpired) {
      // toast.error(
      //   "Your membership needs to be renewed to proceed with booking."
      // );
      setIsModalShow(true);
    }
  };
  console.log("dataa", data);

  return (
    <>
      <div className="mx-2">
        <input
          type="checkbox"
          name="students"
          id={`student-${item?.id}`}
          checked={
            student?.length > 0
              ? student?.findIndex((it) => it?.id === item?.id) !== -1
              : false
          }
          onChange={(e) => {
            handleStudent(e, item);
          }}
        />
        <label
          htmlFor={`student-${item?.id}`}
          className="student__details fullHeight"
          style={{ height: "450px" }}
        >
          <p>
            <img
              src={
                item?.image?.includes("http")
                  ? item?.image
                  : "assets/img/headerlogo.png"
              }
              className="student__img"
              alt=""
            />
          </p>
          <p className="d-grid mb-0">
            <span>student name</span>
            <span>
              <b>{item ? `${item?.first_name} ${item?.last_name}` : ""}</b>
            </span>
          </p>
          <p className="d-grid mb-0">
            <span>date of birth</span>
            <span>
              <b>
                {item?.dob
                  ? moment(item?.dob).format("DD MMM YYYY")
                  : item?.dob_year
                  ? moment(
                      `${item?.dob_year}-${item?.dob_month}-${item?.dob_day}`
                    ).format("DD MMM YYYY")
                  : ""}{" "}
                {item ? `(${item?.student_type})` : ""}
              </b>
            </span>
          </p>
          <p className="d-grid mb-0">
            <span>school venue</span>
            <span>
              <b>
                {item?.school_details?.name ? item?.school_details?.name : "NA"}
              </b>
            </span>
          </p>
          <p className="d-grid mb-0">
            <span>Status</span>
            <span>
              <b>
                {item?.trialAccountStatus === "1"
                  ? "Trial"
                  : item?.trialAccountStatus === "3" && isExpired
                  ? "Inactive"
                  : item?.trialAccountStatus === "3"
                  ? "Active"
                  : item?.trialAccountStatus === "2"
                  ? "InActive"
                  : item?.trialAccountStatus === "0" ||
                    item?.membership_details === null
                  ? "No Membership"
                  : "NA"}
              </b>
            </span>
          </p>
          <p className="d-grid mb-0">
            <span>Discipline</span>
            <span>
              <b>{item?.discipline_details?.name || "NA"}</b>
            </span>
          </p>
          <span>
            <small
              className={
                (item?.trialAccountStatus === "3" && isRenewal) ||
                (item?.trialAccountStatus === "3" && isExpired)
                  ? "text-danger"
                  : ""
              }
            >
              {item?.trialAccountStatus === "3" &&
              item?.membership_details !== null &&
              isExpired
                ? `Your membership is expired. Kindly renew.`
                : item?.trialAccountStatus === "3" &&
                  item?.membership_details !== null &&
                  isRenewal
                ? `Your membership expires soon. Kindly renew.`
                : ""}
            </small>
          </span>
        </label>
      </div>{" "}
      <Modal
        show={isModalShow}
        onHide={() => {
          setIsModalShow(!isModalShow);
        }}
        size="md"
        centered
      >
        <Modal.Body>
          <div className="modal-dialog modal-dialog-centered modal-xl">
            <div className="modal-content other-city b-r-20">
              <div className="modal-header d-grid text-center">
                <h5 className="">
                  Your membership needs to be renewed to proceed with booking.
                </h5>

                {/* <button
                  type="button"
                  className="btn-close"
                  onClick={() => {
                    setIsModalShow(!isModalShow);
                  }}
                ></button> */}
              </div>
              <div className="modal-body">
                <div className="row student-container">
                  <div
                    className="col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex justify-content-center align-items-center text-center"
                    style={{ gap: 20 }}
                  >
                    <button
                      type="button"
                      // onClick={() => {
                      //   setSelectedLevels([]);
                      //   handleShow();
                      //   setSelectedEvent(null);
                      // }}
                      onClick={() => {
                        (async () => {
                          await localStorage.setItem(
                            "discipline",
                            data?.discipline_details?.id
                          );
                          await localStorage.setItem(
                            "student_type",
                            data?.student_type
                          );
                          await localStorage.setItem(
                            "student_data",
                            JSON.stringify(data)
                          );
                          await localStorage.setItem("buyOrRenew", "renew");
                        })();
                        dispatch(
                          changeDiscipline(data?.discipline_details?.id)
                        );
                        dispatch(changeStudentType(data?.student_type));
                        dispatch(changeStudentData(data));
                        navigate(`/plans/${data?.id}`, {
                          state: {
                            membershipId:
                              data?.membership_details?.membership_level,
                          },
                        });
                      }}
                      className="btn btn-submit fs-6 mb-1 w-100"
                    >
                      Renew
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setIsModalShow(!isModalShow);
                      }}
                      className="btn btn-submit fs-6 mb-1 w-100"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default StudentCard;
