import React from "react";
import { Navigate } from "react-router-dom";

function PrivateRoute({ children }) {
  const isAuthorized = true;
  // const isAuthorized = localStorage.getItem("token");
  if (isAuthorized) {
    return children;
  } else {
    return <Navigate to="/" replace />;
  }
}

export default PrivateRoute;
