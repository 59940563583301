import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="footer mt-0">
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 text-center">
            <Link className="footer-logo" to="/">
              <img
                src="assets/img/headerlogo.png"
                className="img-fluid"
                alt="Book In Class"
                title="Book In Class"
              />
            </Link>
          </div>
          {/* <div className="col-sm-6 col-md-4 col-lg-3">
            <div className="widget">
              <p className="mt-2">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
                commodo ligula eget dolor. Aenean massa. Cum sociis natoque
                penatibus et magnis dis parturient montes, nascetur ridiculus
                mus. Donec quam felis, ultricies nec, pellentesque eu, pretium
                quis, sem. Nulla consequat massa quis enim. Donec pede justo,
                fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo,
              </p>
            </div>
          </div>
          <div className="col-sm-1 col-md-1 col-lg-1 md-d-none"></div>
          <div className="col-sm-6 col-md-8 col-lg-5">
            <div className="widget">
              <h3>Quick Links</h3>
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                  <ul className="footer-li">
                    <li>
                      <a href="#">how it work</a>
                    </li>
                    <li>
                      <a href="#">events</a>
                    </li>
                    <li>
                      <a href="#">about us</a>
                    </li>
                    <li>
                      <a href="#">privacy policy</a>
                    </li>
                    <li>
                      <a href="#">membership</a>
                    </li>
                    <li>
                      <a href="#">classes</a>
                    </li>
                    <li>
                      <a href="#">help</a>
                    </li>
                    <li>
                      <Link to="/terms-and-condition/company">
                        terms & conditions
                      </Link>
                    </li>
                    <li>
                      <a href="#">partner with us</a>
                    </li>
                    <li>
                      <a href="#">FAQs</a>
                    </li>
                  </ul>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6">
                  <ul className="footer-li"></ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-3">
            <div className="widget">
              <h3>Social Links</h3>
              <ul className="Social-Links">
                <li>
                  <a href="#">
                    <img src="assets/img/fb.png" className="img-fluid" alt="" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img
                      src="assets/img/insta.png"
                      className="img-fluid"
                      alt=""
                    />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img
                      src="assets/img/twitter.png"
                      className="img-fluid"
                      alt=""
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div> */}
        </div>
      </div>
      <div className="copyright">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <i class="fa fa-copyright" aria-hidden="true"></i>{" "}
              {moment().format("YYYY")} Book In Class. All rights reserved
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
