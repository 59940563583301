import React from "react";
import { Modal } from "react-bootstrap";

function DeleteStudent({ show, handleShow }) {
  return (
    <Modal show={show} onHide={handleShow} size="md">
      <Modal.Body>
        <div className="modal-dialog-centered modal-lg">
          <div className="modal-content other-city b-r-20">
            <div className="modal-header text-center">
              <div>
                <h2 className="mdl-ttl">Delete</h2>
              </div>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  handleShow("");
                }}
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <h5>
                  <p>Are you sure you want to delete the student?</p>
                </h5>

                <div className="col-sm-12 col-md-12 col-lg-12 mt-3">
                  <div className="view-btns">
                    <span className="w-100">
                      <button
                        type="button"
                        className="btn btn-reject btn-view mt-2"
                        onClick={() => {
                          handleShow("");
                        }}
                      >
                        Cancel
                      </button>
                    </span>
                    <button
                      type="button"
                      className="btn btn-accept btn-view mt-2"
                      onClick={() => {
                        handleShow("confirm");
                      }}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default DeleteStudent;
