import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import StudentMembershipDetail from "./../../component/Modal/StudentMembershipDetail";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { toast } from "react-toastify";
import { requestToDeleteAccount } from "../../api/authentications";

function AccountCancellation() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  // const list = useSelector((state) => state.student.list);
  const [reason, setReason] = useState("");

  const handleCancel = () => {
    if (reason) {
      let payload = {};
      if (id) {
        payload = {
          reason,
          studentId: id ? id : "",
        };
      } else {
        payload = {
          reason,
        };
      }
      requestToDeleteAccount(payload).then((res) => {
        if (res?.code === 1) {
          navigate("/dashboard", { replace: true });
          toast.success(res?.message);
        } else {
          toast.error(res?.message);
        }
      });
    } else {
      toast.error("Kindly mention the reason for cancellation of the account.");
    }
  };
  return (
    <section className="sec-bg py-5 bottom-space">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-sm-12 col-md-12 col-lg-12 mb-3 text-center">
            <h3>
              {location?.pathname?.includes("student")
                ? "Request Student Removal"
                : "Request Account Cancellation"}
            </h3>
            {/* <p>Are you sure you want to cancel your account ?</p> */}
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
            <div className="account-Cancellation">
              {/* <Carousel
                responsive={responsive}
                removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                infinite={true}
                showDots={true}
                renderDotsOutside={true}
              >
                {list?.length > 0 ? (
                  list?.map((item, i) => {
                    return <StudentMembershipDetail />;
                  })
                ) : (
                  <div>No data found.</div>
                )}
              </Carousel> */}

              <div className="d-flex flex-direction-column">
                <label className="fw-bold theme-color">
                  Why do you want to cancel your account
                </label>
                <textarea
                  rows={5}
                  className="textArea form-control"
                  value={reason}
                  onChange={(e) => {
                    setReason(e.target.value);
                  }}
                ></textarea>
                <div className="deleteAccountButtonContainer">
                  <button
                    type="button"
                    className="btn btn-view btn-accept  fs-6 mb-1 "
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    Back
                  </button>
                  <button
                    type="button"
                    className="btn btn-view btn-accept  fs-6 mb-1 "
                    onClick={handleCancel}
                  >
                    Cancel Account
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AccountCancellation;
